import { Component } from 'react';
import { GlobalState } from './GlobalState';
import type { StockInfo } from './StockInfo';

interface StockWidgetProps {
  globalstate?: GlobalState;
}

interface StockWidgetState {
    stockInfoLoaded: boolean;
    stockinfo: StockInfo[] | undefined;
  }
  
  class StockWidget extends Component<StockWidgetProps, StockWidgetState> {
    timerID: any;
    constructor(props: any) {
      super(props);
  
      this.state = {
        stockInfoLoaded: false,
        stockinfo: undefined
      };
      this.updateStockInfo = this.updateStockInfo.bind(this);
      this.timerID = undefined;
    }
    async componentDidMount() {
      this.updateStockInfo()
      this.timerID = setInterval(this.updateStockInfo, 60000); // runs every 5 min.
    }
    async componentWillUnmount() {
      if (this.timerID !== undefined) clearInterval(this.timerID);
    }
    async updateStockInfo() {
      try {
        const response = await fetch('https://' + window.location.hostname + '/HomeAPI/GetStockObjects', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            credentials: 'include'
          },
          credentials: 'include'
        });
        var stockInfo: StockInfo[] = await response.json();
        // Update the array of events in state
        this.setState({
          stockInfoLoaded: true,
          stockinfo: stockInfo,
        });
      }
      catch (err) {
        console.log(err);
      }
  
    }
    datefromGerString(input: string )
    {
      var parts = input.split(".");
      return (new Date(parseInt(parts[2], 10), parseInt(parts[1], 10)-1, parseInt(parts[0], 10)));
    }
    render() {
      if (!this.state.stockInfoLoaded || this.state.stockinfo === undefined) return null;
      var lastupdate = new Date(0);
      var sum: number[]=[];
      var sumbuy: number[]=[];
      var sumold: number[] = [];
      var mystocks: any[]=[];
      const curFormat = new Intl.NumberFormat(`de-DE`, { currency: `EUR`,  style: 'currency',    });
      const chgFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2,    });
      const prozFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0,    });
      var sorted = this.state.stockinfo.sort(function (b: StockInfo, a: StockInfo) { return b.value*b.count - a.value*a.count});
      for (var i = 0; i < 10; i++) {
        var locsum = 0;
        var locsumold = 0;
        var locsumbuy = 0;
        sorted.forEach((element: StockInfo) => {
          if (element.count > 0 && element.depot === i) {
            locsum += element.value * element.count;
            locsumbuy += element.buy;
            locsumold += element.value * element.count / (1 + element.change / 100);
          }
          if(lastupdate < new Date(element.lastUpdate)) lastupdate = new Date(element.lastUpdate);
        })
        sum.push(locsum);
        sumold.push(locsumold);
        sumbuy.push(locsumbuy);
        mystocks.push(sorted.filter(elem => elem.depot === i && elem.count > 0).map((element) => (
          <tr key={element.wkn} >
            <td><div style={{color: ((element.value*element.count-element.buy)>0?this.props.globalstate?.currentColors.greencolor:this.props.globalstate?.currentColors.redcolor)}}>{prozFormat.format(element.buy/locsumbuy*100)}% {element.count} {this.DecodeHTML(element.name)} {(new Date()).getTime() - (new Date(element.lastUpdate)).getTime() > 2*60*60*1000?(new Date(element.lastUpdate)).toISOString().substring(0,10):''}</div></td>
            <td style={{textAlign: 'right'}}>{curFormat.format(element.value*element.count)}</td>
            <td style={{textAlign: 'right'}}>{curFormat.format(element.value)}</td>
            <td style={{textAlign: 'right'}}><div style={{color: (element.change>0?this.props.globalstate?.currentColors.greencolor: this.props.globalstate?.currentColors.redcolor)}}>{chgFormat.format(element.change)}%</div></td>
            <td style={{textAlign: 'right'}}><div style={{color: ((element.value*element.count-element.buy)>0?this.props.globalstate?.currentColors.greencolor:this.props.globalstate?.currentColors.redcolor)}}>{curFormat.format(element.value*element.count-element.buy)}</div></td>
            <td style={{textAlign: 'right'}}><div style={{color: ((element.value*element.count/element.buy*100-100)>0?this.props.globalstate?.currentColors.greencolor:this.props.globalstate?.currentColors.redcolor)}}>{chgFormat.format(element.value*element.count/element.buy*100-100)}%</div></td>
            <td style={{textAlign: 'right', marginRight: '30px'}}><div style={{color: ((element.value*element.count/element.buy*100-100)>0?this.props.globalstate?.currentColors.greencolor:this.props.globalstate?.currentColors.redcolor)}}>{chgFormat.format((element.value*element.count/element.buy*100-100)/(((new Date()).getTime() - (this.datefromGerString(element.buydate)).getTime())/1000/60/60/24/365.25))}%</div></td>
          </tr>
        )));
      }
      
      var stocks = sorted.filter(elem => elem.depot === 0).map((element) => (
        <tr key={element.wkn} >
          <td>{this.DecodeHTML(element.name)}{(new Date()).getTime() - (new Date(element.lastUpdate)).getTime() > 2*60*60*1000?'+':''}</td>
          <td style={{textAlign: 'right'}}>{curFormat.format(element.value)}</td><td style={{textAlign: 'right'}}></td>
          <td style={{textAlign: 'right'}}></td>
          <td style={{textAlign: 'right'}}><div style={{color: (element.change>0?this.props.globalstate?.currentColors.greencolor:this.props.globalstate?.currentColors.redcolor)}}>{chgFormat.format(element.change)}%</div></td>
          <td style={{textAlign: 'right'}}></td>
          <td style={{textAlign: 'right'}}></td>
          <td style={{float: 'right', marginRight: '30px'}}></td>
        </tr>
      ))
      var depots: any[] = [];
      for (var i = 0; i < 10; i++) {
        if (mystocks[i].length > 0) {
          depots.push(mystocks[i]);
          var break1 = <tr style={{ borderTop: '1px solid #FFF' }}>
            <td><b>Gesamt</b></td>
            <td style={{ textAlign: 'right' }}><b>{curFormat.format(sum[i])}</b></td>
            <td style={{textAlign: 'right'}}></td>
            <td style={{ textAlign: 'right' }}><div style={{ color: ((sum[i] / sumold[i] - 1) * 100 > 0 ? this.props.globalstate?.currentColors.greencolor : this.props.globalstate?.currentColors.redcolor) }}><b>{chgFormat.format((sum[i] / sumold[i] - 1) * 100)}%</b></div></td>
            <td style={{ textAlign: 'right' }}><div style={{ color: ((sum[i] - sumbuy[i]) > 0 ? this.props.globalstate?.currentColors.greencolor : this.props.globalstate?.currentColors.redcolor) }}><b>{curFormat.format(sum[i] - sumbuy[i])}</b></div></td>
            <td style={{ textAlign: 'right' }}><div style={{ color: ((sum[i] / sumbuy[i] * 100 - 100) > 0 ? this.props.globalstate?.currentColors.greencolor : this.props.globalstate?.currentColors.redcolor) }}><b>{chgFormat.format(sum[i] / sumbuy[i] * 100 - 100)}%</b></div></td>
            <td style={{ textAlign: 'right', marginRight: '30px' }}><div style={{ color: ((sum[i] / sumbuy[i] * 100 - 100) > 0 ? this.props.globalstate?.currentColors.greencolor : this.props.globalstate?.currentColors.redcolor) }}><b>{chgFormat.format(sum[i] / sumbuy[i] * 100 - 100)}%</b></div></td>
            </tr>
          var break2 = <tr>
            <td></td>
            <td style={{ textAlign: 'right' }}></td>
            <td style={{ textAlign: 'right' }}></td>
            <td style={{ textAlign: 'right' }}></td>
            <td style={{ textAlign: 'right' }}></td>
            <td style={{ textAlign: 'right' }}></td>
            <td style={{ float: 'right', marginRight: '30px' }}><div style={{ color: this.props.globalstate?.currentColors.bgcolor }}>%</div></td>
            </tr>;
          depots.push(break1);
          depots.push(break2);
        }
      }
      var outerdivstyle = { color: '#FFF',  background: '#000', width: 'auto', height: 'auto'};
      if(this.props.globalstate !== undefined) outerdivstyle = { color: this.props.globalstate.currentColors.fgcolor, background: this.props.globalstate.currentColors.bgcolor, width: 'auto', height: 'auto' };
      var outerdivstyle2 = { color: '#FFF',  background: '#000', width: '100%', height: '100%'};
      if(this.props.globalstate !== undefined) outerdivstyle2 = { color: this.props.globalstate.currentColors.fgcolor, background: this.props.globalstate.currentColors.bgcolor, width: '100%', height: '100%' };
      return (
        <div style={outerdivstyle2}>
        <div style={outerdivstyle}>
            
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          {depots}
          {stocks}
          </table>
          <div style={{marginTop: '20px'}}>{lastupdate.toLocaleString(`de-DE`)}</div>
        </div></div>
      );
    }
    DecodeHTML(str: string) {
      var textArea = document.createElement('textarea');
      textArea.innerHTML = str;
			return textArea.value.replace(/&#(\d+);/g, function(match, dec) {
				return String.fromCharCode(dec);
			});
		}

  }
  

export default StockWidget;