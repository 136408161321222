import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from "react-cookie";
import {GlobalState} from './GlobalState';
import DoorsAndWindows from './DoorsAndWindows';
import LineChart from './LineChart';
import CustomLineChartPage from './CustomLineChartPage';
import Controls from './Controls';
import ApplicationControls from './ApplicationControls';
import config from './config';
import SmarthomeConfigurator from './SmarthomeConfigurator';
import AdminMenu from './AdminMenu';
import { Method } from '@testing-library/react';
import StockWidget from './StockWidget'
import StockLineChartPage from './StockLineChartPage';
import screenfull from "screenfull";

var globalstate = {};

interface SmartHomeAppProps {
    cookies: Cookies,
    loggedin: boolean,
    smartmirror: boolean,
    togglemirrorfunc: ()=>void,
    loginfunc: ()=>void,
    logoutfunc:()=>void,
    resetLogin: ()=>void,
    userContext: object
}

interface SmartHomeAppState {
    location: string,
    page: string,
    resizedTrigger: boolean,
    selectedItem: any,
    dark: boolean,
    loggedIn: boolean,
    //insvg: string,
    showSmartHomeConfigurator: any | null,
    ShowAdminMenu: boolean,
    fullscreen: boolean,
    smarthomestate: object | undefined,
    userContext: object | undefined,
    eventActivity: boolean
}

class SmartHomeApp extends Component<SmartHomeAppProps,SmartHomeAppState> {
    sse: EventSource| undefined = undefined;
    timerID: any = undefined;
    refreshTimerID: any = undefined;
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };
    constructor(props: SmartHomeAppProps) {
        super(props);
        this.state = {
            location: this.props.cookies.get("location") || "in",
            page: this.props.cookies.get("page") || 'states',
            resizedTrigger: true,
            selectedItem: undefined,
            dark: true,
            loggedIn: false,
            //insvg: '',
            showSmartHomeConfigurator: null,
            ShowAdminMenu: false,
            fullscreen: false,
            smarthomestate: undefined,
            userContext: undefined,
            eventActivity: false
        };
        this.updateValues = this.updateValues.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.switchLocation = this.switchLocation.bind(this);
        this.switchPage = this.switchPage.bind(this);
        this.toggleDayNight = this.toggleDayNight.bind(this);
        //this.LoggedInState = this.LoggedInState.bind(this);
        this.updateEventReceived = this.updateEventReceived.bind(this);
        //this.FinishLogin = this.FinishLogin.bind(this);
        this.showSmartHomeConfigurator = this.showSmartHomeConfigurator.bind(this);
        this.showAdminMenu = this.showAdminMenu.bind(this);
        this.FinishUpdateLoginState = this.FinishUpdateLoginState.bind(this);
        this.handleErrors = this.handleErrors.bind(this);
        this.sse = undefined;
    }
    render() {
        if(this.props.loggedin && !this.state.loggedIn)
        {
            this.FinishUpdateLoginState();
        }
        var output: any = '';
        var SmartHomeConfigurator: any = '';
        var adminMenu: any = '';
        var globalstate: GlobalState ={
        app: this,
        smartmirror: this.props.smartmirror,
        toggleMirrorMode : this.props.togglemirrorfunc,
        /*pic : {__html: this.state.insvg},*/
        dark : this.state.dark,
        loggedIn : this.state.loggedIn,
        page : this.state.page,
        fullscreen : this.state.fullscreen,
        location : this.state.location,
        smarthomestate : this.state.smarthomestate,
        selectedItem : this.state.selectedItem,
        loginfunc : this.props.loginfunc,
        logoutfunc : this.props.logoutfunc,
        config : config,
        mobile : false,
        userContext: this.state.userContext,
        colors: { dark: { fgcolor: '#FFFFFF', bgcolor: '#3c4047', midcolor: '#787c83', redcolor: '#bf1027', greencolor: '#34ad5c' }, light: { fgcolor: '#000000', bgcolor: '#FFFFFF', midcolor: '#787c83', redcolor: '#bf1027', greencolor: '#34ad5c' }},
        currentColors: { fgcolor: '#000000', bgcolor: '#FFFFFF', midcolor: '#787c83', redcolor: '#bf1027', greencolor: '#34ad5c' },
        window: {},
        landscape: undefined
    };
        if (globalstate.dark) globalstate.currentColors = globalstate.colors.dark;
        if (globalstate.smartmirror)
            globalstate.colors = { dark: { fgcolor: '#FFFFFF', bgcolor: '#000000', midcolor: '#787c83', redcolor: '#bf1027', greencolor: '#34ad5c' }, light: { fgcolor: '#FFFFFF', bgcolor: '#000000', midcolor: '#787c83', redcolor: '#bf1027', greencolor: '#34ad5c' }};
        if (globalstate.dark) globalstate.currentColors = globalstate.colors.dark;
        else globalstate.currentColors = globalstate.colors.light;
        var divstyle = {};
        if (globalstate.smartmirror) divstyle = Object.assign({}, config.STYLES.div100, config.STYLES.bgmirror);
        else if (globalstate.dark) divstyle = Object.assign({}, config.STYLES.div100, config.STYLES.bgdark);
        else divstyle = Object.assign({},config.STYLES.div100, config.STYLES.bglight);
        if (this.state.loggedIn !== undefined && this.state.loggedIn === true ) {
            var width = /*window.innerWidth*/ document.getElementById('root')?.clientWidth;
            if (globalstate.smartmirror && width !== undefined) width -= 45;
            var height = /*window.innerHeight*/ document.getElementById('root')?.clientHeight;
            if(height != undefined) height -= 50;
            globalstate.window = { width: width, height: height};
            if(width !== undefined && height !== undefined)
            {
                globalstate.landscape = (width > height);
                if (height <= 600) globalstate.mobile = true;
            }
            if (this.state.smarthomestate !== undefined) {
                if (this.state.page === 'states') {
                    if (this.state.location === 'in') {
                        output =
                            <div style={divstyle}>
                                <DoorsAndWindows globalstate={globalstate} width={width} height={height} />
                            </div>;
                    }
                    else if (this.state.location === 'rcm') {
                        output =
                            <div style={divstyle}>
                                <DoorsAndWindows globalstate={globalstate} width={width} height={height} />
                            </div>;
                    }
                    else if (this.state.location === 'ac') {
                        output =
                            <div style={divstyle}>
                                <DoorsAndWindows globalstate={globalstate} width={width} height={height} />
                            </div>;
                    }
                    else if (this.state.location === 'ka') {
                        output =
                            <div style={divstyle}>
                                <DoorsAndWindows globalstate={globalstate} width={width} height={height} />
                            </div>;
                    }
                }
            }
            else this.updateValues(this.state.location);
            if (this.state.page === 'graphs' && height !== undefined) {
                if (this.state.location === 'in') {
                    output =
                        <div style={divstyle}>
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 0, device_id: 5, sensor_id: 0 }, { location_id: 0, device_id: 2, sensor_id: 0 }, { location_id: 0, device_id: 5, sensor_id: 1 }, { location_id: 0, device_id: 29, sensor_id: 70 }]} />
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 0, device_id: 10, sensor_id: 70 }, { location_id: 0, device_id: 20, sensor_id: 70 }, { location_id: 0, device_id: 13, sensor_id: 70 }, { location_id: 0, device_id: 25, sensor_id: 70 }, { location_id: 0, device_id: 26, sensor_id: 70 }]} />
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 0, device_id: 2, sensor_id: 5 }, { location_id: 0, device_id: 2, sensor_id: 3 }, { location_id: 0, device_id: 58, sensor_id: 96 }]} />
                        </div>;
                }
                else if (this.state.location === 'rcm') {
                    output =
                        <div style={divstyle}>
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 1, device_id: 19, sensor_id: 0 }, { location_id: 1, device_id: 35, sensor_id: 0 }, { location_id: 1, device_id: 58, sensor_id: 70 }, { location_id: 1, device_id: 36, sensor_id: 70 }]} />
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 1, device_id: 35, sensor_id: 1 }, { location_id: 1, device_id: 1, sensor_id: 5 }]} />
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 1, device_id: 1, sensor_id: 3 }, { location_id: 1, device_id: 31, sensor_id: 2 }]} />
                        </div>;
                }
                else if (this.state.location === 'ac') {
                    output =
                        <div style={divstyle}>
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 2, device_id: 6, sensor_id: 0 }, { location_id: 2, device_id: 2, sensor_id: 0 }]} />
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 2, device_id: 6, sensor_id: 1 }, { location_id: 2, device_id: 2, sensor_id: 1 }]} />
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 2, device_id: 0, sensor_id: 70 }]} />
                        </div>;
                }
                else if (this.state.location === 'ka') {
                    output =
                        <div style={divstyle}>
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 1, device_id: 0, sensor_id: 70 }, { location_id: 1, device_id: 1, sensor_id: 70 }, { location_id: 1, device_id: 0, sensor_id: 97 }, { location_id: 1, device_id: 1, sensor_id: 97 }, { location_id: 1, device_id: 17, sensor_id: 70 }]} />
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 1, device_id: 20, sensor_id: 70 },{ location_id: 1, device_id: 26, sensor_id: 86 }]} />
                            <LineChart width={width} height={Math.floor(height / 3)} globalstate={globalstate} timespan={3600 * 2 * 24} dataparams={[{ location_id: 1, device_id: 24, sensor_id: 0 },{ location_id: 1, device_id: 26, sensor_id: 105 },{ location_id: 1, device_id: 26, sensor_id: 106 },{ location_id: 1, device_id: 26, sensor_id: 107 },{ location_id: 1, device_id: 37, sensor_id: 31 }]} />
                        </div>;
                }
            }
            else if (this.state.page === 'customgraphs') {
                output =
                    <CustomLineChartPage width={width} height={height} globalstate={globalstate} key={this.state.location} />;
            }

            else if (this.state.page === 'controls') {
                output = <div style={divstyle}>
                    <Controls globalstate={globalstate} key={this.state.location} />
                </div>
            }
            else if (this.state.page === 'stock' && globalstate.userContext != null && globalstate.userContext.features !== undefined && globalstate.userContext.features.stock !== undefined) {
                output =
                    <StockWidget globalstate={globalstate} />;
            }
            else if (this.state.page === 'stockgraphs' && globalstate.userContext != null && globalstate.userContext.features !== undefined && globalstate.userContext.features.stock !== undefined) {
                output =
                    <StockLineChartPage width={width} height={height} globalstate={globalstate} />;
            }
            if (this.state.ShowAdminMenu) {
                adminMenu =
                    <AdminMenu show={this.state.ShowAdminMenu} globalstate={globalstate} close={this.showAdminMenu} ></AdminMenu>
            }
            if (this.state.showSmartHomeConfigurator !== null)
            {
                SmartHomeConfigurator = <SmarthomeConfigurator globalstate={globalstate}></SmarthomeConfigurator>
            }
        }
        else
        {
            output = <div style={divstyle}></div>;
        }
        var actcolor = 'black';
        if(this.state.eventActivity) actcolor = 'red'
        return (
            <div style={{ display: 'flex', flexFlow:'column', width: width, height: '100%', background: 'black' }}>
                <ApplicationControls globalstate={globalstate}/>
                {output}
                {adminMenu}
                {SmartHomeConfigurator}
                <div style={{ position: 'absolute', top: '-28px', left: '0px', fontSize: '50px' }}><p style={{ color: actcolor}}>•</p></div>
            </div>
        );
    }
    switchLocation(loc: string) {
        if (this.state.location !== loc) {
            const { cookies } = this.props;
            cookies.set("location", loc, { path: "app/" });
            this.setState({
                location: loc,
                smarthomestate: undefined
            });
            this.updateValues(loc);
        }
    }
    switchPage(e: any) {
        if (this.state.page !== e.currentTarget.id) {
            const { cookies } = this.props;
            cookies.set("page", e.currentTarget.id, { path: "app/" });
            this.setState({
                page: e.currentTarget.id
            });
        }
    }
    showAdminMenu(show = true) {
        this.setState({ ShowAdminMenu: show });
    }
    showSmartHomeConfigurator(e: any) {
        this.showAdminMenu(false);
        if (e !== null) {
            this.setState({
                showSmartHomeConfigurator: e.currentTarget.id
            });
        }
        else {
            this.setState({
                showSmartHomeConfigurator: null
            });
        }
    }
    /*FinishLogin(data)
    {
        this.setState({ insvg: data });
    }*/
    toggleDayNight(e: any) {
        if (this.state.dark) {
            this.setState({
                dark: false
            });
        }
        else this.setState({
                dark: true
            });
    }
    toggleFullscreen()
    {
        screenfull.toggle();
    }
    updateDimensions() {
        if (this.state.resizedTrigger) {
            this.setState({
                resizedTrigger: false
            });
        }
        else {
            this.setState({
                resizedTrigger: true
            });
        }
    }
    FinishUpdateLoginState() {
        if (this.state.loggedIn === undefined || !this.state.loggedIn) {
            this.setState({
                loggedIn: true,
                userContext: this.props.userContext
            });
            if (this.timerID === undefined) {
                this.timerID = setInterval(
                    () => this.updateValues(this.state.location),
                    300000
                );
            }
            this.updateValues(this.state.location);
            this.sse = new EventSource('https://' + window.location.hostname + '/HomeAPI/ListenEvents', { withCredentials: true });
            this.sse.addEventListener('StateUpdate', e => this.updateEventReceived(e.data));
            this.sse.onerror = () => {
                // error log here 
                if(this.sse !== undefined) this.sse.close();
            }
        }

    }

    componentDidMount() {
        this.refreshTimerID = setInterval(
            () => this.pageRefresh(),
            3600000
        );
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        if (this.state.loggedIn && this.timerID === undefined) {
            this.timerID = setInterval(
                () => this.updateValues(this.state.location),
                300000
            );
            this.updateValues(this.state.location);
        }
        //console.log("SmartHomeApp - Did: " + window.loggedIn);
        if (screenfull.isEnabled) {
	        screenfull.on('change', () => {
                this.setState({
                        fullscreen: screenfull.isFullscreen
                    });
	        });
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        if (this.timerID !== undefined) clearInterval(this.timerID);
        if (this.refreshTimerID !== undefined) clearInterval(this.refreshTimerID);
    }
    pageRefresh() {
        window.location.reload();
    }
    selectedItemChanged(item: any, e: any)
    {
        this.setState({ selectedItem: item });
         this.setState({
                page: 'controls'
            });        
        console.log("SmartHomeApp - ItemChange: " + item.id + " t:" + item.type);
    }
    handleErrors(response: any) {
        if (!response.ok) {
            if(this.props.loggedin && response.status === 401) this.props.resetLogin();
            throw Error(response.statusText);
        }
        return response;
    }
    updateValues(loc: string) {
        if (this.state.page !== 'graphs' && this.state.page !== 'customgraphs' && this.state.page !== 'stock' && this.state.page !== 'stockgraphs') {
            fetch('https://' + window.location.hostname + '/HomeAPI/GetStateObjects', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    location: loc
                }),
                credentials: 'include'
            })
                .then(this.handleErrors)
                .then(result => result.json())
                .then(smarthomestate => this.setState({ smarthomestate, eventActivity: !this.state.eventActivity }))
                .catch(error => console.log(error));
        }
    }
    updateEventReceived(data: string) {
        //console.log("Event!!!" + data);
        var parts = data.split(":");
        if (parts[0] !== this.state.location) return;
        this.updateValues(this.state.location);
        var d = new Date();
        var n = d.getHours();
        if (n > 6 && n < 19) {
            //console.log("check false");
            if (this.state.dark !== false) {
                //console.log("set false");
                this.setState({ dark: false });
            }
        }
        else if (this.state.dark !== true) {
            //console.log("set true");
            this.setState({ dark: true });
        }
    }
}
/*
function getOperatingSystem(window) {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

    return operatingSystem;
}

function getBrowser(window) {
    let currentBrowser = 'Not known';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
    else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
    else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
    else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
    else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
    else { console.log('Others'); }

    return currentBrowser;
}*/

export default withCookies(SmartHomeApp);
