import React, { Component } from 'react';
import ShadeControl from './ShadeControl';
import AwayControl from './AwayControl';
import AutoControl from './AutoControl';
import ValueControl from './ValueControl';
import SwitchControl from './SwitchControl';
import SliderControl from './SliderControl';
import ColorLightControl from './ColorLightControl';
import ColorTempLightControl from './ColorTempLightControl';
import AirVentSpeedControl from './AirVentSpeedControl';


class ControlItem extends Component {
    render() {
        if (this.props.location === 'ac' && this.props.id < 100) return '';
        if (this.props.location === 'rcm' && this.props.id >= 100) return '';
        if (this.props.location === 'ka' && this.props.id < 10000) return '';
        if (this.props.location === 'in' && this.props.id >= 10000) return '';
        var output = '';
        if (this.props.type === 81) output = <div style={{ width: '100%', height: '35px', overflow: 'hidden', background: 'none' }}><ShadeControl location={this.props.location} id={this.props.id} desc={this.props.desc} value={this.props.value} unit={this.props.unit} globalstate={this.props.globalstate}/></div>
        if (this.props.type === 82 || this.props.type === 84 || this.props.type === 85 || this.props.type === 86) output = <div style={{ width: '100%', height: '35px', overflow: 'hidden', background: 'none' }}><SwitchControl location={this.props.location} id={this.props.id} desc={this.props.desc} value={this.props.value} unit={this.props.unit} globalstate={this.props.globalstate}/></div>
        if (this.props.type === 87) output = <div style={{ width: '100%', height: '35px', overflow: 'hidden', background: 'none' }}><ColorLightControl location={this.props.location} id={this.props.id} desc={this.props.desc} value={this.props.value} unit={this.props.unit} globalstate={this.props.globalstate} /></div>
        if (this.props.type === 88) output = <div style={{ width: '100%', height: '35px', overflow: 'hidden', background: 'none' }}><SliderControl location={this.props.location} id={this.props.id} desc={this.props.desc} value={this.props.value} unit={this.props.unit} globalstate={this.props.globalstate} /></div>
        if (this.props.type === 89) output = <div style={{ width: '100%', height: '35px', overflow: 'hidden', background: 'none' }}><ColorTempLightControl location={this.props.location} id={this.props.id} desc={this.props.desc} value={this.props.value} unit={this.props.unit} globalstate={this.props.globalstate} /></div>
        if (this.props.type === 203) output = <div style={{ width: '100%', height: '35px', overflow: 'hidden', background: 'none' }}><AwayControl location={this.props.location} id={this.props.id} desc={this.props.desc} value={this.props.value} unit={this.props.unit} globalstate={this.props.globalstate}/></div>
        if (this.props.type === 202) output = <div style={{ width: '100%', height: '35px', overflow: 'hidden', background: 'none' }}><AutoControl location={this.props.location} id={this.props.id} desc={this.props.desc} value={this.props.value} unit={this.props.unit} globalstate={this.props.globalstate} /></div>
        if (this.props.type === 204 || this.props.type === 105 || this.props.type === 110 || this.props.type === 111) output = <div style={{ width: '100%', height: '35px', overflow: 'hidden', background: 'none' }}><ValueControl location={this.props.location} id={this.props.id} desc={this.props.desc} value={this.props.value} unit={this.props.unit} globalstate={this.props.globalstate} /></div>
        if (this.props.type === 64) output = <div style={{ width: '100%', height: '35px', overflow: 'hidden', background: 'none' }}><AirVentSpeedControl location={this.props.location} id={this.props.id} desc={this.props.desc} value={this.props.value} unit={this.props.unit} globalstate={this.props.globalstate} /></div>
        return output;
    }
}

export default ControlItem;