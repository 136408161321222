import React, { Component } from 'react';
import mired from 'mired';
import iro from '@jaames/iro';
import ColorPicker from './ColorPicker';
import reactCSS from 'reactcss'
import { Interactive } from 'react-interactive';

class ColorTempLightControl extends Component {
constructor(props) {
    super(props);
        this.state = {
            bri: (this.props.value & 0xFF),
            rgb: iro.Color.kelvinToRgb(mired.miredToKelvin((this.props.value & 0xFFFF00) >>> 8)),
            kelvin: mired.miredToKelvin((this.props.value & 0xFFFF00) >>> 8)
        };
        //this.updateLight = this.updateLight.bind(this);
        this.lampOn = this.lampOn.bind(this);
        this.lampOff = this.lampOff.bind(this);
        this.handleChangeComplete = this.handleChangeComplete.bind(this);
        this.handleValueChangeComplete = this.handleValueChangeComplete.bind(this);
}
    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: '100%',
                    height: '100%',
                    borderRadius: '2px',
                    background: `#fff`,
                },
                swatch: {
                    padding: '0px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                    flex: 1,
                    margin: '1px',
                    flexBasis: '150px'
                },
                popover: {
                    position: 'absolute',
                    zIndex: '101',
                    width: '70%',
                    height: '20%',
                    top: '15%',
                    left: '15%'
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        var output = "";
        if (this.props.globalstate !== undefined && this.props.desc !== undefined) {
            var colors = this.props.globalstate.colors.light;
            if (this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
            var fgcolor = colors.fgcolor;
            var midcolor = colors.midcolor;
            if (this.props.globalstate.selectedItem !== undefined && this.props.globalstate.selectedItem.id === this.props.id) {
                fgcolor = colors.redcolor;
                midcolor = colors.redcolor;
            }
            var hoverstyle = { fill: fgcolor, stroke: fgcolor, color: fgcolor, background: fgcolor + '33' }
            var buttonstyle = { fill: midcolor, stroke: midcolor, flex: 1, flexBasis: '120px', border: '1px', margin: '0px 2px', borderStyle: 'solid', height: '32px', color: midcolor, background: 'none', zIndex: 100, position: 'relative' }
            var buttontextstyle = { position: 'relative', float: 'left', top: '50%', transform: 'translate(0%, -50%)', color: fgcolor }
            styles.swatch.background = colors.fgcolor;
            styles.color.background = 'rgba(' + this.state.rgb.r + ',' + this.state.rgb.g + ',' + this.state.rgb.b + ',' + this.state.bri/254 + ')';//this.state.color;

            output = <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }}>
                <div style={{ flex: 1, margin: '1px', flexBasis: '150px' }}>
                    <div style={Object.assign({}, buttontextstyle)} >{this.props.desc}</div>
                </div>
                <div style={styles.swatch} onClick={this.handleClick}>
                    <div style={styles.color} />
                </div>
                {this.state.displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose} />
                    <ColorPicker window={this.props.globalstate.window} color={this.state.kelvin} value={this.state.bri} onColorChange={this.handleChangeComplete} onValueChange={this.handleValueChangeComplete} />
                </div> : null}

                <Interactive as="div" hover={hoverstyle} style={Object.assign({}, buttonstyle, { width: '3%' })} onClick={this.lampOn}>
                    ON
                        </Interactive>
                <Interactive as="div" hover={hoverstyle} style={Object.assign({}, buttonstyle, { width: '3%' })} onClick={this.lampOff}>
                    OFF
                        </Interactive>
            </div>
        }
        return output;
}
    /*
                    <div style={{ margin: '0px 0px', flex: 1, flexBasis: '200px' }}>
                    <ColorPicker style={{ height: 32 }} color={this.state.kelvin} onColorChange={this.handleChangeComplete}  />
                </div >*/

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    handleChangeComplete = (color) => {
        this.setState({ kelvin: color.kelvin, rgb: color.rgb });
        var lampstate = (mired.kelvinToMired(color.kelvin) << 8) + this.state.bri;
        this.sendCommand(this.props.id, lampstate);
    };
    handleValueChangeComplete = (color) => {
        this.setState({ bri: color.hsv.v*254/100 });

        var lampstate = (mired.kelvinToMired(this.state.kelvin) << 8) + color.hsv.v*254/100;
        this.sendCommand(this.props.id, lampstate);
    };
    lampOn()
    {
        var bri = 254;
        this.setState({ bri: bri });
        var lampstate = (mired.kelvinToMired(this.state.kelvin) << 8) + bri;
        this.sendCommand(this.props.id, lampstate)
    }
    lampOff()
    {
        var bri = 0;
        this.setState({ bri: bri });
        var lampstate = (mired.kelvinToMired(this.state.kelvin) << 8) + bri;
        this.sendCommand(this.props.id, lampstate)
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.value !== this.props.value)
        {
          this.setState({
              bri: (this.props.value & 0xFF),
              kelvin: mired.miredToKelvin((this.props.value & 0xFFFF00) >>> 8)
          });
      }
    }
    sendCommand(id, value)
    {
        fetch('https://' + window.location.hostname + '/HomeAPI/ChangeState', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: this.props.location,
                state: id,
                value: value
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .catch(error => console.log(error));
    }
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
    }
}

export default ColorTempLightControl;