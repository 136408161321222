import { Component } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import './Calendar.css';
import type { HourlyWeatherDataPoint, DailyWeatherDataPoint, WeatherInformation } from './WeatherInformation';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
//import { Chart } from 'react-chartjs-2';
import {ChartOptions} from 'chart.js';

interface WeatherState {
  weatherLoaded: boolean;
  weather: WeatherInformation | undefined;
}

class Weather extends Component<{}, WeatherState> {
  timerID: any;
  constructor(props: any) {
    super(props);

    this.state = {
      weatherLoaded: false,
      weather: undefined
    };
    this.updateWeather = this.updateWeather.bind(this);
    this.timerID = undefined;
  }
  async componentDidMount() {
    this.updateWeather()
    this.timerID = setInterval(this.updateWeather, 900000); // runs every 15 min.
  }
  async componentWillUnmount() {
    if (this.timerID !== undefined) clearInterval(this.timerID);
  }
  async updateWeather() {
    try {
      const response = await fetch('https://' + window.location.hostname + '/HomeAPI/GetWeather', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          credentials: 'include'
        },
        body: JSON.stringify({
          location: 'in',
        }),
        credentials: 'include'
      });
      var weather: WeatherInformation = await response.json();
      // Update the array of events in state
      this.setState({
        weatherLoaded: true,
        weather: weather,
      });
    }
    catch (err) {
      console.log(err);
    }

  }
  //this.state.events.filter(event => moment(event.start?.dateTime).day() === sunday.day() && moment.unix(hourly.dt).tz(Zone).hours() > 6 && moment.unix(hourly.dt).tz(Zone).hours() < 20
  render() {
    if (!this.state.weatherLoaded || this.state.weather === undefined) return null;
    var sunday = moment().startOf('day');
    var monday = moment(sunday).add(1, 'day');
    var tuesday = moment(monday).add(1, 'day');
    var Zone = "Europe/Berlin";
    var hoursfrom = 7;
    var hourstill = 19;
    var dataset:any = [];
    var values:any =[];
    var labels:any =[];
    this.state.weather.hourly.forEach((element: HourlyWeatherDataPoint) => {
      values.push(element.temp);
      let date = new Date(element.dt * 1000);
      labels.push(date.getHours() + "h");
    });
    dataset.push({type:'line',"label":"Temperatur","data":values,"unit":"°C","fill":false});
    values = [];
    this.state.weather.hourly.forEach((element: HourlyWeatherDataPoint) => {
      values.push(element.pop*100);
    });
    dataset.push({type:'line',"label":"Regenwahrscheinlichkeit","data":values,"unit":"%","fill":false});
    values = [];
    this.state.weather.hourly.forEach((element: HourlyWeatherDataPoint) => {
      values.push(element.feels_like);
    });
    dataset.push({type:'line',"label":"gefühlte Temperatur","data":values,"unit":"°C","fill":false, borderDash: [10,5]});
    values = [];
    this.state.weather.hourly.forEach((element: HourlyWeatherDataPoint) => {
      if(element.rain !== undefined)
      {
        values.push(element.rain['1h']);
      }
      else values.push(0);
    });
    dataset.push({type: 'bar',"label":"Regen","data":values,"unit":"mm", stack:"1"});
    values = [];
    this.state.weather.hourly.forEach((element: HourlyWeatherDataPoint) => {
      if(element.snow !== undefined)
      {
        values.push(element.snow['1h']);
      }
      else values.push(0);
    });
    dataset.push({type: 'bar',"label":"Schnee","data":values,"unit":"mm", stack:"1"});

    var datasetdaily:any = [];
    var valuesdaily:any =[];
    var labelsdaily:any =[];
    this.state.weather.daily.forEach((element: DailyWeatherDataPoint) => {
      valuesdaily.push(element.temp.max);
      let date = new Date(element.dt*1000);
      labelsdaily.push(date.toLocaleDateString('de-DE', {
        day: '2-digit'
    }) + ".");
    });
    datasetdaily.push({type:'line',"label":"Max Temperatur","data":valuesdaily,"unit":"°C","fill":false});
    valuesdaily = [];
    this.state.weather.daily.forEach((element: DailyWeatherDataPoint) => {
      valuesdaily.push(element.temp.min);
    });
    datasetdaily.push({type:'line',"label":"Min Temperatur","data":valuesdaily,"unit":"°C","fill":false});
    valuesdaily = [];
    this.state.weather.daily.forEach((element: DailyWeatherDataPoint) => {
      valuesdaily.push(element.pop*100);
    });
    datasetdaily.push({type:'line',"label":"Regenwahrscheinlichkeit","data":valuesdaily,"unit":"%","fill":false});
    valuesdaily = [];
    this.state.weather.daily.forEach((element: DailyWeatherDataPoint) => {
      if(element.rain !== undefined)
      {
        valuesdaily.push(element.rain);
      }
      else valuesdaily.push(0);
    });
    datasetdaily.push({type: 'bar',"label":"Regen","data":valuesdaily,"unit":"mm", stack:"1"});
    valuesdaily = [];
    this.state.weather.daily.forEach((element: DailyWeatherDataPoint) => {
      if(element.snow !== undefined)
      {
        valuesdaily.push(element.snow);
      }
      else valuesdaily.push(0);
    });
    datasetdaily.push({type: 'bar',"label":"Schnee","data":valuesdaily,"unit":"mm", stack:"1"});

    var limitedhours = this.state.weather.hourly.filter((hourly: HourlyWeatherDataPoint) => moment.unix(hourly.dt).tz(Zone).hours() >= hoursfrom && moment.unix(hourly.dt).tz(Zone).hours() <= hourstill);
    var lastday = moment.unix(limitedhours[limitedhours.length - 1].dt).tz(Zone).endOf('day');
    let rootelem = document.getElementById('root');
    var width = 0;
    var height = 0;
    if (rootelem) {
      width = /*window.innerWidth*/ rootelem.clientWidth - 45;
      height = /*window.innerHeight*/ rootelem.clientHeight / 10;
    }
    height = Math.max(height, 300);
    var lineparamshourly = CalculateAxis(labels, dataset, BuildOptions(true, false, false));
    var lineparamsdaily = CalculateAxis(labelsdaily, datasetdaily, BuildOptions(false, true, true));

    return (
      <div style={{ color: '#FFF' }}>
        <Bar width={width} height={height} data={lineparamshourly.data} options={lineparamshourly.options as any} key={width * height} redraw/>
        <Bar width={width} height={height} data={lineparamsdaily.data} options={lineparamsdaily.options as any} key={width * height} redraw/>
      </div>
    );
  }
}

function BuildOptions(showTime: boolean, showDate:boolean, round: boolean):ChartOptions
{
  var colors = { fgcolor: '#FFFFFF', bgcolor: '#000000', midcolor: '#787c83', redcolor: '#bf1027' };
  var options = {
    responsive: false,
    hoverMode: 'index',
    stacked: false,
    title: {
      display: false,
      text: 'SmartHome Daten',
      color: colors.fgcolor
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: colors.fgcolor
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        axis: "x",
        id: "x",
        scaleLabel: {
          display: false,
          labelString: 'Zeit',
          color: colors.fgcolor
        },
        ticks: {
          /*callback: function (value) {
              //console.log(value);
              return new Date(value).toLocaleDateString('de-DE', {
                  year: '2-digit', month: '2-digit', day: '2-digit'
              }) + '\n' + new Date(value).toLocaleTimeString('de-DE', {
                  hour: '2-digit', minute: '2-digit',
                  hour12: false
              });
          },*/
          font: { Size: 16 },
          maxRotation: 0,
          color: colors.fgcolor
        },
        grid: {
          display: true,
          borderDash: [4, 4],
          //offsetGridLines: true,
          color: colors.midcolor,
          borderColor: colors.midcolor,
        }
      },
    },
    pan: {
      enabled: true,
      mode: 'x'
    },
    zoom: {
      enabled: true,
      mode: 'x'
    }/*,
  maintainAspectRatio: false*/
  };
return options as any;
}

function CalculateAxis(xLables:any, datasets:any, options:ChartOptions)
{
  var colors = { fgcolor: '#FFFFFF', bgcolor: '#000000', midcolor: '#787c83', redcolor: '#bf1027' };
  //var color = ['red', 'DodgerBlue', colors.fgcolor, 'yellow', 'purple', 'azure'];
  var color = {Regenwahrscheinlichkeit: 'cyan', Temperatur: colors.redcolor, 'gefühlte Temperatur': colors.redcolor, 'Max Temperatur': colors.redcolor, 'Min Temperatur': 'DodgerBlue', 'UV Index':'yellow', Regen: 'cyan', Schnee: 'white', Windgeschwindigkeit: 'purple'};
  var axis = [];
  options.scales = {};
        for (var i = 0; i < datasets?.length; i++) {
            datasets[i].borderColor = color[datasets[i].label as keyof typeof color];
            datasets[i].borderWidth = 2;
            datasets[i].backgroundColor = color[datasets[i].label as keyof typeof color];
            //datasets[i].backgroundColor = 'rgba(0,0,0,0)';
            datasets[i].pointBorderColor = 'rgba(0,0,0,0)';
            datasets[i].pointBackgroundColor = 'rgba(20,20,20,0)';
            datasets[i].pointBorderWidth = 0.5;
            //datasets[i].lineTension = 0;
            if (!(datasets[i].unit in axis))
            {
                axis[datasets[i].unit] = true;
                var axisOptions = {
                  type: "linear",
                  display: true,
                  stacked: false,
                  position: "right",
                  id: "yaxis" + datasets[i].unit,
                  ticks: {
                      color: color[datasets[i].label as keyof typeof color], // this here
                  },
                  grid: {
                      display: true,
                      color: colors.midcolor,
                      borderColor: colors.midcolor,
                      drawOnChartArea: true,
                      borderDash: [4, 4]
                      //drawTicks: true
                  }
              }
              if(datasets[i].label === 'UV Index' || datasets[i].label === 'Regenwahrscheinlichkeit'|| /*datasets[i].label === 'Regen'||*/ datasets[i].label === 'Schnee') axisOptions.display = false;
              //options.scales[axisOptions.id] = axisOptions;
              Object.assign(options.scales, {[axisOptions.id]: axisOptions});
            }
            datasets[i].yAxisID = "yaxis" + datasets[i].unit;
            datasets[i].xAxisID = "x";
        }
        return {data:{labels: xLables, datasets: datasets}, options:options};
}

export default Weather;