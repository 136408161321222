import React, { Component } from 'react';
import iro from '@jaames/iro';

class HSBColorPicker extends Component {
    componentDidMount() {
        const { props } = this;
        // create a new iro color picker and pass component props to it
        this.HueColorPicker = new iro.ColorPicker(this.hue, Object.assign({}, { width: (Math.min(this.props.window.height, this.props.window.width) - 10) * 0.70 * 0.6, height: (this.props.window.height - 10) * 0.7 * 0.6, color: props.color, layoutDirection: 'vertical'}, props.onColorChange, {
            layout: [
                {
                    component: iro.ui.Wheel
                }
                ]
        }));
        // call onColorChange prop whenever the color changes
        this.HueColorPicker.on('input:end', (color) => {
            if (props.onColorChange) props.onColorChange(color);
        });
        this.SatColorPicker = new iro.ColorPicker(this.sat, Object.assign({}, { width: (this.props.window.width - 10) * 0.70, height: (this.props.window.height - 10) * 0.7 * 0.4, color: props.color, layoutDirection: 'vertical' }, props.onColorChange, {
            layout: [
                {
                component: iro.ui.Slider,
                options: {
                    sliderType: 'saturation', sliderSize: (this.props.window.height - 10) * 0.7 * 0.1
                }
            }
            ]
        }));
        // call onColorChange prop whenever the color changes
        this.SatColorPicker.on('input:end', (color) => {
            if (props.onColorChange) props.onColorChange(color);
        });
        this.ValColorPicker = new iro.ColorPicker(this.val, Object.assign({}, { width: (this.props.window.width - 10) * 0.70, height: (this.props.window.height - 10) * 0.7 * 0.4, color: props.color, layoutDirection: 'vertical' }, props.onValueChange, {
            layout: [
                {
                    component: iro.ui.Slider,
                    options: {
                        sliderType: 'value', sliderSize: (this.props.window.height - 10) * 0.7 * 0.1
                    }
                }
            ]
        }));
        this.ValColorPicker.color.setChannel('hsv', 'v', props.value / 255 * 100);
        // call onColorChange prop whenever the color changes
        this.ValColorPicker.on('input:end', (color) => {
            if (props.onValueChange) props.onValueChange(color);
        });
    }

    componentDidUpdate() {
        // isolate color from the rest of the props
        const { color, value, ...colorPickerState } = this.props;
        // update color
        if (color) this.HueColorPicker.color.set(color);
        if (color) this.SatColorPicker.color.set(color);
        if (color) this.ValColorPicker.color.set(color);
        if (value) this.ValColorPicker.color.setChannel('hsv', 'v', value / 255 * 100);
        // push rest of the component props to the colorPicker's state
        this.HueColorPicker.setState(colorPickerState);
        this.SatColorPicker.setState(colorPickerState);
        this.ValColorPicker.setState(colorPickerState);
    }

    render() {
        const styles = {
            hue: {
                height: '60%',
                width: '100%',
                position: 'relative',
                marginBottom: 0,
            },
            sat: {
                height: '20%',
                width: '100%',
                position: 'relative',
                marginBottom: 0,
            },
            val: {
                height: '20%',
                width: '100%',
                position: 'relative',
                marginBottom: 0,
            },
            popup: {
                height: '100%',
                width: '100%',
                background: 'white',
            }
        }
        return (
            <div style={styles.popup}>
                <div style={styles.hue}>
                    <div ref={hue => this.hue = hue} />
                </div>
                <div style={styles.sat}>
                    <div ref={sat => this.sat = sat} />
                </div>
                <div style={styles.val}>
                    <div ref={val => this.val = val} />
                </div>
            </div>
        );
    }
}

export default HSBColorPicker;