import React, { Component } from 'react';

class Shutter extends Component {
    render() {
        var stylesheet = JSON.parse(JSON.stringify(this.props.design));
        if (this.props.item.value === 100) stylesheet.background = "#4f4f4f";
        else stylesheet.background = "#e0e0e0";
        stylesheet["textAlign"] = "center";
        stylesheet["position"] = "absolute";
        return (
            <div style={stylesheet} onClick={(e) => this.props.globalstate.app.selectedItemChanged(this.props.item, e)}>{this.props.item.value}</div>
            );
    }
}

export default Shutter;