import React, { Component } from 'react';
import { Interactive } from 'react-interactive';

class AdminMenu extends Component {
constructor(props) {
        super(props);
        this.state = {
            ShowAdminMenu: this.props.show
        };
        this.sendCommand = this.sendCommand.bind(this);
        this.initStates = this.initStates.bind(this);
        this.logout = this.logout.bind(this);
    }
    render() {
        var output = "";
        var colors = this.props.globalstate.colors.light;
        if (this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var hoverstyle = { fill: colors.fgcolor, stroke: colors.fgcolor, color: colors.fgcolor, background: colors.fgcolor + '33', boxShadow: '0 8px 16px 0 ' + colors.fgcolor + '33 , 0 6px 20px 0 ' + colors.fgcolor + '30', zIndex: 100 }

        if (this.state.ShowAdminMenu) {
            var buttonstyle = { fill: colors.midcolor, stroke: colors.midcolor, flex: 1, margin: '5px 10px 10px 10px', border: '1px', borderStyle: 'solid', height: '32px', color: colors.midcolor, background: 'none', zIndex: 100, position: 'relative' }
            output =
                <div style={{ top: '5px', left: '5px', width: '80%', background: colors.bgcolor, position: 'absolute', border: '1px', borderStyle: 'solid', color: colors.fgcolor, zIndex: 200 }} >
                <Interactive as="div" hover={hoverstyle} onClick={(e) => this.sendCommand('/docker/webserver/restart', '1', e)} style={Object.assign({}, buttonstyle)} >
                        <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Reload Webserver</div>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} id='SmarthomeAutomation' onClick={this.props.globalstate.app.showSmartHomeConfigurator} style={Object.assign({}, buttonstyle)} >
                    <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Smarthome Configuration</div>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} onClick={(e) => this.initStates()} style={Object.assign({}, buttonstyle)} >
                    <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Init States</div>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} onClick={this.props.globalstate.toggleMirrorMode} style={Object.assign({}, buttonstyle)} >
                    <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Toggle Mirror Mode</div>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} onClick={this.logout} style={Object.assign({}, buttonstyle)} >
                    <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Logout</div>
                </Interactive>
                </div>
        }

        return output;
}
    componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.props.show) {
            this.setState({ ShowAdminMenu: nextProps.show });
        }
    }
    sendCommand(topic, value, e) {
        this.props.close(false);
        fetch('https://' + window.location.hostname + '/HomeAPI/AdminPublish', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: this.props.globalstate.location,
                topic: topic,
                value: value
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .catch(error => console.log(error));
    }
    initStates() {
        this.props.close(false);
        fetch('https://' + window.location.hostname + '/HomeAPI/InitStates', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: this.props.globalstate.location
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .catch(error => console.log(error));
    }
    logout()
    {
        console.log("Logging out...");
        fetch('https://' + window.location.hostname + '/HomeAPI/signout')
            .then(this.props.globalstate.logoutfunc);
    }
}
export default AdminMenu;