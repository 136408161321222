import React, { Component } from 'react';

class Window extends Component {
    render() {
        var stylesheet = JSON.parse(JSON.stringify(this.props.design));
        if (this.props.item.value === 1) stylesheet.background = this.props.globalstate.currentColors.redcolor;
        else stylesheet.background = this.props.globalstate.currentColors.greencolor;
        stylesheet["position"] = "absolute";
        return (
            <div style={stylesheet} onClick={(e) => this.props.globalstate.app.selectedItemChanged(this.props.item, e)}></div>
        );
    }
}

export default Window;