import React, { Component } from 'react';
import Select from 'react-select';
import { Interactive } from 'react-interactive';
//import ReactTooltip from 'react-tooltip';


class SmarthomeAutomationConfigItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item
        };
    }
    render() {
        var out = "";
        var options = [{ 'value': 'MQTT', 'label': 'MQTT' }, { 'value': 'EMAIL', 'label': 'EMAIL' }];
        if (this.props.item === undefined || this.props.globalstate === undefined) return out;

        var colors = this.props.globalstate.colors.light;
        if (this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var hoverstyle = { fill: colors.fgcolor, stroke: colors.fgcolor, color: colors.fgcolor, background: colors.fgcolor + '33', boxShadow: '0 8px 16px 0 ' + colors.fgcolor + '33 , 0 6px 20px 0 ' + colors.fgcolor + '30', zIndex: 100 }
        var buttonstyle = { fill: colors.midcolor, stroke: colors.midcolor, flex: 1, margin: '5px 10px 10px 10px', border: '1px', borderStyle: 'solid', height: '32px', color: colors.midcolor, background: 'none', zIndex: 100, position: 'relative' }

        var divstyle = { fill: colors.midcolor, stroke: colors.midcolor, margin: '5px 10px 10px 10px', border: '1px', borderStyle: 'solid', height: '32px', color: colors.midcolor, background: 'none', zIndex: 100, position: 'relative' }

        var height = 36;

        const customStyles = {
            container: (provided) => ({
                ...provided,
                flex: 'none',
                //display: 'inline-block',
                width: '100px',
                height: height + 'px',
                minHeight: '1px',
                textAlign: 'left',
                border: '1px solid ' + colors.midcolor ,
                margin: '5px 10px 10px 10px',
                background: colors.bgcolor
            }),
            control: (provided) => ({
                ...provided,
                border: '0',
                borderRadius: '0',
                minHeight: '1px',
                height: (height - 2) + 'px',
                background: colors.bgcolor
            }),
            menu: (provided) => ({
                ...provided,
                margin: '0',
                border: '1px solid ' + colors.midcolor,
                borderRadius: '0',
                background: colors.bgcolor
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                minHeight: '1px',
                height: '30px',
                width:'30px',
                paddingTop: '0',
                paddingBottom: '0',
                color: colors.midcolor
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                minHeight: '1px',
                //height: '24px',
                background: colors.midcolor
            }),
            singleValue: (provided) => ({
                ...provided,
                color: colors.midcolor
            }),
            option: (provided, state) => ({
                ...provided,
                color: state.isFocused ? colors.fgcolor : colors.midcolor,
                backgroundColor: state.isDisabled
                    ? null
                    : state.isSelected
                        ? colors.redcolor
                        : null,
            })
        };

        if (this.props.item.Action.Type === "MQTT") {
            out = <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }} >
                <div style={divstyle}>{this.props.index}</div>
                <input style={divstyle} type="checkbox" onChange={(e) => this.changeAttribute('Activated', e)} defaultChecked={(this.state.item.Activated === 'true')} />
                <input style={divstyle} type="text" value={this.state.item.Name} onChange={(e) => this.changeAttribute('Name', e)} />
                <input data-tip={this.humanReadableRule(this.state.item.Rule)} style={divstyle} type="text" value={this.state.item.Rule} onChange={(e) => this.changeAttribute('Rule', e)} />
                <Select styles={customStyles} onChange={this.handleActionTypeChange('single')} options={options} value={{ 'value': this.state.item.Action.Type, 'label': this.state.item.Action.Type }} />
                <input style={divstyle} type="text" value={this.state.item.Action.Topic} onChange={(e) => this.changeAttribute('Action.Topic', e)} />
                <input style={divstyle} type="text" value={this.state.item.Action.Message} onChange={(e) => this.changeAttribute('Action.Message', e)} />
                <Interactive as="div" hover={hoverstyle} onClick={(e) => this.props.onDelete(this.props.index)} style={Object.assign({}, buttonstyle)} >
                    <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Delete</div>
                </Interactive>
            </div>;
        }
        else if (this.props.item.Action.Type === "EMAIL") {
            out = <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }} >
                <div style={divstyle}>{this.props.index}</div>
                <input style={divstyle} type="checkbox" onChange={(e) => this.changeAttribute('Activated', e)} defaultChecked={(this.state.item.Activated === 'true')} />
                <input style={divstyle} type="text" value={this.state.item.Name} onChange={(e) => this.changeAttribute('Name', e)} />
                <input data-tip={this.humanReadableRule(this.state.item.Rule)} style={divstyle} type="text" value={this.state.item.Rule} onChange={(e) => this.changeAttribute('Rule', e)} />
                <Select styles={customStyles} onChange={this.handleActionTypeChange('single')} options={options} value={{ 'value': this.state.item.Action.Type, 'label': this.state.item.Action.Type }} />
                <input style={divstyle} type="text" value={this.state.item.Action.Subject} onChange={(e) => this.changeAttribute('Action.Subject', e)} />
                <input style={divstyle} type="text" value={this.state.item.Action.Message} onChange={(e) => this.changeAttribute('Action.Message', e)} />
                <Interactive as="div" hover={hoverstyle} onClick={(e) => this.props.onDelete(this.props.index)} style={Object.assign({}, buttonstyle)} >
                    <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Delete</div>
                </Interactive>
            </div>;
        }
        else {
            out = <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }} >
                <div style={divstyle}>{this.props.index}</div>
                <input style={divstyle} type="checkbox" onChange={(e) => this.changeAttribute('Activated', e)} defaultChecked={(this.state.item.Activated === 'true')} />
                <input style={divstyle} type="text" value={this.state.item.Name} onChange={(e) => this.changeAttribute('Name', e)} />
                <input style={divstyle} type="text" value={this.state.item.Rule} onChange={(e) => this.changeAttribute('Rule', e)} />
            </div>;}
        return out;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ item: nextProps.item });
    }

    humanReadableRule(rule) {
        var out = '';
        var count = 0;
        try {
            var n = rule.search("State_");
            while (n >= 0 && count < 50) {
                out += rule.substring(0, n);
                rule = rule.substring(n + 6);
                var num = rule.substring(0, rule.match(/\D/).index);
                rule = rule.substring(rule.match(/\D/).index);
                out += this.props.states[num].desc + ' ';
                n = rule.search("State_");
                count++;
            }

            out += rule;
        }
        catch (err) {
            out = 'Invalid rule!';
        }
        return out;
    }

    changeAttribute(attr, e)
    {
        var newitem = this.state.item;
        var attribs = attr.split('.');
        if (attribs.length === 1) newitem[attribs[0]] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        else if (attribs.length === 2) newitem[attribs[0]][attribs[1]] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.props.onChange(this.props.index, newitem);
        //console.log(newitem);
    }
    handleActionTypeChange = name => value => {
        if (value.value !== this.state.item.Action.Type) {
            var newitem = this.state.item;
            if (value.value === 'MQTT')
            {
                newitem.Action = { $: { 'xsi:type': "MQTTActionConfigItem" }, Message: "Message", QoS: "2", Topic: "topic", Type: "MQTT" };
            }
            else if (value.value === 'EMAIL') {
                newitem.Action = { $: { 'xsi:type': "EMAILActionConfigItem" }, From: "automation@roehder.eu", Message: "Message", Subject: "subject", To: { string: ['one@email.de', 'two@email.de'] }, Type: "EMAIL" };
            }
            console.log(newitem);
            this.props.onChange(this.props.index, newitem);
        }
    };
}

export default SmarthomeAutomationConfigItem;