import React, { Component } from 'react';

class ChangeHouse extends Component {
    render() {
        var divstyle = this.props.divstyle

        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 512 431.687" style={Object.assign({},{enableBackground: 'new 0 0 512 431.687'})}>
            <path d="M0,123.801c1.512-2.608,3.459-4.818,5.692-6.865
	            C46.594,79.439,87.459,41.901,128.339,4.382c6.351-5.829,8.848-5.855,15.254-0.002c14.637,13.376,29.227,26.805,43.845,40.201
	            c1.202,1.103,2.473,2.13,4.154,3.571c1.166-4.527,0.557-8.632,0.583-12.656c0.128-19.469,0.081-19.47,19.75-19.481
	            c0.667,0,1.333-0.018,2-0.005c6.612,0.131,9.813,3.195,9.847,9.858c0.079,15.663,0.128,31.329-0.07,46.99
	            c-0.046,3.667,1.039,6.124,3.782,8.587c13.389,12.022,26.585,24.26,39.819,36.456c5.671,5.227,5.875,8.71,0.969,14.481
	            c-1.834,2.158-3.719,4.273-5.591,6.399c-5.355,6.08-8.741,6.32-14.63,0.923c-20.881-19.141-41.743-38.304-62.61-57.459
	            c-15.221-13.973-30.518-27.864-45.594-41.992c-2.986-2.798-4.715-2.701-7.677,0.032C96.657,73.058,61.02,105.695,25.41,138.361
	            c-7.888,7.235-10.251,7.088-17.398-1.109c-2.713-3.113-5.344-6.299-8.012-9.451C0,126.468,0,125.134,0,123.801z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M512,335.801c-3.207,3.789-6.363,7.621-9.632,11.356
	            c-4.918,5.619-8.407,5.904-13.92,0.876c-18.342-16.733-36.616-33.54-54.908-50.328c-17.923-16.449-35.901-32.84-53.689-49.435
	            c-2.953-2.756-4.668-2.815-7.671-0.041c-35.003,32.342-70.138,64.54-105.24,96.774c-1.596,1.465-3.139,3-4.833,4.342
	            c-3.975,3.146-7.502,2.972-11.064-0.7c-2.665-2.747-5.142-5.684-7.612-8.612c-4.511-5.348-4.341-8.979,0.852-13.767
	            c16.907-15.587,33.873-31.11,50.817-46.655c24.313-22.305,48.625-44.608,72.939-66.91c6.801-6.238,9.164-6.214,15.983,0.055
	            c14.353,13.194,28.715,26.377,43.085,39.553c1.315,1.206,2.711,2.324,5.108,4.37c0-7.829-0.019-14.541,0.005-21.253
	            c0.03-8.702,2.676-11.362,11.304-11.407c3.5-0.018,7.001-0.063,10.498,0.014c6.645,0.145,9.713,3.204,9.741,9.93
	            c0.063,15.164,0.249,30.333-0.11,45.489c-0.115,4.863,1.533,7.983,5.016,11.094c12.552,11.21,24.851,22.7,37.275,34.053
	            c2.346,2.143,4.56,4.363,6.057,7.203C512,333.134,512,334.468,512,335.801z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M463.783,384.739c-0.001,9.661,0.077,19.324-0.021,28.984
	            c-0.118,11.64-6.399,17.829-17.992,17.854c-13.826,0.029-27.654-0.098-41.478,0.082c-3.333,0.044-4.171-1.001-4.146-4.223
	            c0.141-17.656,0.081-35.314,0.045-52.973c-0.013-6.238-0.671-6.862-6.922-6.874c-11.328-0.021-22.655-0.007-33.983-0.004
	            c-7.019,0.001-7.497,0.46-7.499,7.302c-0.006,17.325-0.1,34.65,0.076,51.974c0.036,3.58-0.681,4.893-4.599,4.821
	            c-13.988-0.254-27.984-0.061-41.977-0.112c-9.954-0.037-16.746-5.921-16.919-15.778c-0.357-20.483-0.105-40.978-0.19-61.467
	            c-0.009-1.968,1.046-3.021,2.284-4.146c27.988-25.424,55.99-50.834,83.927-76.315c1.846-1.683,2.964-1.848,4.893-0.07
	            c27.431,25.292,54.922,50.519,82.458,75.694c1.682,1.537,2.084,3.166,2.07,5.265C463.749,364.749,463.783,374.744,463.783,384.739z"
	            />
            <path fillRule="evenodd" clipRule="evenodd" d="M223.8,176.79c-0.003,9.496,0.026,18.991-0.011,28.486
	            c-0.048,12.168-6.335,18.337-18.56,18.323c-13.494-0.015-26.99-0.16-40.479,0.088c-3.884,0.071-4.654-1.201-4.617-4.81
	            c0.182-17.323,0.086-34.648,0.078-51.974c-0.003-6.847-0.477-7.296-7.486-7.301c-11.495-0.007-22.991,0.117-34.482-0.08
	            c-4.7-0.08-6.568,1.64-6.523,6.426c0.168,17.656-0.026,35.316,0.166,52.973c0.04,3.665-0.843,4.841-4.668,4.775
	            c-13.823-0.238-27.652-0.048-41.478-0.112c-11.003-0.051-17.399-6.24-17.488-17.342c-0.158-19.655-0.007-39.313-0.088-58.971
	            c-0.009-2.205,0.676-3.71,2.281-5.165c28.027-25.383,56.031-50.791,83.97-76.27c1.899-1.731,3.005-1.712,4.878,0.015
	            c27.434,25.29,54.916,50.527,82.468,75.688c1.887,1.723,2.064,3.565,2.057,5.765C223.783,157.134,223.803,166.962,223.8,176.79z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M127.987,396.035c0-4.659,1.562-10.189-0.42-13.742
	            c-2.167-3.885-8.422-2.187-12.86-3.004c-21.072-3.878-40.845-10.829-57.842-24.277c-21.799-17.248-33.767-40.245-38.698-67.238
	            c-0.627-3.435-1.067-6.903-1.593-10.356c-0.273-1.793-0.991-3.845,1.135-4.899c2.414-1.197,3.157,1.188,4.144,2.519
	            c7.685,10.363,16.351,19.709,26.91,27.263c15.508,11.094,33.025,16.223,51.767,17.594c7.797,0.57,15.647,0.396,23.47,0.675
	            c3.125,0.11,4.436-0.896,4.293-4.29c-0.265-6.32-0.044-12.66-0.086-18.991c-0.023-3.468,0.349-6.685,3.905-8.498
	            c3.621-1.846,6.034,0.468,8.431,2.509c21.055,17.929,42.091,35.879,63.106,53.854c5.434,4.647,5.517,8.586,0.143,13.188
	            c-21.257,18.206-42.566,36.353-63.875,54.497c-2.176,1.854-4.415,3.646-7.617,2.077c-3.127-1.531-4.071-4.179-4.078-7.382
	            c-0.008-3.832-0.002-7.664-0.002-11.496C128.141,396.035,128.064,396.035,127.987,396.035z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M384.041,35.68c0,4.657-1.593,10.24,0.431,13.722
	            c2.189,3.769,8.47,2.122,12.934,2.943c22.275,4.099,42.982,11.688,60.517,26.545c20.068,17.005,31.067,39.067,35.862,64.555
	            c0.707,3.758,1.155,7.563,1.75,11.343c0.268,1.694,0.574,3.505-1.325,4.374c-2.14,0.979-2.94-1.028-3.847-2.274
	            c-9.308-12.793-20.197-23.883-33.912-32.008c-18.714-11.087-39.378-13.656-60.563-13.893c-12.13-0.136-12.127-0.064-12.104,11.941
	            c0.008,4.332,0.027,8.664-0.023,12.996c-0.036,3.018-1.063,5.488-3.967,6.941c-2.932,1.468-5.195,0.087-7.267-1.677
	            c-21.696-18.461-43.39-36.925-65.01-55.475c-4.462-3.828-4.464-7.981,0.04-11.849c21.611-18.561,43.308-37.022,64.996-55.493
	            c2.083-1.774,4.32-3.147,7.253-1.645c2.883,1.479,3.922,3.936,3.957,6.955c0.047,3.998,0.012,7.997,0.012,11.996
	            C383.863,35.679,383.952,35.679,384.041,35.68z"/>
            </svg>

</div>
        );
    }
}
export default ChangeHouse;

