import React, { Component } from 'react';

class DoubleArrow extends Component {
    render() {
        var divstyle = this.props.divstyle

        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 55 55" style={Object.assign({},{enableBackground: 'new 0 0 55 55'})}>
            <polygon points="28,27.5 55,8.513 55,27.155 55,46.487 "/>
            <polygon points="0,27.5 27,8.5 27,27.5 27,46.5 "/>
            </svg>

</div>
        );
    }
}
export default DoubleArrow;

