import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import localization from 'moment/locale/de';
import { Interactive } from 'react-interactive';
import 'react-datepicker/dist/react-datepicker.css';
import LineChart from './LineChart';
import Select from 'react-select';
import de from "date-fns/locale/de";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from "react-cookie";

class CustomLineChartPage extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };
    constructor(props) {
        moment().locale("de", localization);
        super(props);
        this.state = {
            options1stLevel: undefined,
            options2ndLevel: [],
            sensortypemode: true,
            selected1stLevel: undefined,
            selected2ndLevel: undefined,
            selectedGraphOptions: this.props.cookies.get("customgraphselectedGraphOptions") || [],
            customgraphsince: (moment().subtract(3600 * 12, 'seconds')).toDate(),
            customgraphtill: moment().toDate(),
            customgraphsensorid: 1,
            customgraphauto: false
        };

        this.handleDateSinceChange = this.handleDateSinceChange.bind(this);
        this.handleDateTillChange = this.handleDateTillChange.bind(this);
        this.handleSensorIDChange = this.handleSensorIDChange.bind(this);
        this.handleCheckGraphAuto = this.handleCheckGraphAuto.bind(this);
        this.updateValues = this.updateValues.bind(this);
        this.updateGraphOptions = this.updateGraphOptions.bind(this);
        //this.handleIDChange = this.handleIDChange(this);
    }

    handleCheckGraphAuto(event) {
        var since = (moment().subtract(3600 * 12, 'seconds')).toDate();
        if (event.target.checked) since = (moment().subtract(3600 * 24 * 14, 'seconds')).toDate();
        this.setState({ customgraphsince: since, customgraphauto: event.target.checked });
    }
    handleDateSinceChange(date) {
        this.setState({
            customgraphsince: date
        });
    }
    handleDateTillChange(date) {
        this.setState({
            customgraphtill: date
        });
    }
    handleSensorIDChange(event) {
        this.setState({
            customgraphsensorid: event.target.value
        });
    }
    componentDidMount() {
        this.updateValues(this.props.globalstate.location);
    }
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    updateValues(loc) {
        if (this.state.sensortypemode) {
            fetch('https://' + window.location.hostname + '/HomeAPI/GetSensorTypes', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    location: loc
                }),
                credentials: 'include'
            })
                .then(this.handleErrors)
                .then(result => result.json())
                .then(options1stLevel => this.setState({ options1stLevel }))
                .catch(error => console.log(error));
        }
        else {
            fetch('https://' + window.location.hostname + '/HomeAPI/GetDevicesFromLocation', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    location: loc
                }),
                credentials: 'include'
            })
                .then(this.handleErrors)
                .then(result => result.json())
                .then(options1stLevel => this.setState({ options1stLevel }))
                .catch(error => console.log(error));
        }
        
    }
 
    updateGraphOptions(loc, id, sensortypemode)
    {
        var body = JSON.stringify({
            location: loc,
            deviceid: id,
            since: 3600
        });
        if(sensortypemode) 
        {
            body = JSON.stringify({
                location: loc,
                sensorid: id,
                since: 3600
            });
        }
        fetch('https://' + window.location.hostname + '/HomeAPI/GetDeviceAndSensorOptions', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
        credentials: 'include'
        })
        .then(this.handleErrors)
        .then(result => result.json())
        .then(options2ndLevel => this.setState({ options2ndLevel }))
        .catch(error => console.log(error));
    }

    handleIDChange = name => value => {
        this.setState({ selected1stLevel: value, selected2ndLevel: undefined });
        this.updateGraphOptions(this.props.globalstate.location, value.value, this.state.sensortypemode)
    };
    handleIDChange2ndLevel = name => value => {
        this.setState({ selected2ndLevel: value });
    };


    ToggleButton(){
        this.setState({
            sensortypemode: !this.state.sensortypemode,
            options1stLevel: undefined,
            options2ndLevel: []
        });
        
        this.setState({ graphoptions: [] });
    }
    AddButton(){
        if(this.state.selected1stLevel !== undefined && this.state.selected2ndLevel !== undefined)
        {//check duplicate
            var copy = this.state.selectedGraphOptions;
            var locid = 0;
            if (this.props.globalstate.location == 'rcm' || this.props.globalstate.location == 'ka') locid = 1;
            else if (this.props.globalstate.location == 'ac') locid = 2;
            if (this.state.sensortypemode) {
                copy.push({ location_id: locid, device_id: this.state.selected2ndLevel.value, sensor_id: this.state.selected1stLevel.value, value: this.state.selected2ndLevel.value +':'+ this.state.selected1stLevel.value, label: this.state.selected2ndLevel.label + ' ' + this.state.selected1stLevel.label});
            }
            else {
                copy.push({ location_id: locid, device_id: this.state.selected1stLevel.value, sensor_id: this.state.selected2ndLevel.value, value: this.state.selected1stLevel.value +':'+ this.state.selected2ndLevel.value, label: this.state.selected1stLevel.label + ' ' + this.state.selected2ndLevel.label });
            }
            const { cookies } = this.props;
            cookies.set("customgraphselectedGraphOptions", copy, { path: "app/" });
            this.setState({ selectedGraphOptions: copy });
        }
    }
    removeOptions = name => value => {
        const { cookies } = this.props;
        cookies.set("customgraphselectedGraphOptions", value, { path: "app/" });
        this.setState({ selectedGraphOptions: value });
    };

    render() {
        var output = "";
        var colors = this.props.globalstate.colors.light;
        if (this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var divstyle = {};
        if (this.props.globalstate.dark) divstyle = Object.assign({}, this.props.globalstate.config.STYLES.div100, this.props.globalstate.config.STYLES.bgdark);
        else divstyle = Object.assign({}, this.props.globalstate.config.STYLES.div100, this.props.globalstate.config.STYLES.bglight);
        var height = 36;
        if (this.state.options1stLevel === undefined) this.updateValues(this.props.globalstate.location);
        if (this.state.options1stLevel !== undefined && this.state.options1stLevel.length > 0) {
            const customStyles = {
                container: (provided) => ({
                    ...provided,
                    flex: 'none',
                    //display: 'inline-block',
                    width: '100%',
                    minHeight: '1px',
                    textAlign: 'left',
                    border: '1px solid ' + colors.midcolor,
                    margin: '5px 0px 5px 0px',
                    background: colors.bgcolor
                }),
                control: (provided) => ({
                    ...provided,
                    border: '0',
                    borderRadius: '0',
                    minHeight: '1px',
                    background: colors.bgcolor
                }),
                menu: (provided) => ({
                    ...provided,
                    margin: '0',
                    border: '1px solid ' + colors.midcolor,
                    borderRadius: '0',
                    background: colors.bgcolor
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    minHeight: '1px',
                    height: '30px',
                    width: '30px',
                    paddingTop: '0',
                    paddingBottom: '0',
                    color: colors.midcolor
                }),
                indicatorSeparator: (provided) => ({
                    ...provided,
                    minHeight: '1px',
                    //height: '24px',
                    background: colors.midcolor
                }),
                singleValue: (provided) => ({
                    ...provided,
                    color: colors.midcolor
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    const color = data.color;
                    return {
                        ...styles,
                        backgroundColor: 'white',
                        color: isDisabled
                            ? '#ccc'
                            : isSelected
                                ? 'black'
                                : data.color,
                        cursor: isDisabled ? 'not-allowed' : 'default',
    
                        ':active': {
                            ...styles[':active'],
                            backgroundColor: !isDisabled && (isSelected ? data.color : color),
                        },
                    };
                },
                multiValue: (styles, { data }) => {
                    return {
                        ...styles,
                        backgroundColor: colors.bgcolor,
                    };
                },
                multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: data.color,
                }),
                multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    minHeight: '1px',
                    height: '20px',
                    width: '20px',
                    color: data.color,
                    ':hover': {
                        backgroundColor: data.color,
                        color: 'white',
                    },
                }),
                clearIndicator: (styles, { data }) => ({
                    ...styles,
                    minHeight: '1px',
                    height: '30px',
                    width: '30px'
                }),
            };
            var color = ['red', 'DodgerBlue', colors.fgcolor, 'fuchsia', 'green', 'cyan', 'orange', 'lightsteelblue'];
            var selectedoptions = []; 
            var selopt = []; 
            for (var i = 0; i < this.state.selectedGraphOptions.length; i++) {
                if (i >= color.length) color.push('grey');
                this.state.selectedGraphOptions[i].color = color[i];
                selectedoptions.push(this.state.selectedGraphOptions[i]);
                selopt.push(this.state.selectedGraphOptions[i]);
            }

            const DatePickerInput = React.forwardRef((props, ref) => {
                return (
                    <button onClick={props.onClick} style={{ background: colors.bgcolor, color: colors.midcolor, border: '1px', borderStyle: 'solid' }}>
                        {props.value}
                    </button>
                )
            });
            output = (
                <div style={divstyle}>
                    <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }}>
                        <DatePicker
                            selected={this.state.customgraphsince}
                            onChange={this.handleDateSinceChange}
                            //showTimeSelect
                            showTimeInput
                            timeIntervals={30}
                            dateFormat="dd.MM.yy HH:mm"
                            timeCaption="Zeit"
                            timeInputLabel="Zeit"
                            locale={de}
                            customInput={<DatePickerInput />}
                            maxDate={this.state.customgraphtill}
                            selectsStart
                            startDate={this.state.customgraphsince}
                            endDate={this.state.customgraphtill}
                        />
                        <button onClick={() => this.ToggleButton()}>
                    {this.state.sensortypemode? "ByDevice":"BySensor"}
                    </button>
                    <button onClick={() => this.AddButton()}>
                    Add
                    </button>
                        <DatePicker
                            selected={this.state.customgraphtill}
                            onChange={this.handleDateTillChange}
                            //showTimeSelect
                            showTimeInput
                            timeIntervals={30}
                            dateFormat="dd.MM.yy HH:mm"
                            timeCaption="Zeit"
                            timeInputLabel="Zeit"
                            locale={de}
                            minDate={this.state.customgraphsince}
                            customInput={<DatePickerInput />}
                            selectsEnd
                            startDate={this.state.customgraphsince}
                            endDate={this.state.customgraphtill}
                        />
                        <Interactive as="div" onClick={(e) => this.handleDateTillChange(moment().toDate())}>
                            <div> Now</div>
                        </Interactive>
                        <input type="checkbox" onChange={this.handleCheckGraphAuto} defaultChecked={this.state.customgraphauto} />

                    </div>
                    
                    <Select isSearchable={false} styles={customStyles} onChange={this.handleIDChange('single')} options={this.state.options1stLevel} />
                    <Select isSearchable={false} styles={customStyles} onChange={this.handleIDChange2ndLevel('single')} options={this.state.options2ndLevel} />
                    <Select styles={customStyles} defaultValue={selopt} isMulti onChange={this.removeOptions('single')} options={selectedoptions} />
                    <LineChart width={this.props.width} height={this.props.height - 36 - 21} globalstate={this.props.globalstate} since={this.state.customgraphsince} till={this.state.customgraphtill} dataparams={this.state.selectedGraphOptions} showlegend={false} />
                    
                </div>);
        }
        return output;
    }
}//<input style={{ flex: 1, margin: '1px', flexBasis: '30px' }} type="text" value={this.state.customgraphsensorid} onChange={this.handleSensorIDChange} />
/*<CustomLineChart width={this.props.width} height={this.props.height - 36 - 21} globalstate={this.props.globalstate} sensorID={this.state.selectedID} since={this.state.customgraphsince} till={this.state.customgraphtill} graphauto={this.state.customgraphauto} key={this.props.width * this.props.height}/>*/
export default withCookies(CustomLineChartPage);