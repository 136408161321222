import React, { Component } from 'react';

class Vent extends Component {
    render() {
        var colors = this.props.globalstate.colors.light;
        if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        //var stylesheet = JSON.parse(JSON.stringify(this.props.design));
        var divstyle = Object.assign({},{position:'absolute'},this.props.design)
        var svgstyle = {}
        var pathstyle = { stroke: colors.fgcolor, fill: colors.fgcolor, strokeWidth: 15 }
        if (this.props.item.value > 0) pathstyle = { stroke: colors.redcolor, fill: colors.redcolor,  strokeWidth: 15 }
        return (
    <div style={divstyle} onClick={(e) => this.props.globalstate.app.selectedItemChanged(this.props.item, e)}>
            <svg class="full absolute" version="1.1" id="Capa_1" x="0px" y="0px"
                    viewBox="0 0 428.239 452.111" style={Object.assign({}, { enableBackground:'new 0 0 428.239 452.111'}, svgstyle)}>
                    <g>
                        <path style={pathstyle} d="M234.036,421.089c12.205,10.837,25.27,17.306,40.125,20.038c16.664,3.064,32.855,1.221,48.385-5.608c36.361-15.992,66.146-40.2,89.578-72.218c8.883-12.14,8.996-26.015,0.354-38.129c-22.908-32.115-52.605-55.814-88.904-71.248c-0.9-0.382-1.846-0.657-3.291-1.169c-8.865,30.5-27.463,52.438-57.436,63.146c-30.088,10.75-58.35,5.586-84.585-12.857
		c-1.299,0.944-2.632,1.885-3.935,2.863c-33.424,25.121-70.85,40.034-112.581,43.492c-17.431,1.443-31.828-5.591-39.317-21.201
		C2.306,286.26-5.35,241.793,3.877,195.967c7.264-36.077,30.835-58.794,66.268-68.844c3.587-1.018,6.015-2.657,8.168-5.716
		c14.867-21.118,33.396-38.396,55.39-51.949c1.134-0.697,2.296-1.351,3.433-2.046c2.666-1.633,5.312-1.605,6.968,1.149
		c1.754,2.92,0.462,5.196-2.279,7.143c-9.494,6.736-19.411,13.025-28.124,20.672c-9.158,8.038-17.118,17.441-25.678,26.327
		c11.25,1.944,21.285,3.8,31.375,5.279c1.346,0.197,3.115-1.324,4.443-2.362c10.729-8.386,21.354-16.906,32.122-25.242
		c2.619-2.026,5.542-1.784,7.254,1.306c1.539,2.776,0.408,5.136-2.304,6.714c-0.72,0.418-1.474,0.796-2.13,1.3
		c-8.043,6.179-16.09,12.352-24.082,18.597c-1.264,0.987-2.257,2.321-3.727,3.863c5.541,2.807,10.663,5.401,16.265,8.239
		c-1.59,2.914-2.971,5.442-4.457,8.166c-5.343-2.556-10.338-4.768-15.156-7.314c-2.777-1.468-4.353-0.856-6.226,1.555
		c-18.854,24.266-29.313,51.627-30.763,82.37c-0.078,1.664-0.041,3.333-0.102,4.998c-0.121,3.25-1.571,5.743-5.005,5.608
		c-3.409-0.133-4.958-2.731-4.589-5.953c1.29-11.229,1.981-22.604,4.342-33.614c4.675-21.793,14.696-41.271,29.476-60.359
		c-8.719-0.812-16.31-1.453-23.886-2.238c-7.355-0.762-11.537,2.479-15.082,9.049c-14.419,26.727-22.668,54.976-23.165,85.403
		c-0.014,0.833,0.306,1.927-0.104,2.452c-1.472,1.893-2.975,4.748-4.865,5.101c-2.986,0.558-4.729-2.154-4.628-5.195
		c0.209-6.324,0.387-12.672,1.066-18.955c2.714-25.117,10.074-48.813,22.237-70.987c0.313-0.569,0.496-1.209,1.088-2.686
		c-2.271,0.77-3.96,1.203-5.536,1.896C31.223,153.168,14.313,176.86,10.9,209.916c-3.694,35.783,2.232,70.232,14.982,103.824
		c8.886,23.411,24.338,28.122,42.906,25.737c33.862-4.351,75.902-21.206,101.776-42.871c-1.058-1.185-2.086-2.386-3.167-3.538
		c-42.131-44.895-28.81-116.489,26.767-143.04c3.924-1.874,3.771-4.276,3.355-7.518c-5.163-40.305,0.738-78.983,17.177-116.091
		c8.461-19.098,24.639-28.497,45.328-26.034c39.268,4.672,75.209,18.163,107.529,41.073c34.07,24.15,47.609,62.689,36.348,102.978
		c-0.555,1.98-0.271,4.623,0.594,6.51c11.135,24.238,17.066,49.659,17.836,76.304c0.021,0.833,0.047,1.666,0.045,2.499
		c-0.01,3.176-1.139,5.915-4.588,6.033c-3.738,0.128-4.795-2.611-5.084-6.038c-0.889-10.605-1.506-21.271-3.156-31.769
		c-2.035-12.942-6.037-25.405-11.242-36.942c-6.688,7.975-13.334,15.747-19.729,23.723c-0.766,0.952-0.393,3.038-0.156,4.537
		c1.934,12.308,3.973,24.599,5.939,36.899c0.182,1.142,0.092,2.329,0.092,3.495c0.002,3.412-1.242,6.192-5.008,6.094
		c-3.445-0.091-4.432-2.775-4.672-5.968c-0.582-7.795-1.182-15.601-2.172-23.351c-0.586-4.592-1.924-9.088-3.043-14.164
		c-5.381,3.479-10.334,6.68-15.551,10.051c-1.658-2.706-3.125-5.096-4.826-7.869c4.494-3.076,8.746-6.229,13.242-8.98
		c3.16-1.935,3.621-3.882,2.18-7.322c-12.008-28.69-30.908-51.507-57.311-68.04c-0.846-0.529-1.689-1.067-2.563-1.555
		c-2.902-1.62-4.256-4.045-2.529-7.038c1.771-3.07,4.549-2.821,7.357-1.149c15.918,9.472,29.711,21.377,41.277,35.856
		c8.994,11.262,16.248,23.548,21.834,36.829c0.311,0.738,0.783,1.408,1.379,2.457c7.775-7.455,13.504-15.942,17.463-25.558
		c0.59-1.431-0.313-3.854-1.191-5.45c-15.471-28.008-36.789-50.477-64.068-67.252c-0.992-0.61-1.99-1.212-2.99-1.809
		c-2.734-1.633-3.67-4.058-2.102-6.771c1.6-2.767,4.26-3.137,6.887-1.356c7.852,5.32,15.938,10.354,23.379,16.206
		c16.916,13.305,30.838,29.381,42.213,47.654c0.682,1.094,1.443,2.137,2.623,3.872c3.197-12.539,2.771-24.346,0.055-36.044
		c-4.59-19.749-15.193-35.763-31.486-47.776c-32.52-23.977-69.087-37.592-109.195-41.777c-13.067-1.364-25.461,6.125-31.313,18.778
		c-13.948,30.167-20.247,61.884-18.842,95.091c0.292,6.909,0.991,13.803,1.469,20.245c10.055-0.85,19.291-2.404,28.506-2.28
		c50.688,0.684,91.736,46.596,86.879,97.033c-0.377,3.928,0.918,5.085,4.066,6.405c37.932,15.92,69.029,40.517,93.092,73.91
		c11.738,16.291,11.686,34.824-0.268,51.022c-24.043,32.586-54.479,57.37-91.457,73.931c-37.026,16.583-77.475,8.487-105.549-20.679
		c-1.418-1.473-3.857-2.519-5.924-2.722c-27.211-2.676-52.813-10.41-76.68-23.821c-2.711-1.523-4.776-3.246-3.756-6.663
		c0.96-3.214,4.278-3.917,8.277-1.673c15.199,8.526,31.275,14.78,48.271,18.592c6.145,1.378,12.385,2.332,19.528,3.653
		c-2.338-4.716-4.507-8.521-6.145-12.542c-1.939-4.764-3.722-9.638-4.987-14.611c-0.775-3.046-2.33-4.026-5.125-5.063
		c-12.439-4.614-24.739-9.61-37.04-14.59c-3.293-1.333-5.47-3.786-3.36-7.428c1.972-3.404,4.936-2.506,7.825-0.898
		c10.797,6.009,22.102,10.73,34.761,13.696c-0.311-6.162-0.601-11.896-0.913-18.094c3.188-0.172,5.951-0.32,9.184-0.494
		c0.586,6.992,1.148,13.708,1.731,20.662c12.91,2.797,25.677,2.967,38.456,1.775c19.818-1.846,38.438-7.74,55.814-17.47
		c3.082-1.726,6.23-2.571,8.121,1.189c1.891,3.764-0.857,5.729-3.865,7.355c-29.15,15.76-60.086,22.096-93.066,17.719
		c-0.799-0.105-1.61-0.118-3.062-0.221c2.476,10.903,7.207,20.245,13.802,28.628c0.85,1.08,3.295,1.217,5.013,1.255
		c31.833,0.707,61.708-6.715,89.714-21.797c1.172-0.632,2.322-1.304,3.492-1.938c2.773-1.506,5.311-1.197,6.859,1.631
		c1.555,2.837,0.439,5.312-2.328,6.666c-8.662,4.235-17.217,8.78-26.152,12.352c-19.968,7.979-40.844,11.938-62.338,12.615
		C237.338,420.73,236.358,420.874,234.036,421.089z M313.278,231.806c0.662-43.913-35.174-80.43-79.723-81.236
		c-44.081-0.799-80.904,35.335-81.495,79.971c-0.585,44.18,35.587,80.813,80.222,81.247
		C276.313,312.215,312.608,276.374,313.278,231.806z"/>
	<path style={pathstyle}  d="M232.737,179.087c28.545-0.049,51.934,23.18,52.01,51.654c0.078,29.217-23.035,52.489-52.149,52.509
                            c-28.578,0.02-51.922-23.162-52.014-51.649C180.489,202.378,203.572,179.136,232.737,179.087z M189.951,231.248
		c0.051,23.32,19.368,42.629,42.668,42.65c23.332,0.021,42.596-19.143,42.775-42.553c0.18-23.412-19.484-43.062-42.928-42.893
		C209.063,188.622,189.899,207.91,189.951,231.248z"/>
</g>
</svg>
</div>
        );
    }
}
export default Vent;

