import React, { Component } from 'react';

class Door extends Component {
    render() {
        var divstyle = this.props.divstyle
        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 520.343 520.343" style={Object.assign({},{enableBackground:'new 0 0 520.343 520.343'})}>
            <path d="M411.487,0H108.855C102.174,0,96.75,5.423,96.75,12.105v411.578c0,6.68,5.423,12.105,12.105,12.105
		c6.683,0,12.105-5.426,12.105-12.105V24.211h278.421v8.697l-127.105,92.279v395.155l127.791-92.781
		c1.631,4.764,6.1,8.222,11.42,8.222c6.679,0,12.104-5.42,12.104-12.105V12.1C423.592,5.417,418.166,0,411.487,0z M320.296,331.973
		c-5.704,0-10.462-7.046-11.691-16.485h-13.631v-10.592h13.825c1.448-8.86,6.012-15.38,11.497-15.38
		c6.632,0,12.005,9.498,12.005,21.231C332.307,322.468,326.928,331.973,320.296,331.973z"/>
            </svg>
</div>
        );
    }
}
export default Door;

