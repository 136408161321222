import React, { Component } from 'react';
import ControlItem from './ControlItem';

class Controls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: undefined
        };
    }
    render() {
        var output = "";
        if(this.state.items !== undefined){
        output =
            <div style={{ width: '100%', height: '100%', overflow: 'hidden', background: 'none'  }}>
                {this.state.items.map(item => <ControlItem key={item.id} id={parseInt(item.id)} type={parseInt(item.type)} value={this.props.globalstate.smarthomestate[item.id].value} desc={item.desc} unit={item.unit} location={this.props.globalstate.location} globalstate={this.props.globalstate} />)}
            </div>
        }
        return output;
    }
    componentDidMount() {
        this.loadConfig(this.props.globalstate.location);
    }
    /*componentWillReceiveProps(nextProps) {
        if (nextProps.globalstate.selectedItem !== this.state.selectedItem) this.setState({ selectedItem: nextProps.globalstate.selectedItem });
        if (nextProps.globalstate.location !== this.props.globalstate.location)
        {
            this.setState({ items: undefined });
            this.loadConfig(nextProps.globalstate.location);
        }
    }*/
    handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
    loadConfig(loc)
    {
        fetch('https://' + window.location.hostname + '/HomeAPI/GetState', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: loc
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(result => result.json())
            .then(items => this.setState({ items }))
            .catch(error => console.log(error));
    }
}

export default Controls;