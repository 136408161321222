import React, { Component } from 'react';
import Background from './Background';

class DoorWindowState extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resizedTrigger: true,
            location: ''
        };
        this.updateDimensions = this.updateDimensions.bind(this);
        this.loadConfig = this.loadConfig.bind(this);
    }
    render() {
        if (this.state.config !== undefined && this.props.globalstate !== undefined) {
            var x, y;
            if (this.props.width * this.state.config.imageratio > this.props.height) {
                
                y = (this.props.height);
                x = (this.props.height) / this.state.config.imageratio;
            }
            else {
                y = (this.props.width) * this.state.config.imageratio;
                x = (this.props.width);
            }
            var global = this.props.globalstate;
            global.doorsandwindow={height: y, width: x/this.props.scalewidth}
            return (
                <div style={{ width: `${x}px`, height: `${y}px`, background: "none", position: "relative" }}>
                    <Background globalstate={global} landscape={this.props.globalstate.landscape} layoutitems={this.state.config.items} key={this.state.config.backgroundimage} image={this.state.config.backgroundimage} floor={this.props.floor} />
                </div>
                
            );
        }
        else return "";
    }
    updateDimensions() {
        if (this.state.config !== undefined) {
            if (this.state.resizedTrigger) {
                this.setState({
                    resizedTrigger: false
                });
            }
            else {
                this.setState({
                    resizedTrigger: true
                });
            }
            clearInterval(this.timerDim);
        }
        else
        {
            this.timerDim = setInterval(
                () => this.updateDimensions(),
                200);
        }
    }
    componentDidMount() {
        this.updateDimensions();
        this.loadConfig(this.props.floor);
    }
    /*componentWillReceiveProps(nextProps) {
        if (this.props.floor !== nextProps.floor) {
            this.setState({ config: undefined });
            this.loadConfig(nextProps.floor);
        }
    }*/
    handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
    loadConfig(loc)
    {
        fetch('https://' + window.location.hostname + '/HomeAPI/GetStateConfigArray', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: loc
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(result => result.json())
            .then(config => this.setState({ config }))
            .catch(error => console.log(error));
            
    }
    componentWillUnmount() {
    }
}

export default DoorWindowState;
