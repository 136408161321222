import React, { Component } from 'react';
import { Interactive } from 'react-interactive';
import Door from './Door';
import Graph from './Graph';
import Control from './Control';
import Lock from './Lock';
import Fullscreen from './Fullscreen';
import DayNight from './DayNight';
import ChangeHouse from './ChangeHouse';

class ApplicationControls extends Component {
constructor(props) {
        super(props);
        this.state = {
            showLocationChooser: false
        };
        this.switchLocation = this.switchLocation.bind(this);
        this.showLocationChooser = this.showLocationChooser.bind(this);
        this.logout = this.logout.bind(this);
    }
    render() {
        var output ="";
        var colors = this.props.globalstate.colors.light;
        if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var activestyle = {fill: colors.redcolor, stroke: colors.redcolor,color: colors.redcolor, boxShadow: '0 8px 16px 0 '+colors.redcolor+'33 , 0 6px 20px 0 '+colors.redcolor+'30', background: colors.redcolor+'33', zIndex:100}
        var divstyle = {fill: colors.midcolor, stroke: colors.midcolor, flex: 1, margin: '5px 10px 10px 10px', border: '1px', borderStyle: 'solid', height: '32px',color: colors.midcolor, background: 'none',  zIndex:100, position:'relative' }
        var hoverstyle = {fill: colors.fgcolor, stroke: colors.fgcolor, color: colors.fgcolor, background: colors.fgcolor+'33', boxShadow: '0 8px 16px 0 '+colors.fgcolor+'33 , 0 6px 20px 0 '+colors.fgcolor+'30', zIndex:100 }
        var buttontextstyle = {position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
        var iconstyle = {position:'absolute',top:'10%',height:'80%', width:'100%'}

        if(!this.props.globalstate.loggedIn) return (
            <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }} >
                <Interactive as="div" hover={hoverstyle} id='login' onClick={this.props.globalstate.loginfunc} style={divstyle} >
                    <div style={buttontextstyle}>Login</div>
                </Interactive>
            </div>
        );

        var activated = {in: {}, rcm: {}, ac: {}, states: {}, graphs: {}, customgraphs: {}, controls: {}, stock: {}, stockgraphs: {}, fullscreen: {}, dark: {}}
        activated[this.props.globalstate.page] = activestyle;
        activated[this.props.globalstate.location] = activestyle;
        if( this.props.globalstate.dark) activated['dark'] = activestyle;
        if( this.props.globalstate.fullscreen) activated['fullscreen'] = activestyle;
        var LocationChooser = "";

        if(this.state.ShowLocationChooser)
        {
         var buttonstyle = {fill: colors.midcolor, stroke: colors.midcolor, flex: 1, margin: '5px 10px 10px 10px', border: '1px', borderStyle: 'solid', height: '32px',color: colors.midcolor, background: 'none',  zIndex:100, position:'relative' }
         LocationChooser = 
            <div style={{ top: '5px', left: '5px', width: '80%', background: colors.bgcolor, position: 'absolute', border: '1px', borderStyle: 'solid', color: colors.fgcolor, zIndex:200 }} >
                <Interactive as="div" hover={hoverstyle} id='in' onClick={this.switchLocation} style={Object.assign({},buttonstyle,activated['in'])} >
                    <div style={{position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>IN</div>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} id='rcm' onClick={this.switchLocation} style={Object.assign({},buttonstyle,activated['rcm'])} >
                    <div style={{position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>RCM</div>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} id='ac' onClick={this.switchLocation} style={Object.assign({},buttonstyle,activated['ac'])} >
                    <div style={{position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>AC</div>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} id='ka' onClick={this.switchLocation} style={Object.assign({},buttonstyle,activated['ka'])} >
                    <div style={{position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>KA</div>
                </Interactive>
            </div>
        }
        var loginbutton = <Interactive as="div" hover={hoverstyle} id='login' onClick={this.logout} style={Object.assign({}, divstyle, activestyle)} >
            <Lock divstyle={iconstyle}></Lock>
        </Interactive>
        if (this.props.globalstate.userContext != null && this.props.globalstate.userContext.admin)
        {
            loginbutton = <Interactive as="div" hover={hoverstyle} id='login' onClick={this.props.globalstate.app.showAdminMenu} style={Object.assign({}, divstyle, activestyle)} >
                <Lock divstyle={iconstyle}></Lock>
            </Interactive>
        }
        var stock = '';
        var stockchart = '';
        if (this.props.globalstate.userContext != null && this.props.globalstate.userContext.features !== undefined && this.props.globalstate.userContext.features.stock !== undefined)
        {
            stock = <Interactive as="div" hover={hoverstyle} id='stock' onClick={this.props.globalstate.app.switchPage} style={Object.assign({},divstyle,activated['stock'])} >
            <Control divstyle={iconstyle}></Control>
        </Interactive>
            stockchart = <Interactive as="div" hover={hoverstyle} id='stockgraphs' onClick={this.props.globalstate.app.switchPage} style={Object.assign({}, divstyle, activated['stockgraphs'])} >
            <Graph divstyle={iconstyle}></Graph>
        </Interactive>
        }
        output = 
            <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }} >
                {loginbutton}
                <Interactive as="div" hover={hoverstyle} onClick={this.showLocationChooser} style={Object.assign({},divstyle)} >
                    <ChangeHouse divstyle={iconstyle}></ChangeHouse>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} id='states' onClick={this.props.globalstate.app.switchPage} style={Object.assign({},divstyle,activated['states'])} >
                    <Door divstyle={iconstyle}></Door>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} id='graphs' onClick={this.props.globalstate.app.switchPage} style={Object.assign({},divstyle,activated['graphs'])} >
                    <Graph divstyle={iconstyle}></Graph>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} id='customgraphs' onClick={this.props.globalstate.app.switchPage} style={Object.assign({}, divstyle, activated['customgraphs'])} >
                    <Graph divstyle={iconstyle}></Graph>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} id='controls' onClick={this.props.globalstate.app.switchPage} style={Object.assign({},divstyle,activated['controls'])} >
                    <Control divstyle={iconstyle}></Control>
                </Interactive>
               {stock}
               {stockchart}
                <Interactive as="div" hover={hoverstyle} id='fullscreen' onClick={this.props.globalstate.app.toggleFullscreen} style={Object.assign({},divstyle,activated['fullscreen'])} >
                    <Fullscreen divstyle={iconstyle}></Fullscreen>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} id='dark' onClick={this.props.globalstate.app.toggleDayNight} style={Object.assign({},divstyle,activated['dark'])} >
                    <DayNight divstyle={iconstyle}></DayNight>
                </Interactive>
                {LocationChooser}
            </div>

        return output;
    }
    showLocationChooser()
    {
        this.setState({ ShowLocationChooser: true });
    }
    switchLocation(e) {
        this.setState({ ShowLocationChooser: false });
        this.props.globalstate.app.switchLocation(e.currentTarget.id);
    }
    logout()
    {
        console.log("Logging out...");
        this.props.globalstate.logoutfunc();
    }
}
export default ApplicationControls;