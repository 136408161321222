import React from 'react';
import {  PlannerChecklistItem } from 'microsoft-graph';

interface CheckListItemProps {
  info: PlannerChecklistItem;
}


export default class CheckListItem extends React.Component<CheckListItemProps> {
  render() {
    var output = <React.Fragment><li>{this.props.info.title}</li></React.Fragment>
    if(this.props.info.isChecked) output = <React.Fragment><li><del>{this.props.info.title}</del></li></React.Fragment>
    return output;
  }
}