import React, { Component } from 'react';

class DimLight extends Component {
    render() {
        var divstyle = Object.assign({},{position:'absolute'},this.props.design)
        var svgstyle = {}
		String.prototype.convertToRGB = function(){
			if(this.length != 7){
				throw "Only six-digit hex colors are allowed.";
			}
		
			var aRgbHex = this.match(/[\d,\w]{2}/g);
			var aRgb = {
				r: parseInt(aRgbHex[0], 16),
				g: parseInt(aRgbHex[1], 16),
				b: parseInt(aRgbHex[2], 16)
			};
			return aRgb;
		}
		var redcolor = this.props.globalstate.currentColors.redcolor.convertToRGB();
		var pathstyle = {stroke: this.props.globalstate.currentColors.fgcolor, fill:this.props.globalstate.currentColors.fgcolor }
        if(this.props.item.value > 0) pathstyle = {stroke: 'rgba(' + redcolor.r + ',' + redcolor.g + ',' + redcolor.b + ',' + this.props.item.value + ')', fill: 'rgba(' + redcolor.r + ',' + redcolor.g + ',' + redcolor.b + ',' + this.props.item.value + ')' }
        return (
    <div style={divstyle} onClick={(e) => this.props.globalstate.app.selectedItemChanged(this.props.item, e)}>
            <svg class="full absolute" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 222.667 222.667" style={Object.assign({},{enableBackground:'new 0 0 222.667 222.667'}, svgstyle)}>
            <path style={pathstyle} d="M110.593,53.33c-26.119,0.383-47.445,21.112-48.552,47.19c-0.471,11.112,2.674,21.746,9.096,30.753
	            c9.684,13.579,14.194,26.604,14.194,40.986v0.588c0,0.289,0.038,0.568,0.085,0.843v33.073c0,1.664,0.828,3.22,2.209,4.148
	            l16.206,10.903c0.825,0.555,1.797,0.852,2.791,0.852c0.003,0,0.006,0,0.008,0l9.581-0.018c0.998-0.002,1.972-0.302,2.797-0.862
	            l16.048-10.886c1.372-0.931,2.193-2.48,2.193-4.138v-33.068c0.048-0.277,0.085-0.557,0.085-0.848v-0.593
	            c0-14.577,4.446-27.51,13.992-40.7c6.112-8.446,9.343-18.437,9.343-28.893C160.67,75.21,138.121,52.902,110.593,53.33z
	             M117.537,177.848l-22.12,6.889v-6.889H117.537z M95.417,195.21l31.833-9.914v4.388l-31.833,9.915V195.21z M114.662,212.652
	            l-6.518,0.012l-6.662-4.482l25.768-8.025v3.957L114.662,212.652z M106.333,39.828V5c0-2.762,2.239-5,5-5s5,2.238,5,5v34.828
	            c0,2.762-2.239,5-5,5S106.333,42.59,106.333,39.828z M219.732,96.984c0,2.762-2.239,5-5,5h-30.105c-2.761,0-5-2.238-5-5s2.239-5,5-5
	            h30.105C217.493,91.984,219.732,94.223,219.732,96.984z M38.041,101.984H7.935c-2.761,0-5-2.238-5-5s2.239-5,5-5h30.105
	            c2.761,0,5,2.238,5,5S40.802,101.984,38.041,101.984z M62.946,55.101c-0.977,0.977-2.256,1.465-3.536,1.465
	            s-2.559-0.488-3.536-1.465L34.172,33.397c-1.953-1.952-1.953-5.118,0-7.07c1.953-1.953,5.118-1.953,7.071,0L62.946,48.03
	            C64.899,49.982,64.899,53.148,62.946,55.101z M166.792,55.101c-0.977,0.977-2.256,1.465-3.536,1.465s-2.559-0.488-3.536-1.465
	            c-1.953-1.952-1.953-5.118,0-7.07l21.703-21.703c1.953-1.953,5.118-1.953,7.071,0c1.953,1.952,1.953,5.118,0,7.07L166.792,55.101z
	             M194.073,162.346c1.953,1.952,1.953,5.118,0,7.07c-0.977,0.977-2.256,1.465-3.536,1.465c-1.28,0-2.559-0.488-3.536-1.465
	            l-20.778-20.778c-1.953-1.952-1.953-5.118,0-7.07c1.954-1.953,5.119-1.952,7.071,0L194.073,162.346z M56.443,141.567
	            c1.953,1.952,1.953,5.118,0,7.07l-20.778,20.778c-0.977,0.977-2.256,1.465-3.536,1.465s-2.559-0.488-3.536-1.465
	            c-1.953-1.952-1.953-5.118,0-7.07l20.778-20.778C51.325,139.614,54.49,139.614,56.443,141.567z"/>
            </svg>
</div>
        );
    }
}
export default DimLight;

