import React, { Component } from 'react';

class Lock extends Component {
    render() {
        var divstyle = this.props.divstyle
        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 497.675 497.675" style={Object.assign({},{enableBackground:'new 0 0 497.675 497.675'})}>
            <path d="M362.032,184.637H360v-73.47C360,49.875,310.129,0,248.834,0S137.663,49.875,137.663,111.167v73.47h-2.03
	c-27.612,0-50.003,22.395-50.003,50.013v213.015c0,27.617,22.391,50.01,50.003,50.01h226.399c27.621,0,50.013-22.392,50.013-50.01
	V234.65C412.045,207.032,389.653,184.637,362.032,184.637z M196.394,111.167c0-28.912,23.525-52.437,52.44-52.437
	c28.911,0,52.437,23.525,52.437,52.437v73.47H196.394V111.167z M280.696,367.401c0,17.59-14.264,31.857-31.862,31.857
	c-17.592,0-31.856-14.268-31.856-31.857v-52.479c0-17.592,14.265-31.855,31.863-31.855c17.592,0,31.856,14.264,31.856,31.855
	V367.401z"/>
            </svg>
</div>
        );
    }
}
export default Lock;

