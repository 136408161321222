
const SERVER_URL = 'http://localhost:3000'; // dev
// const SERVER_URL = 'https://my-staging-server.com'; // staging
// const SERVER URL = 'https://my-prod-server.com'; // production

const STYLES = {
    div100: { flex: '1 1 auto', width: '100%' },
    bgdark: { background: 'rgb(60, 64, 71)' },
    bgmirror: { background: 'black' },
    bglight: { background: 'white' }
};

const obj = {
    STYLES,
    SERVER_URL,
    
};
export default obj;