import React, { Component } from 'react';

class Fullscreen extends Component {
    render() {
        var divstyle = this.props.divstyle
        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 384 384" style={Object.assign({},{enableBackground:'new 0 0 384 384'})}>
                <polygon points="110.293,243.52 48.96,304.96 0,256 0,384 128,384 79.04,335.04 140.48,273.707 "/>
			    <polygon points="128,0 0,0 0,128 48.96,79.04 110.293,140.48 140.48,110.293 79.04,48.96 "/>
			    <polygon points="256,0 304.96,48.96 243.52,110.293 273.707,140.48 335.04,79.04 384,128 384,0 "/>
			    <polygon points="273.707,243.52 243.52,273.707 304.96,335.04 256,384 384,384 384,256 335.04,304.96 "/>
            </svg>
</div>
        );
    }
}
export default Fullscreen;

