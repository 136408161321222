import React, { Component } from 'react';

class Measurement extends Component {
    render() {
        var colors = this.props.globalstate.colors.light;
        if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var stylesheet = JSON.parse(JSON.stringify(this.props.design));
        stylesheet["fontWeight"] = "bold";
        stylesheet["margin"] = "0";
        stylesheet["position"] = "absolute";
        stylesheet["color"] = (Math.round(((new Date()).getTime() - (new Date(this.props.item.lastUpdate)).getTime()) / (60 * 1000)) < (24*60) ? colors.fgcolor : colors.redcolor);
        
        var fontSize = stylesheet["fontSize"];
        if (!fontSize) fontSize = 3;
        var sizefactor = 0.01;
        stylesheet["fontSize"] = this.props.globalstate.doorsandwindow.width * fontSize *sizefactor;
        /*var screenRatio = this.props.globalstate.doorsandwindow.height / this.props.globalstate.doorsandwindow.width;
        if (screenRatio < 1) screenRatio = 1 / screenRatio;
        if (this.props.landscape === true) stylesheet["fontSize"] = this.props.globalstate.doorsandwindow.width * fontSize *sizefactor;
        else stylesheet["fontSize"] = this.props.globalstate.doorsandwindow.height * fontSize *sizefactor*screenRatio;*/
        var value = this.props.item.value;
        if (value < 100) {
            value = Math.round(value * 10);
            value /= 10;
        }
        else value = Math.round(value);
        if(this.props.item.type === 130) value = value;
        else value = value + ' ' + this.props.item.unit;
        return (
            <div style={stylesheet}>{value}</div>
        );
    }
}
/*.sensor_value {
    "font-weight": "bold",
    "margin": "0"
  },
  .sizedw {
    "font-size": "3vw"
  },
  .sizedh {
    "font-size": "4.5vh"
  },*/
export default Measurement;