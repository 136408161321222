import React, { Component, ComponentProps } from 'react';
import './App.css';
import SmartHomeApp from './SmartHomeApp';
import Calendar from './Calendar';
import Tasks from './Tasks';
import Weather from './Weather';
import StockWidget from './StockWidget';

interface AppProps {}

interface AppState {
  isLoggedIn: boolean;
  userContext: any;
  forceMirrorMode: boolean;
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      isLoggedIn: false,
      userContext: undefined,
      forceMirrorMode: false
    };
    this.FinishUpdateLoginState = this.FinishUpdateLoginState.bind(this);
    this.ResetLoginState = this.ResetLoginState.bind(this);
    this.UpdateLoginState = this.UpdateLoginState.bind(this);
    this.ToggleForceMirrorMode = this.ToggleForceMirrorMode.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
  }

  login()
  {
    window.location.href = 'https://smarthome.roehder.eu/HomeAPI/signin';
  }

  logout()
  {
    fetch('https://' + window.location.hostname + '/HomeAPI/signout')
    .then(function() {window.location.href = 'https://smarthome.roehder.eu';});
  }

  handleErrors(response: any) {
    if (!response.ok) {
      //if(response.status === 401) window.location.href = 'https://smarthome.roehder.eu/HomeAPI/signin';
      if (response.status === 401) 
      {
        if(this.state.isLoggedIn) this.ResetLoginState();
        return { then: function() {} };
      }
      else throw Error(response.statusText);
    }
    return response;
  }
  UpdateLoginState() {
    fetch('https://' + window.location.hostname + '/HomeAPI/GetLoginState')
      .then(this.handleErrors)
      .then(result => result.json())
      .then(this.FinishUpdateLoginState)
      .catch(error => console.log(error));
  }
  ResetLoginState() {
    this.setState({
      isLoggedIn: false,
      userContext: undefined
    });
  }
  FinishUpdateLoginState(userContext: any) {
    if (userContext.features !== undefined) {
      var features = userContext.features.split('|');
      userContext.features = {};
      features.forEach((element: string) => {
        userContext.features[element] = true;
      });
    }
    else userContext.features = { smarthome: true }
    this.setState({
      isLoggedIn: true,
      userContext: userContext
    });
  }
  ToggleForceMirrorMode()
  {
    this.setState({
      forceMirrorMode: !this.state.forceMirrorMode
    });
  }
  render() {
    if (!this.state.isLoggedIn) {
      this.UpdateLoginState();
    }
    var smartmirror = (window.navigator.userAgent.indexOf('X11') !== -1) && (window.navigator.userAgent.indexOf('CrOS') !== -1);
    smartmirror = smartmirror || this.state.forceMirrorMode;
    var output = <SmartHomeApp smartmirror={smartmirror} togglemirrorfunc={this.ToggleForceMirrorMode} loginfunc={this.login} logoutfunc={this.logout} loggedin={this.state.isLoggedIn} userContext={this.state.userContext} resetLogin={this.ResetLoginState}/>
    var rootelem = document.getElementById('root');
    if (smartmirror && this.state.isLoggedIn && rootelem) output = (
      <div style={{width: rootelem.clientWidth-45}}>
        <SmartHomeApp smartmirror={smartmirror} togglemirrorfunc={this.ToggleForceMirrorMode} loginfunc={this.login} logoutfunc={this.logout} loggedin={this.state.isLoggedIn} userContext={this.state.userContext} resetLogin={this.ResetLoginState}/>
        <Weather />
        <Tasks {...this.props} />
        <Calendar {...this.props} />
        </div>
    );
    return output;
  }
}

export default App;
