import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import localization from 'moment/locale/de';
import { Interactive } from 'react-interactive';
import 'react-datepicker/dist/react-datepicker.css';
import StockLineChart from './StockLineChart';
import Select from 'react-select';
import de from "date-fns/locale/de";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from "react-cookie";

class StockLineChartPage extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };
    constructor(props) {
        moment().locale("de", localization);
        super(props);
        this.state = {
            stocks: undefined,
            customgraphsince: (moment().subtract(3600 * 12, 'seconds')).toDate(),
            customgraphtill: moment().toDate(),
            customgraphauto: this.props.cookies.get("customgraphauto") || false,
            stockinfo: undefined,
            selectableStocks: undefined
        };

        this.handleDateSinceChange = this.handleDateSinceChange.bind(this);
        this.handleDateTillChange = this.handleDateTillChange.bind(this);
        this.handleCheckGraphAuto = this.handleCheckGraphAuto.bind(this);
        this.updateValues = this.updateValues.bind(this);
        this.graphAutoUpdate = this.graphAutoUpdate.bind(this);
        //this.handleIDChange = this.handleIDChange(this);
    }
    async componentDidMount() {
        
    }
    async componentWillUnmount() {
        if (this.timerID !== undefined) clearInterval(this.timerID);
    }
    handleCheckGraphAuto(event) {
        if (event.target.checked)
        {
            this.timerID = setInterval(this.graphAutoUpdate, 60000); // runs every min.
            this.graphAutoUpdate();
        }
        else if (this.timerID !== undefined) clearInterval(this.timerID);
        const { cookies } = this.props;
        cookies.set("customgraphauto", event.target.checked, { path: "app/" });
        this.setState({ customgraphauto: event.target.checked });
    }
    graphAutoUpdate()
    {
        var since = moment();
        var till = moment().toDate();;
        var diff = (this.state.customgraphtill.getTime() - this.state.customgraphsince.getTime()) / 1000;
        since = since.subtract(diff, 'seconds').toDate();
        const { cookies } = this.props;
        cookies.set("till", till, { path: "app/" });
        cookies.set("since", since, { path: "app/" });
        this.setState({
            customgraphtill: till,
            customgraphsince: since
        });
    }
    handleDateSinceChange(date) {
        const { cookies } = this.props;
        cookies.set("since", date, { path: "app/" });
        this.setState({
            customgraphsince: date
        });
    }
    handleDateTillChange(date) {
        const { cookies } = this.props;
        cookies.set("till", date, { path: "app/" });
        this.setState({
            customgraphtill: date
        });
    }
    selectDepot(depot) {
        var copy = this.state.selectableStocks;
        var selectedWKN = [];
        Object.entries(this.state.selectableStocks).forEach(([key, value]) => {
            if(copy[key].label.startsWith(depot.toString())) 
            {
                selectedWKN.push(key);
                copy[key].selected = true;
            }
            else copy[key].selected = false;
        });
        const { cookies } = this.props;
        cookies.set("wkn", selectedWKN, { path: "app/" });
        this.setState({ selectableStocks: copy });
    }
    componentDidMount() {
        this.updateValues();
        this.updateStockInfo();
    }
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    async updateStockInfo() {
        try {
          const response = await fetch('https://' + window.location.hostname + '/HomeAPI/GetStockObjects', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              credentials: 'include'
            },
            credentials: 'include'
          });
          var stockInfoObjects = {};
          var stockInfo = await response.json();
          stockInfo.forEach((element) => {
            stockInfoObjects[element.wkn] = element;
          })
          // Update the array of events in state
          this.setState({
            stockinfo: stockInfoObjects,
          });
        }
        catch (err) {
          console.log(err);
        }
    
      }
    updateValues() {
        fetch('https://' + window.location.hostname + '/HomeAPI/GetStockPortfolio', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(result => result.json())
            .then(stocks => {
                this.setState({ stocks: stocks });
                return stocks;
            })
            .catch(error => console.log(error));
    }
 
    handleStockChange = name => value => {
        var copy = this.state.selectableStocks;
        Object.entries(this.state.selectableStocks).forEach(([key, value]) => {
            copy[key].selected = false;
        });
        var selectedWKN = [];
        value.forEach(element => {
            selectedWKN.push(element.value);
            copy[element.value].selected = true;
        });
        const { cookies } = this.props;
        cookies.set("wkn", selectedWKN, { path: "app/" });
        this.setState({ selectableStocks: copy });
    };
    render() {
        var output = "";
        var colors = this.props.globalstate.colors.light;
        if (this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var divstyle = {};
        if (this.props.globalstate.dark) divstyle = Object.assign({}, this.props.globalstate.config.STYLES.div100, this.props.globalstate.config.STYLES.bgdark);
        else divstyle = Object.assign({}, this.props.globalstate.config.STYLES.div100, this.props.globalstate.config.STYLES.bglight);
        var height = 36;
        const customStyles = {
            container: (provided) => ({
                ...provided,
                flex: 'none',
                //display: 'inline-block',
                width: '100%',
                minHeight: '1px',
                textAlign: 'left',
                border: '1px solid ' + colors.midcolor,
                margin: '5px 0px 5px 0px',
                background: colors.bgcolor
            }),
            control: (provided) => ({
                ...provided,
                border: '0',
                borderRadius: '0',
                minHeight: '1px',
                background: colors.bgcolor
            }),
            menu: (provided) => ({
                ...provided,
                margin: '0',
                border: '1px solid ' + colors.midcolor,
                borderRadius: '0',
                background: colors.bgcolor
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                minHeight: '1px',
                height: '30px',
                width: '30px',
                paddingTop: '0',
                paddingBottom: '0',
                color: colors.midcolor
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                minHeight: '1px',
                //height: '24px',
                background: colors.midcolor
            }),
            singleValue: (provided) => ({
                ...provided,
                color: colors.midcolor
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = data.color;
                return {
                    ...styles,
                    backgroundColor: 'white',
                    color: isDisabled
                        ? '#ccc'
                        : isSelected
                            ? 'black'
                            : data.color,
                    cursor: isDisabled ? 'not-allowed' : 'default',

                    ':active': {
                        ...styles[':active'],
                        backgroundColor: !isDisabled && (isSelected ? data.color : color),
                    },
                };
            },
            multiValue: (styles, { data }) => {
                return {
                    ...styles,
                    backgroundColor: colors.bgcolor,
                };
            },
            multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: data.color,
            }),
            multiValueRemove: (styles, { data }) => ({
                ...styles,
                minHeight: '1px',
                height: '20px',
                width: '20px',
                color: data.color,
                ':hover': {
                    backgroundColor: data.color,
                    color: 'white',
                },
            }),
            clearIndicator: (styles, { data }) => ({
                ...styles,
                minHeight: '1px',
                height: '30px',
                width: '30px'
            }),
        };
        if(this.state.stocks !== undefined && this.state.stockinfo !== undefined && this.state.selectableStocks === undefined)
        {
            var stockwkns = {};
            this.state.stocks.forEach(element => {
                    if(this.state.stockinfo.hasOwnProperty(element))
                    {
                        stockwkns[element]= {value: element, label:this.state.stockinfo[element].depot +" - " +this.DecodeHTML(this.state.stockinfo[element].name), selected: false, color: 'black'};
                    }
                });
            var selected = this.props.cookies.get("wkn");
            if(selected !== undefined)
            {
                selected.forEach(wkn => {stockwkns[wkn].selected = true});
            }
            this.setState({ selectableStocks: stockwkns });
            if(this.state.customgraphauto && this.timerID === undefined)
            {
                this.timerID = setInterval(this.graphAutoUpdate, 60000); // runs every min.
                this.graphAutoUpdate();
            }
        }
        
        if (this.state.selectableStocks !== undefined && Object.entries(this.state.selectableStocks).length > 0) {
            /*const DatePickerInput = ({ value, onClick }) => (
                <button onClick={onClick} style={{ background: colors.bgcolor, color: colors.midcolor, border: '1px', borderStyle: 'solid'}}>
                    {value}
                </button>
            );*/
            const DatePickerInput = React.forwardRef((props, ref) => {
                return (
                    <button onClick={props.onClick} style={{ background: colors.bgcolor, color: colors.midcolor, border: '1px', borderStyle: 'solid' }}>
                        {props.value}
                    </button>
                )
            });
            
            var selectableOptions = [];
            var selectedOptions =[];
            var selectedWKN ={};
            Object.entries(this.state.selectableStocks).forEach(([key, value]) => {
                selectableOptions.push(value);
                if(value.selected) selectedOptions.push(value);
                if(value.selected) selectedWKN[key]=value;
            });
            selectableOptions = selectableOptions.sort((a, b) => a.label.localeCompare(b.label, 'de'));
            output = (
                <div style={divstyle}>
                    <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }}>
                        <DatePicker
                            selected={this.state.customgraphsince}
                            onChange={this.handleDateSinceChange}
                            //showTimeSelect
                            showTimeInput
                            timeIntervals={30}
                            dateFormat="dd.MM.yy HH:mm"
                            timeCaption="Zeit"
                            timeInputLabel="Zeit"
                            locale={de}
                            customInput={<DatePickerInput />}
                            maxDate={this.state.customgraphtill}
                            selectsStart
                            startDate={this.state.customgraphsince}
                            endDate={this.state.customgraphtill}
                        />
                        <Interactive as="div" onClick={(e) => this.selectDepot(1)}>
                            <div> 1 </div>
                        </Interactive>
                        <Interactive as="div" onClick={(e) => this.selectDepot(2)}>
                            <div> 2 </div>
                        </Interactive>
                        <Interactive as="div" onClick={(e) => this.selectDepot(3)}>
                            <div> 3 </div>
                        </Interactive>
                        <Interactive as="div" onClick={(e) => this.selectDepot(4)}>
                            <div> 4 </div>
                        </Interactive>
                        <DatePicker
                            selected={this.state.customgraphtill}
                            onChange={this.handleDateTillChange}
                            //showTimeSelect
                            showTimeInput
                            timeIntervals={30}
                            dateFormat="dd.MM.yy HH:mm"
                            timeCaption="Zeit"
                            timeInputLabel="Zeit"
                            locale={de}
                            minDate={this.state.customgraphsince}
                            customInput={<DatePickerInput />}
                            selectsEnd
                            startDate={this.state.customgraphsince}
                            endDate={this.state.customgraphtill}
                        />                        
                        <Interactive as="div" onClick={(e) => this.handleDateTillChange(moment().toDate())}>
                            <div> Now</div>
                        </Interactive>
                        <input type="checkbox" onChange={this.handleCheckGraphAuto} defaultChecked={this.state.customgraphauto} />

                    </div>
                    <Select styles={customStyles} defaultValue={selectedOptions} isMulti onChange={this.handleStockChange('single')} options={selectableOptions} />
                    <StockLineChart width={this.props.width} height={this.props.height - 36 - 21} globalstate={this.props.globalstate} selectedWKN={selectedWKN} since={this.state.customgraphsince} till={this.state.customgraphtill} graphauto={this.state.customgraphauto} key={this.props.width * this.props.height}/>
                    
                </div>);
        }
        return output;
    }
    DecodeHTML(str) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = str;
              return textArea.value.replace(/&#(\d+);/g, function(match, dec) {
                  return String.fromCharCode(dec);
              });
          }
}//<input style={{ flex: 1, margin: '1px', flexBasis: '30px' }} type="text" value={this.state.customgraphsensorid} onChange={this.handleSensorIDChange} />
//
export default withCookies(StockLineChartPage);