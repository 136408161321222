import React from 'react';
import { Table } from 'reactstrap';
import { PlannerTask, PlannerTaskDetails, PlannerChecklistItem } from 'microsoft-graph';
import TaskRow from './TaskRow';
import moment from 'moment-timezone';
import './Calendar.css';

class Taskitem {
  task: PlannerTask
  details: PlannerTaskDetails

  constructor(task: PlannerTask, details: PlannerTaskDetails){
      this.task = task;
      this.details = details;
  }
}

interface TasksProps {
  }

interface TasksState {
  tasksLoaded: boolean;
  tasks: Taskitem[];
  lastUpdate: moment.Moment;
}

class Tasks extends React.Component<TasksProps, TasksState> {
  timerID: any;
  constructor(props: any) {
    super(props);

    this.state = {
      tasksLoaded: false,
      tasks: [],
      lastUpdate: moment()
    };
    this.processTasks = this.processTasks.bind(this);
    this.updateTasks = this.updateTasks.bind(this);
    this.timerID = undefined;
  }
  async componentDidMount() {
    this.updateTasks()
    this.timerID = setInterval(this.updateTasks, 150000); // runs every 2.5 min.
}
  async componentWillUnmount() {
    if (this.timerID !== undefined) clearInterval(this.timerID);
  }
  handleErrors(response: any) {
    if (!response.ok) {
        if(response.status === 401) window.location.href = 'https://smarthome.roehder.eu/HomeAPI/signin';
        throw Error(response.statusText);
    }
    return response;
}

async processTasks(tasks:Taskitem[])
{
  tasks = tasks.sort(function(a, b) { if(a.task.title && b.task.title) return a.task.title.localeCompare(b.task.title); else return 0;});
  // Update the array of events in state
  this.setState({
    tasksLoaded: true,
    tasks: tasks,
    lastUpdate: moment()
  });
}

async updateTasks() 
  {
     fetch('https://' + window.location.hostname + '/HomeAPI/GetTasks', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      credentials: 'include'})
        .then(this.handleErrors)
        .then(result => result.json())
        .then(this.processTasks)
        .catch(error => console.log(error));
  }

  render() {
    var taskToShow = this.state.tasks.filter(task => task.task.percentComplete !== 100);
    return (
      <div className="tasks" style={{color:'#FFF'}}>
        <div className="calendar-week">
                <div className="table-responsive">
                    <Table size="sm">
                        <tbody>
                        <tr >

                            <React.Fragment>
                            
                                {taskToShow.map(function (task: Taskitem, index: Number) {
                                  return (<td valign="top"><TaskRow task={task.task} details={task.details} /></td>)
                                }
                                )}
                            </React.Fragment>
                          </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
      </div>
    );
  }
}

export default Tasks;