import React, { Component } from 'react';
import State from './State';


class Background extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: undefined
        };
        this.id = 0;
    }

    render() {
        if(this.state.image !== undefined)
        {
        var colors = this.props.globalstate.colors.light;
        if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var divStyle = {
            left: 0 ,
            top: 0 ,
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "none"
        };
        var stylesheets = document.styleSheets;
        for(var i = 0; i < stylesheets.length; i++)
        {
            for(var j = 0; j < stylesheets[i].rules.length; j++)
            {
                if(stylesheets[i].rules[j].selectorText === '.pic line') document.styleSheets[i].rules[j].style.setProperty('stroke', colors.midcolor);
                if(stylesheets[i].rules[j].selectorText === '.pic rect') document.styleSheets[i].rules[j].style.setProperty('stroke', colors.midcolor);
                if(stylesheets[i].rules[j].selectorText === '.pic line, .pic rect') document.styleSheets[i].rules[j].style.setProperty('stroke', colors.midcolor);
            }
        }
        return (
            <div style={divStyle}>
                <div className="pic" dangerouslySetInnerHTML={{__html: this.state.image}}  style={{width:"100%", height:"100%", "z-index":"-99999", border:"0"}}  />
                {this.props.layoutitems.map((item,i ) => <State key={i} item={this.props.globalstate.smarthomestate[item.id]} design={item.layout} landscape={this.props.landscape} globalstate={this.props.globalstate} />)}
            </div>
        );}
        else return "";
    }
    componentDidMount() {
            this.loadBackground(this.props.image);
    }
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    
    loadBackground(file)
    {
        fetch('https://' + window.location.hostname + '/' +file)
            .then(this.handleErrors)
            .then(result => result.text())
            .then(image => this.setState({ image }))
            .catch(error => console.log(error));
    }
}

export default Background;