import React, { Component } from 'react';

class Waterpump extends Component {
    render() {
        var colors = this.props.globalstate.colors.light;
        if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        //var stylesheet = JSON.parse(JSON.stringify(this.props.design));
        var divstyle = Object.assign({},{position:'absolute'},this.props.design)
        var svgstyle = {}
        var pathstyle = {stroke: colors.fgcolor, fill:colors.fgcolor }
        if(this.props.item.value === 1) pathstyle = {stroke: colors.redcolor, fill:colors.redcolor }
        return (
    <div style={divstyle} onClick={(e) => this.props.globalstate.app.selectedItemChanged(this.props.item, e)}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 106.048 106.048" style={Object.assign({},{enableBackground:'new 0 0 106.048 106.048'}, svgstyle)}>
            <path style={pathstyle} d="M71.282,83.616c0-2.227,6.685-18.559,8.16-20.785
		c1.491,2.967,8.171,18.559,8.171,20.785C87.613,95.852,71.282,95.852,71.282,83.616z M88.162,59.822H71.209
		c-1.501,0-2.733-1.227-2.733-2.739c0-0.932,0.491-1.719,1.212-2.206c-0.265-3.008-1.166-4.58-3.604-4.58c-8.171,0-8.171,0-8.171,0
		c-2.227,4.466-5.939,6.677-10.393,6.677c-4.458,0-7.423-2.211-9.649-6.677c-18.918,0-22.227,0-22.711,0V33.955
		c13.797,0,18.703,0,20.42,0v-5.237c0-1.507,1.225-2.737,2.736-2.737h6.967V22.62c-0.578-0.323-1.062-0.753-1.463-1.284
		c-8.298,0-12.917,0-15.446,0c-0.917,1.199-2.307,2.038-3.936,2.038c-2.793,0-5.059-2.261-5.059-5.054
		c0-2.796,2.266-5.064,5.059-5.064c1.665,0,3.081,0.885,3.998,2.141c5.934,0,11.024,0,15.319,0c0.917-1.256,2.338-2.141,4.008-2.141
		c1.678,0,3.087,0.885,4.021,2.141c7.527,0,11.659,0,13.87,0c0.917-1.256,2.335-2.141,4.013-2.141c2.781,0,5.049,2.263,5.049,5.064
		c0,2.793-2.268,5.054-5.049,5.054c-1.641,0-3.023-0.834-3.956-2.038c-8.031,0-12.023,0-13.996,0
		c-0.396,0.521-0.87,0.94-1.442,1.279v3.366h6.574c1.507,0,2.733,1.23,2.733,2.737l0.099,5.245c16.762,0,16.803,0,16.803,0
		c7.188,0,12.625,5.163,12.625,11.138c0,4.938,0,7.742,0,9.434c1.046,0.384,1.802,1.367,1.802,2.548
		C90.907,58.596,89.675,59.822,88.162,59.822z"/>
            </svg>
</div>
        );
    }
}
export default Waterpump;

