import React, { Component } from 'react';
//import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import moment from 'moment';
import Select from 'react-select';

class StockLineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineChartData: undefined,
            linesSelected: []
        };        
    }

    componentDidMount() {
        /*if (this.props.graphauto) 
            setInterval(this.graphAutoUpdate, 60000);
        
        else
        {
            if (this.timerID !== undefined) clearInterval(this.timerID);
        }*/
        this.updateValues(this.props.selectedWKN, this.props.since, this.props.till);
    }
    componentWillUnmount() {
        //if (this.timerID !== undefined) clearInterval(this.timerID);
    }
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    updateValues(wkn, since, till) {
        if(Object.entries(wkn).length === 0) return;
        var wkns = [];
        Object.entries(wkn).forEach(([key, value]) => {
            wkns.push(value.value);
        });
        var url = 'https://' + window.location.hostname + '/HomeAPI/GetStockData';
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                credentials: 'include'
            },
            body: JSON.stringify({
                wkn: wkns,
                since: Math.round(since.getTime() / 1000),
                till: Math.round(till.getTime() / 1000)
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(result => result.json())
            .then(lineChartData => {
                if (this.state.linesSelected.length !== lineChartData.length) {
                    var lines = [];
                    for (var i = 0; i < lineChartData.length; i++)
                    {
                        lines.push({'selected': true, 'label': this.props.selectedWKN[lineChartData[i].wkn].label, 'value': i });
                    }
                    this.setState({ linesSelected: lines });
                }
                return lineChartData;
            })
            .then(lineChartData => this.setState({ lineChartData }))
            .catch(error => console.log(error));
    }
    componentDidUpdate(prevProps, prevState) {
        if (Object.entries(this.props.selectedWKN).length !== Object.entries(prevProps.selectedWKN).length || this.props.since !== prevProps.since || this.props.till !== prevProps.till) {
            this.setState({ lineChartData: undefined });
            this.updateValues(this.props.selectedWKN, this.props.since, this.props.till);
        }
        /*if (this.props.graphauto !== prevProps.graphauto)
        {
            if (this.props.graphauto) {
                setInterval(this.graphAutoUpdate, 60000);
            }
            else {
                if (this.timerID !== undefined) clearInterval(this.timerID);
            }
        }*/
    }

    /*graphAutoUpdate()
    {
        var till = moment();
        var diff = (this.props.till.getTime() - this.props.since.getTime()) / 1000;
        var since = till.subtract(diff).toDate();
        till = till.toDate();
        this.setState({
            customgraphtill: till,
            customgraphsince: since
        });
        this.updateValues(this.props.selectedWKN, since, till);
    }*/
    handleIDChange = name => value => {
        var copy = this.state.linesSelected;
        for (var i = 0; i < copy.length; i++) { copy[i].selected = false }
        for (i = 0; i < value.length; i++) { copy[value[i].value].selected = true }
        this.setState({ linesSelected: copy });
    };
    
    render() {
      if (this.state.lineChartData === undefined) return "";
        var colors = this.props.globalstate.colors.light;
        if (this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;

        var dataset = [];
        const customStyles = {
            container: (provided) => ({
                ...provided,
                flex: 'none',
                //display: 'inline-block',
                width: '100%',
                minHeight: '1px',
                textAlign: 'left',
                border: '1px solid ' + colors.midcolor,
                margin: '5px 0px 5px 0px',
                background: colors.bgcolor
            }),
            control: (provided) => ({
                ...provided,
                border: '0',
                borderRadius: '0',
                minHeight: '1px',
                background: colors.bgcolor
            }),
            menu: (provided) => ({
                ...provided,
                margin: '0',
                border: '1px solid ' + colors.midcolor,
                borderRadius: '0',
                background: colors.bgcolor
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                minHeight: '1px',
                height: '30px',
                width: '30px',
                paddingTop: '0',
                paddingBottom: '0',
                color: colors.midcolor
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                minHeight: '1px',
                //height: '24px',
                background: colors.midcolor
            }),
            singleValue: (provided) => ({
                ...provided,
                color: colors.midcolor
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = data.color;
                return {
                    ...styles,
                    backgroundColor: 'white',
                    color: isDisabled
                        ? '#ccc'
                        : isSelected
                            ? 'black'
                            : data.color,
                    cursor: isDisabled ? 'not-allowed' : 'default',

                    ':active': {
                        ...styles[':active'],
                        backgroundColor: !isDisabled && (isSelected ? data.color : color),
                    },
                };
            },
            multiValue: (styles, { data }) => {
                return {
                    ...styles,
                    backgroundColor: colors.bgcolor,
                };
            },
            multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: data.color,
            }),
            multiValueRemove: (styles, { data }) => ({
                ...styles,
                minHeight: '1px',
                height: '20px',
                width: '20px',
                color: data.color,
                ':hover': {
                    backgroundColor: data.color,
                    color: 'white',
                },
            }),
            clearIndicator: (styles, { data }) => ({
                ...styles,
                minHeight: '1px',
                height: '30px',
                width: '30px'
            }),
        };
        var allopt = this.state.linesSelected;
        var selopt = [];
        var color = ['red', 'DodgerBlue', colors.fgcolor, 'lightsteelblue', 'fuchsia', 'green', 'cyan', 'orange'];
            
        for (var i = 0; i < this.state.lineChartData.length; i++) {
            if (i >= color.length) color.push('grey');
            if (this.state.linesSelected[i].selected) {
                var elem = this.state.lineChartData[i];
                elem.borderColor = color[i];
                dataset.push(elem);
                selopt.push(this.state.linesSelected[i]);
            }
            allopt[i].color = color[i];
        }
        var data = {
            datasets: dataset
        };
        
        var options = {
            responsive: false,
            hoverMode: 'index',
            stacked: false,
            title: {
                display: false,
                text: 'SmartHome Daten',
                fontColor: colors.fgcolor
            },
            plugins:{legend: {
                display: false
            }},
            scales: {
                x: {
                    type: "time",
                    axis: "x",
                    id: "x",
                    time: {
                        displayFormats: {
                            /*second: 'L H:mm:ss',
                            minute: 'L H:mm',
                            hour: 'L, H:mm',
                            day: 'L',
                            week: 'L'*/
                            second: 'H:mm:ss',
                            minute: 'H:mm',
                            hour: 'H:mm',
                        },
                        // format: 'x',
                        // round: 'day'
                        tooltipFormat: 'll HH:mm'
                    },
                    /*scaleLabel: {
                        display: false,
                        labelString: 'Zeit',
                        fontColor: colors.fgcolor
                    },*/
                    ticks: {
                        font:{size: 10},
                        maxRotation: 0,
                        color: colors.fgcolor
                    },
                    grid: {
                        display: true,
                        color: colors.midcolor,
                        borderColor: colors.midcolor,
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    position: "right",
                    id: "y",
                    axis: "y",
                    ticks: {
                        color: colors.fgcolor, // this here
                    },
                    grid: {
                        display: true,
                        color: colors.fgcolor,
                        borderColor: colors.fgcolor,
                        drawOnChartArea: false,
                        drawTicks: true
                    }
                },
            },
            pan: {
                enabled: true,
                mode: 'x'
            },
            zoom: {
                enabled: true,
                mode: 'x'
            }
        };
        for (i = 0; i < data.datasets.length; i++) {
            data.datasets[i].borderWidth = 0.5;
            data.datasets[i].backgroundColor = 'rgba(0,0,0,0)';
            data.datasets[i].pointBorderColor = 'rgba(0,0,0,0)';
            data.datasets[i].pointBackgroundColor = 'rgba(20,20,20,0)';
            data.datasets[i].pointBorderWidth = 0.5;
            data.datasets[i].lineTension = 0;
            data.datasets[i].yAxisID = "y";
            data.datasets[i].xAxisID = "x";
        }
        return (
            <div>
                <Select styles={customStyles} defaultValue={selopt} isMulti onChange={this.handleIDChange('single')} options={this.state.linesSelected} />
                <Chart type='line' width={this.props.width} height={this.props.height - 78} data={data} options={options} redraw/>
            </div>
            );
    }
}

export default StockLineChart;