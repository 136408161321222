import React, { Component } from 'react';

class ArrowEnd extends Component {
    render() {
        var divstyle = this.props.divstyle

        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 51.426 51.426" style={Object.assign({},{enableBackground: 'new 0 0 51.426 51.426'})}>
            <polygon points="38.713,25.713 9.713,45.713 9.713,26.077 9.713,5.713 "/>
            <line style={{strokeWidth:6}} x1="41.713" y1="5.713" x2="41.713" y2="45.713"/>
            </svg>

</div>
        );
    }
}
export default ArrowEnd;

