import React, { Component } from 'react';
import { Interactive } from 'react-interactive';
import DoubleArrow from './DoubleArrow';
import ArrowEnd from './ArrowEnd';

class ShadeControl extends Component {

    render() {
        var output = "";
        if(this.props.globalstate !== undefined && this.props.desc !== undefined)
        {
            var colors = this.props.globalstate.colors.light;
            if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
            var fgcolor = colors.fgcolor;
            var midcolor = colors.midcolor;
            if(this.props.globalstate.selectedItem !== undefined && this.props.globalstate.selectedItem.id === this.props.id)
            {
                fgcolor = colors.redcolor;
                midcolor = colors.redcolor;
            }
            var hoverstyle = {fill: fgcolor, stroke: fgcolor, color: fgcolor, background: fgcolor+'33'}
            var buttonstyle = {fill: midcolor, stroke: midcolor, flex: 1, flexBasis: '30px', border: '1px', margin:'0px 2px', borderStyle: 'solid', height: '32px',color: midcolor, background: 'none',  zIndex:100, position:'relative' }
            var buttontextstyle = {position: 'relative', float: 'left', top: '50%', transform: 'translate(0%, -50%)', color: fgcolor }
            var iconstyle = {position:'absolute',top:'15%',height:'70%', fill: fgcolor, stroke: fgcolor, width:'100%'}
            output = <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }}>
                        <div style={{flex: 1, margin: '1px', flexBasis: '150px'}}>
                            <div style={Object.assign({},buttontextstyle)}>{this.props.desc}</div>
                        </div>
                        <div style={{flex: 1, margin: '1px', flexBasis: '30px'}}>
                            <div style={Object.assign({},buttontextstyle, {left: '50%', transform: 'translate(-50%, -50%)'})}>{this.props.value}{this.props.unit}</div>
                        </div>
                        <Interactive as="div" hover={hoverstyle} style={Object.assign({},buttonstyle,{width:'3%'})} onClick={(e) => this.sendCommand(this.props.id, 0, e)}>
                            <ArrowEnd divstyle={ Object.assign({},iconstyle,{ MozTransform: "rotate(-90deg)", msTransform: "rotate(-90deg)", OTransform: "rotate(-90deg)", WebkitTransform: "rotate(-90deg)", transform: 'rotate(-90deg)'})}/>
                        </Interactive>
                        <Interactive as="div" hover={hoverstyle} style={Object.assign({}, buttonstyle, { width: '3%' })} onClick={(e) => this.sendCommand(this.props.id, -20, e)}>
                            <DoubleArrow divstyle={Object.assign({}, iconstyle, { MozTransform: "rotate(90deg)", msTransform: "rotate(90deg)", OTransform: "rotate(90deg)", WebkitTransform: "rotate(90deg)", transform: 'rotate(90deg)' })} />
                        </Interactive>
                        <Interactive as="div" hover={hoverstyle} style={Object.assign({}, buttonstyle, { width: '3%' })} onClick={(e) => this.sendCommand(this.props.id, 20, e)}>
                            <DoubleArrow divstyle={ Object.assign({},iconstyle,{ MozTransform: "rotate(-90deg)", msTransform: "rotate(-90deg)", OTransform: "rotate(-90deg)", WebkitTransform: "rotate(-90deg)", transform: 'rotate(-90deg)'})}/>
                        </Interactive>
                        <Interactive as="div" hover={hoverstyle} style={Object.assign({},buttonstyle,{width:'3%'})} onClick={(e) => this.sendCommand(this.props.id, 100, e)}>
                            <ArrowEnd divstyle={ Object.assign({},iconstyle,{MozTransform: "rotate(90deg)", msTransform: "rotate(90deg)", OTransform: "rotate(90deg)", WebkitTransform: "rotate(90deg)", transform: 'rotate(90deg)'})}/>
                        </Interactive>
                    </div>
        }
        return output;
    }

    sendCommand(id, value, e)
    {
        //console.log("set " + this.props.location + id + value);
        fetch('https://' + window.location.hostname + '/HomeAPI/ChangeState', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: this.props.location,
                state: id,
                value: value
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .catch(error => console.log(error));
    }
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
    }
}

export default ShadeControl;