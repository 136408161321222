import React, { Component } from 'react';

class Graph extends Component {
    render() {
        var divstyle = this.props.divstyle
        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 492.163 492.163" style={Object.assign({},{enableBackground:'new 0 0 492.163 492.163'})}>
            <path d="M486.544,423.488l-54.14-57.536c-4.402-4.675-10.343-7.589-16.714-8.221l-106.239-10.928l-80.778-79.874
		c-6.051-5.964-14.502-8.718-23.085-7.316c0,0-89.908,14.536-90.272,14.064L34.227,169.682c-4.931-6.379-13.799-8.934-21.29-6.347
		C5.202,165.975,0,173.253,0,181.44v247.619c0,16.119,13.13,29.24,29.233,29.24h442.281c8.242,0,15.685-4.891,18.968-12.464
		C493.73,438.264,492.193,429.491,486.544,423.488z M62.819,433.709V246.325l24.593,31.538v155.847H62.819z M142.575,433.709
		V295.088l24.592-4.115v142.736H142.575z M222.331,433.709V295.241l24.592,24.309v114.16H222.331z M302.092,433.709v-63.572
		c2.017,0.713,4,1.489,6.143,1.697l18.444,1.906v59.969H302.092z M381.847,433.709v-54.27l24.588,2.543v51.727H381.847z"/>
	<path d="M71.755,112.635h119.68c3.331,0,6.58-0.616,9.702-1.843l64.822-25.429l55.823,54.654c5.12,5.05,11.845,7.563,18.57,7.563
		c6.816,0,13.639-2.577,18.793-7.74l54.558-54.543l10.885,10.895c2.914,2.907,7.288,3.777,11.079,2.202
		c3.811-1.576,6.274-5.283,6.274-9.397V44.029c0-5.612-4.543-10.165-10.148-10.165h-44.985c-4.112,0-7.811,2.479-9.397,6.274
		c-1.565,3.801-0.689,8.172,2.211,11.086l10.9,10.896l-50.203,50.179l-54.301-53.173c-7.428-7.3-18.541-9.566-28.272-5.756
		l-67.493,26.479H71.755c-9.047,0-16.393,7.341-16.393,16.394S62.708,112.635,71.755,112.635z"/>
            </svg>
</div>
        );
    }
}
export default Graph;

