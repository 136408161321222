import React, { Component } from 'react';
import { Interactive } from 'react-interactive';
import SmarthomeAutomationConfigItem from './SmarthomeAutomationConfigItem';

class SmarthomeConfigurator extends Component {
constructor(props) {
    super(props);
    this.state = {
        States: null,
        Services: null,
        WifiUsers: null,
        Configuration: null,
        Graphviz: null,
        Application: ''
    };
        this.sendCommand = this.sendCommand.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.updateConfig = this.updateConfig.bind(this);
        this.updateConfigItem = this.updateConfigItem.bind(this);
        this.addConfigItem = this.addConfigItem.bind(this);
        this.deleteConfigItem = this.deleteConfigItem.bind(this);
        this.getStates = this.getStates.bind(this);
        this.getServiceStates = this.getServiceStates.bind(this);
        this.getWifiUsers = this.getWifiUsers.bind(this);
    }
    render() {
        
        var output = "";
        var colors = this.props.globalstate.colors.light;
        if (this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var hoverstyle = { fill: colors.fgcolor, stroke: colors.fgcolor, color: colors.fgcolor, background: colors.fgcolor + '33', boxShadow: '0 8px 16px 0 ' + colors.fgcolor + '33 , 0 6px 20px 0 ' + colors.fgcolor + '30', zIndex: 100 }
        var buttonstyle = { fill: colors.midcolor, stroke: colors.midcolor, flex: 1, margin: '5px 10px 10px 10px', border: '1px', borderStyle: 'solid', height: '32px', color: colors.midcolor, background: 'none', zIndex: 100, position: 'relative' }
        var savebutton = '';
        var configitems = "";
        var stateitems = '';
        var serviceitems = '';
        var wifiusers = '';
        if (this.state.Configuration !== null && this.state.Configuration.RuleAutomationConfiguration != null && this.state.States !== null)
        {
            savebutton = <Interactive as="div" hover={hoverstyle} onClick={(e) => this.updateConfig(this.state.Application, this.state.Configuration)} style={Object.assign({}, buttonstyle)} >
                <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Save</div>
            </Interactive>;
            //console.log(this.state.Configuration);
            configitems = <div style={{ width: '100%',  background: colors.bgcolor }} >
                {this.state.Configuration.RuleAutomationConfiguration.Rules.AutomationConfigItem.map((item, i) => <SmarthomeAutomationConfigItem key={i} index={i} item={item} states={this.state.States} globalstate={this.props.globalstate} onDelete= { this.deleteConfigItem } onChange = { this.updateConfigItem } />)}
            </div>                
        }
        if (this.state.States !== null) {
            //console.log(Object.keys(this.state.States));
            //console.log((new Date(this.state.States['2'].lastUpdate)).toString());((a < b) ? 'minor' : 'major')
            
            stateitems = Object.keys(this.state.States).map((item, i) => <div style={{ color: (Math.round(((new Date()).getTime() - (new Date(this.state.States[item].lastUpdate)).getTime()) / (60 * 1000)) < 30 ? colors.fgcolor : colors.redcolor) }} key={i}>{item} => {new Date(this.state.States[item].lastUpdate).toLocaleString()}: {this.state.States[item].desc} => {this.state.States[item].value}</div>)
            
        }
        if (this.state.Services !== null) {
            //console.log(Object.keys(this.state.States));
            //console.log((new Date(this.state.States['2'].lastUpdate)).toString());((a < b) ? 'minor' : 'major')
            
                serviceitems = Object.keys(this.state.Services).map((item, i) => <div style={{ color: (Math.round(((new Date()).getTime() - (new Date(this.state.Services[item].lastUpdate)).getTime()) / (60 * 1000)) < 30 ? colors.fgcolor : colors.redcolor) }} key={i}>{this.state.Services[item].service}</div>)
            
        }
        if (this.state.WifiUsers !== null) {
            //console.log(Object.keys(this.state.States));
            
                wifiusers = Object.keys(this.state.WifiUsers).map((item, i) => <div key={i}>{this.state.WifiUsers[item].mac} --!!-- {this.state.WifiUsers[item].snr} --!!-- {new Date(this.state.WifiUsers[item].lastSeen).toLocaleString()} --!!-- {this.state.WifiUsers[item].ip}--!!-- {this.state.WifiUsers[item].apName} --!!-- {this.state.WifiUsers[item].ssid} --!!-- {this.state.WifiUsers[item].name}</div>)
            
        }
        output =
            <div style={{ top: '0px', left: '0px', width: '100%',  background: colors.bgcolor, position: 'absolute', border: '0px', borderStyle: 'none', color: colors.fgcolor, zIndex: 300 }} >
            {configitems}
            <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }} >
                <Interactive as="div" hover={hoverstyle} onClick={(e) => this.addConfigItem()} style={Object.assign({}, buttonstyle)} >
                <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Add item</div>
                </Interactive>
                <Interactive as="div" hover={hoverstyle} onClick={(e) => this.props.globalstate.app.showSmartHomeConfigurator(null)} style={Object.assign({}, buttonstyle)} >
                    <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Cancel</div>
                </Interactive>
                {savebutton}
            </div>
            {stateitems}
            {serviceitems}
            {wifiusers}
            </div>

        return output;
        }
    componentDidMount() {
        this.getConfig(this.state.Application);
        this.getStates(this.props.globalstate.location);
        this.getServiceStates(this.props.globalstate.location);
        this.getWifiUsers(this.props.globalstate.location);
    }
    /*componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.props.show) {
            this.setState({ ShowAdminMenu: nextProps.show });
        }
    }*/

    updateConfigItem(index, item)
    {
        var config = this.state.Configuration;
        config.RuleAutomationConfiguration.Rules.AutomationConfigItem[index] = item;
        this.setState({ Configuration: config });
    }
    addConfigItem()
    {
        var newitem = {
            Action:
            { $: { 'xsi:type': "MQTTActionConfigItem" }, Message: "Message", QoS: "2", Topic: "topic", Type: "MQTT" },
            Activated: "false", Name: "Name", Rule: "((State_40=0)&(State_13<0)&(State_30>55)&(State_12<70))", Type: "OnChange"
        };
        var config = this.state.Configuration;
        config.RuleAutomationConfiguration.Rules.AutomationConfigItem.push(newitem);
        this.setState({ Configuration: config });
    }
    deleteConfigItem(index)
    {
        var config = this.state.Configuration;
        config.RuleAutomationConfiguration.Rules.AutomationConfigItem.splice(index, 1);
        this.setState({ Configuration: config });
    }
    sendCommand(topic, value, e) {
        this.props.close(false);
        fetch('https://' + window.location.hostname + '/HomeAPI/AdminPublish', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: this.props.globalstate.location,
                topic: topic,
                value: value
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .catch(error => console.log(error));
    }
    getConfig(service) {
        fetch('https://' + window.location.hostname + '/HomeAPI/SmartHomeConfig', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: this.props.globalstate.location,
                service: service
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(result => result.json())
            .then(config => this.setState({Configuration: config}))
            .catch(error => console.log(error));
    }
    updateConfig(service, config) {
        fetch('https://' + window.location.hostname + '/HomeAPI/SmartHomeConfig', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: this.props.globalstate.location,
                service: service,
                data: config
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(this.props.globalstate.app.showSmartHomeConfigurator(null))
            .catch(error => console.log(error));
    }
    getStates(loc) {
        fetch('https://' + window.location.hostname + '/HomeAPI/GetStateObjects', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: loc
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(result => result.json())
            .then(States => this.setState({ States }))
            .catch(error => console.log(error));
    }
    getServiceStates(loc) {
        fetch('https://' + window.location.hostname + '/HomeAPI/GetStateServices', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: loc
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(result => result.json())
            .then(Services => this.setState({ Services }))
            .catch(error => console.log(error));
    }
    getWifiUsers(loc) {
        fetch('https://' + window.location.hostname + '/HomeAPI/WifiUsers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: loc
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(result => result.json())
            .then(WifiUsers => this.setState({ WifiUsers }))
            .catch(error => console.log(error));
    }
}
export default SmarthomeConfigurator;