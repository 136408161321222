import React, { Component } from 'react';

class Lock extends Component {
    render() {
        var divstyle = this.props.divstyle
        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 612 612" style={Object.assign({},{enableBackground:'new 0 0 612 612'})}>
            <path d="M189.45,351.607l42.016-23.823l-7.402-26.786c-63.74-6.041-113.602-59.725-113.568-125.054
			c0.036-69.541,56.021-125.496,125.562-125.496c23.216,0,44.958,6.301,63.614,17.287c6.392,3.764,13.073-4.473,8.089-9.966
			C275.385,22.089,228.569-0.245,176.537,0.002C79.864,0.461,1.264,79.553,1.381,176.228
			c0.118,97.107,78.875,175.792,176.01,175.792C181.445,352.02,185.464,351.877,189.45,351.607z"/>
		<path d="M433.682,235.291l-28.847-50.876c-2.488-4.389-6.894-6.583-11.3-6.583s-8.812,2.195-11.3,6.583l-28.847,50.876
			c13.042-3.275,26.47-4.933,40.146-4.933C407.211,230.357,420.64,232.016,433.682,235.291z"/>
		<path d="M353.389,554.541l28.847,50.876c2.488,4.389,6.894,6.583,11.3,6.583c4.406,0,8.812-2.195,11.3-6.583l28.847-50.876
			c-13.042,3.275-26.47,4.933-40.146,4.933C379.858,559.474,366.43,557.816,353.389,554.541z"/>
		<path d="M183.034,383.617c-8.777,4.977-8.777,17.623,0,22.599l50.877,28.847c-3.275-13.042-4.933-26.47-4.933-40.147
			c0-13.677,1.658-27.104,4.933-40.146L183.034,383.617z"/>
		<path d="M604.037,383.617L553.16,354.77c3.275,13.042,4.933,26.47,4.933,40.146c0,13.677-1.658,27.105-4.933,40.147l50.877-28.847
			C612.814,401.239,612.814,388.593,604.037,383.617z"/>
		<path d="M537.912,237.595c-1.148,0-2.328,0.155-3.52,0.484l-56.389,15.582c11.561,6.919,22.238,15.241,31.892,24.895
			c9.655,9.654,17.977,20.331,24.895,31.892l15.582-56.389C552.73,245.525,546.134,237.595,537.912,237.595z"/>
		<path d="M249.158,552.237c1.148,0,2.329-0.155,3.52-0.484l56.389-15.582c-11.561-6.918-22.238-15.241-31.892-24.895
			c-9.654-9.655-17.976-20.331-24.895-31.892l-15.582,56.389C234.34,544.306,240.935,552.237,249.158,552.237z"/>
		<path d="M249.158,237.595c-8.222,0-14.818,7.93-12.46,16.464l12.925,46.774l2.656,9.614c2.267-3.788,4.683-7.483,7.251-11.077
			c5.267-7.372,11.154-14.324,17.645-20.815c9.655-9.655,20.331-17.976,31.892-24.895l-56.389-15.582
			C251.487,237.75,250.306,237.595,249.158,237.595z"/>
		<path d="M537.912,552.237c8.222,0,14.818-7.931,12.46-16.464l-15.582-56.389c-6.918,11.561-15.241,22.238-24.895,31.892
			c-9.654,9.655-20.33,17.976-31.892,24.895l56.389,15.582C535.584,552.083,536.764,552.237,537.912,552.237z"/>
		<path d="M333.281,266.499c-2.409,1.133-4.784,2.324-7.116,3.586c-2.213,1.197-4.389,2.452-6.529,3.761
			c-6.031,3.689-11.761,7.819-17.154,12.34c-6.397,5.363-12.313,11.279-17.676,17.676c-4.52,5.393-8.65,11.123-12.34,17.154
			c-0.995,1.627-1.954,3.279-2.884,4.948c-1.583,2.84-3.072,5.74-4.461,8.697c-1.841,3.917-3.512,7.929-4.995,12.032
			c-1.75,4.842-3.24,9.807-4.463,14.877c-1.633,6.774-2.778,13.737-3.399,20.851c-0.36,4.118-0.554,8.284-0.554,12.495
			c0,4.212,0.194,8.377,0.554,12.495c0.621,7.114,1.767,14.077,3.399,20.851c1.222,5.071,2.712,10.036,4.463,14.877
			c1.483,4.103,3.154,8.115,4.995,12.032c2.206,4.694,4.657,9.249,7.346,13.644c3.689,6.031,7.819,11.762,12.34,17.154
			c5.363,6.397,11.279,12.314,17.676,17.676c5.393,4.52,11.123,8.65,17.154,12.34c4.396,2.689,8.951,5.14,13.645,7.346
			c3.916,1.841,7.928,3.511,12.03,4.995c4.842,1.75,9.808,3.241,14.878,4.463c6.774,1.633,13.737,2.778,20.851,3.399
			c4.118,0.359,8.284,0.554,12.495,0.554s8.378-0.195,12.496-0.555c7.114-0.621,14.076-1.766,20.85-3.399
			c5.071-1.222,10.036-2.712,14.878-4.463c4.102-1.483,8.114-3.153,12.03-4.995c4.694-2.206,9.25-4.657,13.645-7.346
			c6.031-3.689,11.761-7.819,17.154-12.34c6.397-5.363,12.313-11.279,17.676-17.676c4.521-5.393,8.651-11.124,12.34-17.155
			c2.688-4.395,5.139-8.951,7.346-13.644c1.841-3.916,3.511-7.929,4.995-12.03c1.75-4.842,3.241-9.808,4.463-14.878
			c1.633-6.774,2.778-13.737,3.399-20.85c0.359-4.118,0.554-8.284,0.554-12.495c0-4.212-0.195-8.377-0.554-12.495
			c-0.622-7.114-1.767-14.076-3.399-20.85c-1.222-5.071-2.712-10.036-4.463-14.878c-1.483-4.102-3.154-8.114-4.995-12.03
			c-2.206-4.694-4.657-9.249-7.346-13.644c-3.689-6.031-7.82-11.762-12.34-17.155c-5.363-6.397-11.279-12.313-17.676-17.676
			c-5.393-4.521-11.123-8.65-17.154-12.34c-4.396-2.689-8.951-5.14-13.645-7.346c-3.916-1.841-7.928-3.511-12.03-4.995
			c-4.842-1.75-9.808-3.241-14.878-4.463c-6.774-1.633-13.736-2.778-20.85-3.399c-4.118-0.36-8.284-0.554-12.496-0.554
			c-4.211,0-8.377,0.194-12.495,0.554c-7.114,0.621-14.077,1.767-20.851,3.399c-5.071,1.222-10.036,2.712-14.878,4.463
			C341.208,262.989,337.197,264.659,333.281,266.499z"/>
            </svg>
</div>
        );
    }
}
export default Lock;

