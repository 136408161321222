import React, { Component } from 'react';

class LeavingHouse extends Component {
    render() {
        var divstyle = this.props.divstyle

        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 512.192 512.201" style={Object.assign({},{enableBackground: 'new 0 0 512.192 512.201'})}>
            <path d="M512.094,307.107c-0.537,1.202-1.068,2.406-1.611,3.605c-3.518,7.769-11.945,11.222-20.082,7.606
	            c-13.68-6.075-27.154-12.611-40.77-18.841c-4.127-1.888-6.059-5.844-8.85-9.155c-1.406,1.581-0.867,3.136-0.871,4.53
	            c-0.041,14.495,0.059,28.992-0.074,43.487c-0.029,3.026,0.803,5.406,2.633,7.752c5.738,7.353,10.785,15.325,17.102,22.128
	            c9.305,10.021,12.344,21.813,13.492,34.948c1.621,18.555,4.164,37.032,6.512,55.52c1.297,10.209-5.133,19.111-15.521,20.904
	            c-9.068,1.565-18.523-4.913-19.924-14.688c-2.332-16.304-3.941-32.711-5.861-49.072c-0.639-5.455-1.273-10.91-1.977-16.357
	            c-0.121-0.953-0.334-2.043-0.896-2.767c-5.688-7.335-11.457-14.606-18.148-23.095c-0.859,8.735-1.734,16.042-2.25,23.375
	            c-0.354,5.045-1.342,9.815-3.361,14.481c-8.195,18.925-16.172,37.944-24.377,56.865c-3.459,7.975-11.049,12.416-18.652,11.378
	            c-8.4-1.147-14.65-7.208-16.035-15.529c-0.689-4.134,0.578-7.897,2.16-11.604c7.121-16.673,14.012-33.449,21.49-49.961
	            c2.801-6.185,2.867-12.591,3.688-18.977c4.609-35.943,4.926-72.066,4.359-108.222c-0.01-0.613-0.184-1.226-0.387-2.5
	            c-7.777,6.516-16.123,11.403-15.273,23.452c0.553,7.874-1.059,15.915-1.844,23.865c-0.977,9.893-8.166,16.543-16.609,15.542
	            c-9.352-1.108-14.617-8.864-13.541-19.229c1.307-12.582,2.277-25.199,3.336-37.806c0.352-4.205,2.205-7.532,5.158-10.483
	            c12.26-12.248,24.434-24.58,36.648-36.869c4.697-4.725,10.436-7.029,17.063-7.072c7.332-0.048,14.664,0.046,21.994-0.016
	            c10.275-0.087,17.785,4.346,22.906,13.275c6.211,10.828,12.746,21.472,18.979,32.289c1.582,2.743,3.59,4.525,6.488,5.795
	            c9.602,4.198,19.002,8.866,28.672,12.896c6.611,2.756,11.896,6.492,14.236,13.541C512.094,303.774,512.094,305.44,512.094,307.107z"
	            />
            <path d="M0.095,153.107c3.002-3.76,6.674-6.817,10.289-9.951c46.452-40.25,92.887-80.521,139.326-120.786
	            c0.881-0.765,1.759-1.531,2.652-2.281c5.94-4.988,9.539-5.061,15.364-0.034c17.027,14.688,34.005,29.432,51.01,44.146
	            c1.48,1.28,3.015,2.501,5.576,4.617c0-7.29-0.002-13.497,0.001-19.705c0.002-2.498-0.121-5.007,0.057-7.492
	            c0.392-5.479,3.779-8.911,9.271-9.228c3.819-0.22,7.661-0.115,11.492-0.059c7.075,0.104,10.708,3.69,10.74,10.876
	            c0.07,16.158,0.082,32.317-0.052,48.476c-0.026,3.24,0.818,5.482,3.396,7.688c18.232,15.584,36.304,31.357,54.42,47.076
	            c8.014,6.951,8.234,10.465,1.156,18.103c-2.264,2.444-4.498,4.918-6.818,7.309c-4.922,5.074-9.443,5.396-14.859,0.738
	            c-16.291-14.01-32.498-28.116-48.735-42.189c-26.557-23.021-53.171-45.977-79.592-69.152c-3.577-3.137-5.665-3.292-9.366-0.062
	            c-42.16,36.81-84.487,73.427-126.785,110.077c-7.403,6.415-11.208,6.25-17.75-0.846c-3.7-4.013-7.201-8.206-10.792-12.318
	            C0.095,156.44,0.095,154.774,0.095,153.107z"/>
            <path d="M192.311,241.495c0-13.831,0.016-27.662-0.007-41.493c-0.011-6.46-1.536-8.076-7.821-8.094
	            c-16.331-0.044-32.662-0.041-48.992-0.006c-5.967,0.015-7.597,1.695-7.602,7.806c-0.022,27.662-0.096,55.324,0.083,82.984
	            c0.026,4.052-0.856,5.347-5.139,5.305c-22.827-0.224-45.658-0.076-68.487-0.106c-13.846-0.019-22.024-8.246-22.037-22.13
	            c-0.02-21.663,0.054-43.327-0.063-64.989c-0.016-2.948,0.923-4.832,3.145-6.758c40.163-34.815,80.293-69.669,120.27-104.697
	            c3.551-3.111,5.5-2.939,8.96,0.062c40.026,34.716,80.191,69.271,120.387,103.791c2.102,1.805,2.936,3.607,2.924,6.347
	            c-0.098,22.162-0.008,44.325-0.072,66.488c-0.039,13.674-8.135,21.827-21.74,21.868c-23.164,0.069-46.327-0.06-69.489,0.104
	            c-3.57,0.025-4.446-1.04-4.399-4.488C192.42,269.49,192.311,255.492,192.311,241.495z"/>
            <path d="M415.809,207.876c-17.297-0.11-31.791-14.919-31.502-32.185c0.287-17.208,14.674-31.394,31.818-31.377
	            c17.367,0.019,31.904,14.647,31.732,31.934C447.684,193.71,433.217,207.986,415.809,207.876z"/>
            <path d="M178.984,359.251c3.76,0.194,6.12,1.817,7.232,4.942c3.459,9.721,7.638,19.103,12.688,28.104
	            c2.074,3.696,0.849,6.848-2.326,9.159c-4.566,3.322-9.221,6.546-14.006,9.541c-4.113,2.573-8.028,1.507-10.579-2.743
	            c-6.431-10.72-11.529-22.071-15.391-33.951c-1.749-5.381,0.056-8.531,5.545-10.2C167.856,362.368,173.614,360.792,178.984,359.251z"
	            />
            <path d="M217.214,417.292c2.357-0.064,4.046,1.335,5.722,2.694c6.739,5.475,14.08,9.97,21.871,13.785
	            c5.791,2.836,6.859,5.662,4.588,11.641c-1.767,4.653-3.509,9.323-5.494,13.886c-2.019,4.639-5.548,6.035-10.145,3.955
	            c-12.021-5.439-23.146-12.354-33.144-20.99c-3.28-2.833-3.665-6.994-0.833-10.148c3.995-4.449,8.251-8.666,12.468-12.91
	            C213.566,417.877,215.246,417.119,217.214,417.292z"/>
            <path d="M159.194,288.307c13.989,0.021,14.053,0.017,15.212,14.076c0.696,8.437,1.024,16.896,2.221,25.298
	            c0.602,4.227-1.603,7.165-5.696,8.088c-5.17,1.166-10.427,1.995-15.679,2.74c-4.425,0.628-7.368-1.935-8.098-6.718
	            c-1.707-11.183-2.248-22.464-2.772-33.739c-0.328-7.044,2.335-9.691,9.328-9.743C155.539,288.295,157.367,288.307,159.194,288.307z"
	            />
            <path d="M319.875,464.646c-0.023,15.228-0.871,15.922-15.924,14.756c-9.463-0.732-18.916-1.445-28.229-3.364
	            c-5.104-1.053-7.268-4.028-6.246-8.902c1.02-4.877,2.336-9.692,3.527-14.534c1.043-4.238,3.803-6.235,8.072-5.599
	            c10.711,1.598,21.49,2.427,32.281,3.189c4.178,0.296,6.107,3.084,6.469,6.962C320.055,459.631,319.87,462.146,319.875,464.646z"/>
            </svg>

</div>
        );
    }
}
export default LeavingHouse;

