import React, { Component } from 'react';

class Control extends Component {
    render() {
        var divstyle = this.props.divstyle
        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 41.011 41.011" style={Object.assign({},{enableBackground:'new 0 0 41.011 41.011'})}>
            <path d="M40.839,6.436C40.839,2.887,38.086,0,34.703,0H6.308C2.925,0,0.172,2.887,0.172,6.436v28.139
		c0,3.549,2.753,6.436,6.136,6.436h28.395c3.383,0,6.136-2.887,6.136-6.436C40.839,34.575,40.839,6.436,40.839,6.436z
		 M38.726,34.574c0,2.384-1.805,4.322-4.023,4.322H6.308c-2.219,0-4.023-1.938-4.023-4.322V6.436c0-2.384,1.805-4.322,4.023-4.322
		h28.395c2.219,0,4.023,1.938,4.023,4.322V34.574z M10.164,10.979h11.085v0.94c0,0.791,0.645,1.434,1.434,1.434h0.904
		c0.791,0,1.434-0.643,1.434-1.434v-0.94h5.36c0.207,0,0.375-0.168,0.375-0.375s-0.168-0.375-0.375-0.375h-5.36v-0.94
		c0-0.789-0.643-1.434-1.434-1.434h-0.904c-0.789,0-1.434,0.644-1.434,1.434v0.94H10.164c-0.207,0-0.375,0.168-0.375,0.375
		S9.957,10.979,10.164,10.979z M22.249,10.979v-0.75v-0.94c0-0.238,0.195-0.434,0.434-0.434h0.904c0.238,0,0.434,0.195,0.434,0.434
		v0.94v0.75v0.94c0,0.24-0.195,0.434-0.434,0.434h-0.904c-0.238,0-0.434-0.193-0.434-0.434V10.979z M10.164,21.848h4.031v0.94
		c0,0.791,0.644,1.434,1.434,1.434h0.904c0.791,0,1.434-0.643,1.434-1.434v-0.94h12.414c0.207,0,0.375-0.168,0.375-0.375
		s-0.168-0.375-0.375-0.375H17.967v-0.94c0-0.789-0.643-1.434-1.434-1.434h-0.904c-0.789,0-1.434,0.645-1.434,1.434v0.94h-4.031
		c-0.207,0-0.375,0.168-0.375,0.375S9.957,21.848,10.164,21.848z M15.195,21.848v-0.75v-0.94c0-0.238,0.195-0.434,0.434-0.434h0.904
		c0.238,0,0.434,0.195,0.434,0.434v0.94v0.75v0.94c0,0.24-0.195,0.434-0.434,0.434h-0.904c-0.238,0-0.434-0.193-0.434-0.434V21.848z
		 M30.383,30.029h-2.409v-0.941c0-0.789-0.642-1.434-1.434-1.434h-0.904c-0.789,0-1.434,0.645-1.434,1.434v0.941H10.166
		c-0.207,0-0.375,0.168-0.375,0.375s0.168,0.375,0.375,0.375h14.036v0.94c0,0.791,0.645,1.434,1.434,1.434h0.904
		c0.791,0,1.434-0.643,1.434-1.434v-0.94h2.409c0.207,0,0.375-0.168,0.375-0.375S30.59,30.029,30.383,30.029z M26.973,30.029v0.75
		v0.94c0,0.24-0.195,0.434-0.434,0.434h-0.904c-0.238,0-0.434-0.193-0.434-0.434v-0.94v-0.75v-0.941
		c0-0.238,0.195-0.434,0.434-0.434h0.904c0.238,0,0.434,0.195,0.434,0.434V30.029z"/>
            </svg>
</div>
        );
    }
}
export default Control;

