import React, { Component } from 'react';
import iro from '@jaames/iro';

class ColorPicker extends Component {
    componentDidMount() {
        const { props } = this;
        // create a new iro color picker and pass component props to it handleRadius
        this.colorPickerKelvin = new iro.ColorPicker(this.temp, Object.assign({}, { width: (this.props.window.width-10)*0.70, height: (this.props.window.height-10)*0.2, color: iro.Color.kelvinToRgb(props.color), layoutDirection: 'vertical' }, props.onColorChange, {
            layout: [{
                component: iro.ui.Slider,
                options: {
                    sliderType: 'kelvin', sliderSize: (this.props.window.height - 10) * 0.2*0.5, minTemperature: 2000, maxTemperature: 6000
                }
            }]
        }));
        // call onColorChange prop whenever the color changes
        this.colorPickerKelvin.on('input:end', (color) => {
            if (props.onColorChange) props.onColorChange(color);
        });
        this.colorPickerValue = new iro.ColorPicker(this.bri, Object.assign({}, { width: (this.props.window.width - 10) * 0.70, height: (this.props.window.height - 10) * 0.2, color: iro.Color.kelvinToRgb(props.color), layoutDirection: 'vertical' }, props.onValueChange, {
            layout: [{
                component: iro.ui.Slider,
                options: {
                    sliderType: 'value', sliderSize: (this.props.window.height - 10) * 0.2 * 0.5
                }
            }]
        }));
        this.colorPickerValue.color.setChannel('hsv', 'v', props.value/254*100);
        // call onColorChange prop whenever the color changes
        this.colorPickerValue.on('input:end', (color) => {
            if (props.onValueChange) props.onValueChange(color);
        });
    }

    componentDidUpdate() {
        // isolate color from the rest of the props
        const { color, value, ...colorPickerState } = this.props;
        // update color
        if (color) this.colorPickerKelvin.color.set(iro.Color.kelvinToRgb(color));
        if (value) this.colorPickerValue.color.setChannel('hsv', 'v', value / 254 * 100);
        // push rest of the component props to the colorPicker's state
        this.colorPickerKelvin.setState(colorPickerState);
    }

    render() {
        const styles = {
            bri: {
                height: '50%',
                width: '100%',
                position: 'relative',
                marginBottom: 0,
            },
            temp: {
                height: '50%',
                width: '100%',
                position: 'relative',
                marginBottom: 0,
            },
            popup: {
                height: '100%',
                width: '100%',
                background: 'white',
            }
        }
        return (
            <div style={styles.popup}>
                <div style={styles.bri}>
                    <div ref={bri => this.bri = bri} />
                </div>
                <div style={styles.temp}>
                    <div ref={temp => this.temp = temp} />
                </div>
            </div>
        );
    }
}

export default ColorPicker;