import React, { Component } from 'react';

class House extends Component {
    render() {
        var divstyle = this.props.divstyle

        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 512.192 512.202" style={Object.assign({},{enableBackground: 'new 0 0 512.192 512.202'})}>
            <path d="M2.462,250.422c4.787-5.995,10.641-10.871,16.404-15.866c74.065-64.176,148.103-128.387,222.146-192.588
		        c1.404-1.218,2.804-2.441,4.229-3.637c9.472-7.954,15.208-8.067,24.498-0.055c27.148,23.418,54.219,46.927,81.332,70.387
		        c2.359,2.043,4.807,3.988,8.889,7.363c0-11.623-0.004-21.52,0.004-31.418c0.002-3.983-0.193-7.983,0.09-11.947
		        c0.625-8.737,6.025-14.208,14.783-14.711c6.09-0.35,12.215-0.184,18.322-0.093c11.281,0.167,17.074,5.886,17.125,17.341
		        c0.111,25.763,0.131,51.529-0.082,77.291c-0.043,5.167,1.305,8.742,5.416,12.256c29.07,24.848,57.883,49.999,86.77,75.062
		        c12.775,11.083,13.127,16.685,1.844,28.862c-3.609,3.897-7.172,7.841-10.871,11.653c-7.85,8.09-15.059,8.603-23.695,1.177
		        c-25.977-22.338-51.816-44.83-77.705-67.27c-42.342-36.707-84.777-73.305-126.904-110.259c-5.703-5.001-9.032-5.249-14.933-0.097
		        c-67.221,58.689-134.71,117.073-202.151,175.512c-11.803,10.229-17.871,9.965-28.302-1.349
		        c-5.899-6.398-11.481-13.084-17.207-19.641C2.462,255.736,2.462,253.08,2.462,250.422z"/>
	        <path d="M308.938,391.351c0-22.053,0.025-44.105-0.012-66.158c-0.018-10.3-2.447-12.877-12.469-12.905
		        c-26.039-0.068-52.078-0.065-78.115-0.008c-9.515,0.022-12.113,2.703-12.121,12.444c-0.036,44.105-0.152,88.213,0.132,132.315
		        c0.042,6.459-1.365,8.523-8.194,8.456c-36.396-0.354-72.798-0.121-109.199-0.17c-22.077-0.029-35.116-13.147-35.136-35.284
		        c-0.032-34.541,0.086-69.083-0.1-103.621c-0.025-4.701,1.472-7.705,5.014-10.775c64.038-55.512,128.022-111.083,191.763-166.934
		        c5.663-4.962,8.769-4.688,14.287,0.097c63.818,55.353,127.861,110.449,191.951,165.488c3.35,2.878,4.68,5.754,4.662,10.119
		        c-0.154,35.337-0.014,70.674-0.115,106.012c-0.063,21.803-12.971,34.803-34.666,34.868c-36.932,0.111-73.863-0.094-110.795,0.165
		        c-5.691,0.04-7.088-1.659-7.014-7.157C309.112,435.989,308.938,413.668,308.938,391.351z"/>
            </svg>

</div>
        );
    }
}
export default House;

