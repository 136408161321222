import React, { Component } from 'react';
//import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import 'chartjs-adapter-moment';

class LineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineChartData: undefined,
            paramslength: this.props.dataparams.length,
            timespanmode: (this.props.timespan !== undefined)
        };
    }
    componentDidMount() {
        if (this.timerID === undefined) {
            this.timerID = setInterval(
                () => this.updateValues(this.props.globalstate.location, this.props.dataparams, this.props.since),
                300000
            );
        }
        this.updateValues(this.props.globalstate.location, this.props.dataparams, this.props.since);
    }
    componentWillUnmount() {
        if(this.timerID !== undefined) clearInterval(this.timerID);
    }
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    updateValues(loc, params, since) {
        if(params.length == 0) return;
        var body = '';
        if(this.state.timespanmode)body = JSON.stringify({
            location: loc,
            tuples: params,
            since: Math.round(new Date().getTime() / 1000) - this.props.timespan,
            till: Math.round(new Date().getTime() / 1000)
        });
        else if(this.props.since !== undefined && this.props.till !== undefined)
        {
            body = JSON.stringify({
                location: loc,
                tuples: params,
                since: Math.round(this.props.since.getTime() / 1000),
                till: Math.round(this.props.till.getTime() / 1000)
            });
        }
        fetch('https://' + window.location.hostname + '/HomeAPI/GetData', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                credentials: 'include'
            },
            body: body,
            credentials: 'include'
        })
            .then(this.handleErrors)
            .then(result => result.json())
            .then(lineChartData => this.setState({ lineChartData }))
            .catch(error => console.log(error));
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.globalstate.location !== prevProps.globalstate.location|| this.props.till !== prevProps.till || this.props.since !== prevProps.since || this.props.dataparams.length !== this.state.paramslength) {
            this.setState({ lineChartData: undefined, timespanmode: (this.props.timespan !== undefined), paramslength: this.props.dataparams.length });
            this.updateValues(this.props.globalstate.location, this.props.dataparams);
        }
    }
    render() {
        if (this.state.lineChartData === undefined) return "";
        var colors = this.props.globalstate.colors.light;
        if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var color = ['red', 'DodgerBlue', colors.fgcolor, 'fuchsia', 'green', 'cyan', 'orange', 'lightsteelblue'];
        //console.log(this.props.globalstate.dark);
        //console.log(this.state.lineChartData);
        var data = {
            datasets: this.state.lineChartData
            /*[
                {
                    label: 'My First dataset',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [65, 59, 80, 81, 56, 55, 40]
                }
            ]*/
        };
        
        var options = {
            responsive: false,
            hoverMode: 'index',
            stacked: false,
            title: {
                display: false,
                text: 'SmartHome Daten',
                fontColor: colors.fgcolor
            },
            plugins:{legend: {
                display: false
            }},
            scales: {x:{
                    type: "time",
                    id: "x",
                    axis: "x",
                    time: {
                        displayFormats: {
                            /*second: 'L H:mm:ss',
                            minute: 'L H:mm',
                            hour: 'L, H:mm',
                            day: 'L',
                            week: 'L'*/
                            second: 'H:mm:ss',
                            minute: 'H:mm',
                            hour: 'H:mm',
                        },
                        // format: 'x',
                        // round: 'day'
                        tooltipFormat: 'll HH:mm'
                    },
                    /*scaleLabel: {
                        display: false,
                        labelString: 'Zeit',
                        color: colors.fgcolor
                    },*/
                    ticks: {
                        font:{size: 10},
                        maxRotation: 0,
                        color: colors.fgcolor
                    },
                    grid: {
                        display: true,
                        color: colors.midcolor,
                        borderColor: colors.midcolor,
                    }
                },},
            pan: {
                enabled: true,
                mode: 'x'
            },
            zoom: {
                enabled: true,
                mode: 'x'
            }/*,
            maintainAspectRatio: false*/
        };
        if((!this.props.globalstate.mobile && this.props.showlegend === undefined) || (this.props.showlegend !== undefined && this.props.showlegend))
        {
            options.plugins.legend = {
                display: true,
                labels: {
                    color: colors.fgcolor
                }
            };
        }
        var axis = [];
        for (var i = 0; i < data.datasets.length; i++) {
            data.datasets[i].borderColor = color[i];
            data.datasets[i].borderWidth = 0.5;
            data.datasets[i].backgroundColor = 'rgba(0,0,0,0)';
            data.datasets[i].pointBorderColor = 'rgba(0,0,0,0)';
            data.datasets[i].pointBackgroundColor = 'rgba(20,20,20,0)';
            data.datasets[i].pointBorderWidth = 0.5;
            data.datasets[i].lineTension = 0;
            if (!(data.datasets[i].unit in axis))
            {
                axis[data.datasets[i].unit] = true;
                options.scales["yaxis" + data.datasets[i].unit] = {
                    type: "linear",
                    display: true,
                    position: "right",
                    id: "yaxis" + data.datasets[i].unit,
                    axis: "y",
                    ticks: {
                        color: color[i], // this here
                    },
                    grid: {
                        display: true,
                        color: color[i],
                        borderColor: color[i],
                        drawOnChartArea: false,
                        drawTicks: true
                    }
                };
            }
            data.datasets[i].yAxisID = "yaxis" + data.datasets[i].unit;
            data.datasets[i].xAxisID = "x";
        }

        return (
            <Chart type='line' width={this.props.width} height={this.props.height} data={data} options={options} key={this.props.width * this.props.height} redraw/>
            );
        
    }
}

export default LineChart;