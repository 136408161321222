import React, { Component } from 'react';
import DoorWindowState from './DoorWindowState';

class DoorsAndWindows extends Component {
    render() {
        var output = "";
        var colors = this.props.globalstate.colors.light;
        if (this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        var divstyle = { width: '100%', height: '100%', background: 'none' };
        var lustyle = { position: 'absolute', bottom: '0px', color: colors.fgcolor };
        if (this.props.globalstate.smartmirror) lustyle = { position: 'absolute', bottom: '0px', right: '45px', color: colors.fgcolor };
        if(this.props.globalstate !== undefined)
        {
            var latestUpdate = new Date(0);
            Object.entries(this.props.globalstate.smarthomestate).forEach(function (item) { var curitem = new Date(item[1].lastUpdate); if (curitem > latestUpdate) latestUpdate = curitem; });
            //console.log(latestUpdate.toLocaleString());
            if (this.props.globalstate.location === 'in') {
                /*if (this.props.globalstate.smartmirror)
                {
                    output =
                        <div style={divstyle}>
                            <DoorWindowState height={this.props.height * 0.25} width={this.props.width } globalstate={this.props.globalstate} floor="hhdg" key="hhdg" />
                            <DoorWindowState height={this.props.height * 0.25} width={this.props.width } globalstate={this.props.globalstate} floor="hhog" key="hhog" />
                            <DoorWindowState height={this.props.height * 0.25} width={this.props.width } globalstate={this.props.globalstate} floor="hheg" key="hheg" />
                            <DoorWindowState height={this.props.height * 0.25} width={this.props.width } globalstate={this.props.globalstate} floor="hhkg" key="hhkg" />
                            <div style={lustyle}>{latestUpdate.toLocaleString()}</div>
                        </div>;
                }
                else*/
                    output =
                        <div style={divstyle}>
                            <div style={{display: 'flex'}}>
                                <DoorWindowState height={this.props.height * 0.5} width={this.props.width * 0.5} globalstate={this.props.globalstate} scalewidth={0.5} floor="hhdg" key="hhdg" />
                                <DoorWindowState height={this.props.height * 0.5} width={this.props.width * 0.5} globalstate={this.props.globalstate} scalewidth={0.5} floor="hhog" key="hhog" />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <DoorWindowState height={this.props.height * 0.5} width={this.props.width * 0.5} globalstate={this.props.globalstate} scalewidth={0.5} floor="hheg" key="hheg" />
                                <DoorWindowState height={this.props.height * 0.5} width={this.props.width * 0.5} globalstate={this.props.globalstate} scalewidth={0.5} floor="hhkg" key="hhkg" />
                            </div>
                            <div style={lustyle}>{latestUpdate.toLocaleString()}</div>
                        </div>;
                }
                else if (this.props.globalstate.location === 'ac') {
                    output =
                        <div style={divstyle}>
                        <DoorWindowState height={this.props.height} width={this.props.width} globalstate={this.props.globalstate} scalewidth={1} floor="ac" key="ac" />
                        <div style={lustyle}>{latestUpdate.toLocaleString()}</div>
                        </div>;
                }
                else if (this.props.globalstate.location === 'rcm')
                {
                    output =
                        <div style={divstyle}>
                        <DoorWindowState height={Math.floor(this.props.height * 0.5)} width={this.props.width} scalewidth={1} floor="rcmOG" key="rcmOG" globalstate={this.props.globalstate}/>
                        <DoorWindowState height={Math.floor(this.props.height * 0.5)} width={this.props.width} scalewidth={1} floor="rcmEG" key="rcmEG" globalstate={this.props.globalstate} />
                        <div style={lustyle}>{latestUpdate.toLocaleString()}</div>
                        </div>;
                }
                else if (this.props.globalstate.location === 'ka') {
                    output =
                        <div style={divstyle}>
                        <DoorWindowState height={this.props.height} width={this.props.width} globalstate={this.props.globalstate} scalewidth={1} floor="ka" key="ka" />
                        <div style={lustyle}>{latestUpdate.toLocaleString()}</div>
                        </div>;
                }
        }
        return output;
    }
}

export default DoorsAndWindows;