import React, { Component } from 'react';
import reactCSS from 'reactcss'
import { Interactive } from 'react-interactive';
import HSBColorPicker from './HSBColorPicker';
import rgb from 'color-space/rgb.js';
import hsv from 'color-space/hsv.js';
import xyy from 'color-space/xyy.js';
import xyz from 'color-space/xyz.js';

class ColorLightControl extends Component {
    num2hex(num) {
        var out = num.toString(16);
        if (out.length < 2) out = '0' + out;
        return out;
    }
constructor(props) {
    super(props);
    var hsvarr = rgb.hsv(xyz.rgb(xyy.xyz([((this.props.value & 0xFF00) >>> 8) / 254.0, ((this.props.value & 0xFF0000) >>> 16) / 254.0, 20]), xyz.whitepoint[10].D65));
    hsvarr[2] = (this.props.value & 0xFF) * 100 / 254.0;
    var rgbarr = hsv.rgb(hsvarr);
        this.state = {
            displayColorPicker: false,
            bri: (this.props.value & 0xFF),
            rgb: { r: rgbarr[0], g: rgbarr[1], b: rgbarr[2] },
            hsv: { v: hsvarr[2] , s: hsvarr[1] , h: hsvarr[0] }
        };
        this.lampOn = this.lampOn.bind(this);
        this.lampOff = this.lampOff.bind(this);
}
render() {
    const styles = reactCSS({
        'default': {
            color: {
                width: '100%',
                height: '100%',
                borderRadius: '2px',
                background: `#fff`,
            },
            swatch: {
                padding: '0px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                flex: 1,
                margin: '1px',
                flexBasis: '150px'
            },
            popover: {
                position: 'absolute',
                zIndex: '101',
                width: '70%',
                height: '70%',
                top: '15%',
                left: '15%'
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
        var output = "";
        if(this.props.globalstate !== undefined && this.props.desc !== undefined)
        {
            var colors = this.props.globalstate.colors.light;
            if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
            var fgcolor = colors.fgcolor;
            var midcolor = colors.midcolor;
            if(this.props.globalstate.selectedItem !== undefined && this.props.globalstate.selectedItem.id === this.props.id)
            {
                fgcolor = colors.redcolor;
                midcolor = colors.redcolor;
            }
            var hoverstyle = {fill: fgcolor, stroke: fgcolor, color: fgcolor, background: fgcolor+'33'}
            var buttonstyle = {fill: midcolor, stroke: midcolor, flex: 1, flexBasis: '120px', border: '1px', margin:'0px 2px', borderStyle: 'solid', height: '32px',color: midcolor, background: 'none',  zIndex:100, position:'relative' }
            var buttontextstyle = { position: 'relative', float: 'left', top: '50%', transform: 'translate(0%, -50%)', color: fgcolor }
            styles.swatch.background = colors.fgcolor;
            styles.color.background = 'rgba(' + this.state.rgb.r + ',' + this.state.rgb.g + ',' + this.state.rgb.b + ',' + this.state.bri/254 + ')';//this.state.color;
            output = <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }}>
                        <div style={{flex: 1, margin: '1px', flexBasis: '150px'}}>
                            <div style={Object.assign({},buttontextstyle)} >{this.props.desc}</div>
                        </div>
                        <div style={styles.swatch} onClick={this.handleClick}>
                            <div style={styles.color} />
                        </div>
                        {this.state.displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose} />
                    <HSBColorPicker window={this.props.globalstate.window} color={{ h: this.state.hsv.h, s: this.state.hsv.s, v: 100 }} value={this.state.bri} onColorChange={this.handleChangeComplete} onValueChange={this.handleValueChangeComplete} />
                        </div> : null}
                        <Interactive as="div" hover={hoverstyle} style={Object.assign({}, buttonstyle, { width: '3%' })} onClick={this.lampOn}>
                            ON
                        </Interactive>
                        <Interactive as="div" hover={hoverstyle} style={Object.assign({}, buttonstyle, { width: '3%' })} onClick={this.lampOff}>
                            OFF
                        </Interactive>
                    </div>
        }
        return output;
}
        handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    handleChangeComplete = (color) => {
        var xyzval = rgb.xyz([color.rgb.r, color.rgb.g, color.rgb.b], xyz.whitepoint[10].D65);
        var xyyval = xyz.xyy(xyzval)
        this.setState({ hsv: color.hsv, rgb: color.rgb });
        var lampstate = (Math.round(xyyval[2] * 254 / 100.0) << 24) + (Math.round(xyyval[1] * 254) << 16) + (Math.round(xyyval[0]*254) << 8) + Math.round(this.state.bri);
        this.sendCommand(this.props.id, lampstate);
    };
    handleValueChangeComplete = (color) => {
        var xyzval = rgb.xyz([this.state.rgb.r, this.state.rgb.g, this.state.rgb.b], xyz.whitepoint[10].D65);
        var xyyval = xyz.xyy(xyzval)
        var bri = Math.round(color.hsv.v / 100 * 254);
        this.setState({ bri: bri });
        var lampstate = (Math.round(xyyval[2] * 254 / 100.0) << 24) + (Math.round(xyyval[1] * 254) << 16) + (Math.round(xyyval[0] * 254) << 8) + Math.round(bri);
        this.sendCommand(this.props.id, lampstate);
    };
    handleChange = (color) => {
        this.setState({ hsv: color.hsv, rgb: color.rgb });
    };
    lampOn()
    {
        var bri = 254;
        var xyyval = xyz.xyy(rgb.xyz([this.state.rgb.r, this.state.rgb.g, this.state.rgb.b], xyz.whitepoint[10].D65));
        this.setState({ bri: bri });
        var lampstate = (Math.round(xyyval[2] * 254 / 100.0) << 24) + (Math.round(xyyval[1] * 254) << 16) + (Math.round(xyyval[0] * 254) << 8) + Math.round(bri);
        this.sendCommand(this.props.id, lampstate)
    }
    lampOff() {
        var bri = 0;
        var xyyval = xyz.xyy(rgb.xyz([this.state.rgb.r, this.state.rgb.g, this.state.rgb.b], xyz.whitepoint[10].D65));
        this.setState({ bri: bri });
        var lampstate = (Math.round(xyyval[2] * 254 / 100.0) << 24) + (Math.round(xyyval[1] * 254) << 16) + (Math.round(xyyval[0] * 254) << 8) + Math.round(bri);
        this.sendCommand(this.props.id, lampstate)
    }
    componentDidUpdate(prevProps, prevState){
        if (prevProps.value !== this.props.value)
        {
            var hsvarr = rgb.hsv(xyz.rgb(xyy.xyz([((this.props.value & 0xFF00) >>> 8) / 254.0, ((this.props.value & 0xFF0000) >>> 16) / 254.0, 20]), xyz.whitepoint[10].D65));
            hsvarr[2] = (this.props.value & 0xFF) * 100 / 254.0;
          var rgbarr = hsv.rgb(hsvarr);
          this.setState({
              bri: (this.props.value & 0xFF),
              rgb: { r: rgbarr[0], g: rgbarr[1], b: rgbarr[2] },
              hsv: { v: hsvarr[2] , s: hsvarr[1] , h: hsvarr[0] }
          });
      }
    }
    sendCommand(id, value)
    {
        fetch('https://' + window.location.hostname + '/HomeAPI/ChangeState', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: this.props.location,
                state: id,
                value: value
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .catch(error => console.log(error));
    }
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
    }
}

export default ColorLightControl;