import React, { Component } from 'react';

class Change extends Component {
    render() {
        var divstyle = this.props.divstyle

        return (
    <div style={divstyle}>
            <svg class="full absolute" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" style={Object.assign({},{enableBackground: 'new 0 0 48 48'})}>
            <path d="M13.98 22l-7.98 8 7.98 8v-6h14.02v-4h-14.02v-6zm28.02-4l-7.98-8v6h-14.02v4h14.02v6l7.98-8z"/>
            </svg>

</div>
        );
    }
}
export default Change;

