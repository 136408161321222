import React, { Component } from 'react';
import House from './House';
import LeavingHouse from './LeavingHouse';

class Presence extends Component {
    render() {
        var colors = this.props.globalstate.colors.light;
        if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
        //var stylesheet = JSON.parse(JSON.stringify(this.props.design));
		var divstyle = Object.assign({},{position:'absolute', fill: this.props.globalstate.currentColors.fgcolor, stroke: this.props.globalstate.currentColors.fgcolor},this.props.design)
        if (this.props.item.value > 0) return (<House divstyle={divstyle} />);
		else return (<LeavingHouse divstyle={divstyle} />);
    }
}
export default Presence;

