import React from 'react';
import { PlannerTask, PlannerTaskDetails, PlannerChecklistItem } from 'microsoft-graph';
import CheckListItem from './CheckListItem';

interface TaskRowProps {
  task: PlannerTask;
  details: PlannerTaskDetails;
}


export default class TaskRow extends React.Component<TaskRowProps> {
  render() {
      var temparr: PlannerChecklistItem[] = []
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(JSON.parse(JSON.stringify(this.props.details.checklist)))) {
        //temparr.push(value as PlannerChecklistItem);
        var val: PlannerChecklistItem = value as PlannerChecklistItem;
        if(!val.isChecked) temparr.push(val);
      }
      if(temparr.length === 0) return null;
      temparr = temparr.sort(function(a, b) { if(a.orderHint && b.orderHint) return a.orderHint.localeCompare(b.orderHint); else return 0;});
      return (
      <React.Fragment>

                  <div className="calendar-view-subject">
                    {this.props.task.title}
                      <ul>
                          {temparr.map(function (checkitem: PlannerChecklistItem, index: Number) {
                                    return (<CheckListItem info={checkitem} />)
                                }
                                )}
                      </ul>
                    </div>

      </React.Fragment>
    )
                              
  }
}