import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import { Event } from 'microsoft-graph';
import CalendarDayRow from './CalendarDayRow';
import './Calendar.css';

interface CalendarProps {};

interface CalendarState {
  eventsLoaded: boolean;
  events: Event[];
  lastUpdate: moment.Moment;
}

class Calendar extends React.Component<CalendarProps, CalendarState> {
  timerID: any;
  
  constructor(props: any) {
    super(props);

    this.state = {
      eventsLoaded: false,
      events: [],
      lastUpdate: moment()
    };
    this.processEvents = this.processEvents.bind(this);
    this.updateEvents = this.updateEvents.bind(this);
    this.timerID = undefined;
  }
  
  async componentDidMount() {
    this.timerID = setInterval(this.updateEvents, 300000); // runs every 5 min.
    this.updateEvents()
}
  async componentWillUnmount() {
    if (this.timerID !== undefined) clearInterval(this.timerID);
  }
  handleErrors(response: any) {
    if (!response.ok) {
        if(response.status === 401) window.location.href = 'https://smarthome.roehder.eu/HomeAPI/signin';
        throw Error(response.statusText);
    }
    return response;
}

  async processEvents(response: any) {
    response = response.sort(function (b: Event, a: Event) { if(b.start?.dateTime && a.start?.dateTime) return (new Date(b.start?.dateTime)).getTime() - (new Date(a.start?.dateTime)).getTime(); else return 0; });
    // Update the array of events in state
    this.setState({
      eventsLoaded: true,
      events: response,
      lastUpdate: moment()
    });
  }
  async updateEvents() 
  {
    fetch('https://' + window.location.hostname + '/HomeAPI/GetCalendar', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      credentials: 'include'})
    .then(this.handleErrors)
    .then(result => result.json())
    .then(this.processEvents)
    .catch(error => console.log(error));
  }

  render() {
    if (this.state.eventsLoaded) {
      var Zone = "Europe/Berlin";
      var startOfWeek = moment.tz(Zone).startOf('day').utc();
      var sunday = moment(startOfWeek);
      var monday = moment(sunday).add(1, 'day');
      var tuesday = moment(monday).add(1, 'day');
      var wednesday = moment(tuesday).add(1, 'day');
      var thursday = moment(wednesday).add(1, 'day');
      var friday = moment(thursday).add(1, 'day');
      var saturday = moment(friday).add(1, 'day');

      var timeFormat = "HH:mm";

      return (
        <div style={{ color: '#FFF' }}>
          <div className="calendar-week">
            <div className="table-responsive">
              <Table size="sm">
                <tbody>
                  <CalendarDayRow
                    date={sunday}
                    timeFormat={timeFormat}
                    events={this.state.events.filter(event => moment(event.start?.dateTime).date() === sunday.date())} />
                  <CalendarDayRow
                    date={monday}
                    timeFormat={timeFormat}
                    events={this.state.events.filter(event => moment(event.start?.dateTime).date() === monday.date())} />
                  <CalendarDayRow
                    date={tuesday}
                    timeFormat={timeFormat}
                    events={this.state.events.filter(event => moment(event.start?.dateTime).date() === tuesday.date())} />
                  <CalendarDayRow
                    date={wednesday}
                    timeFormat={timeFormat}
                    events={this.state.events.filter(event => moment(event.start?.dateTime).date() === wednesday.date())} />
                  <CalendarDayRow
                    date={thursday}
                    timeFormat={timeFormat}
                    events={this.state.events.filter(event => moment(event.start?.dateTime).date() === thursday.date())} />
                  <CalendarDayRow
                    date={friday}
                    timeFormat={timeFormat}
                    events={this.state.events.filter(event => moment(event.start?.dateTime).date() === friday.date())} />
                  <CalendarDayRow
                    date={saturday}
                    timeFormat={timeFormat}
                    events={this.state.events.filter(event => moment(event.start?.dateTime).date() === saturday.date())} />
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      );
    }
    else return null;
  }
}

export default Calendar;