import React, { Component } from 'react';
import Window from './Window';
import Shutter from './Shutter';
import Measurement from './Measurement';
import Heating from './Heating';
import Light from './Light';
import ColorLight from './ColorLight';
import ColorTempLight from './ColorTempLight';
import Waterpump from './Waterpump';
import Vent from './Vent';
import DimLight from './DimLight';
import Presence from './Presence';


class State extends Component {
    render() {
        var out = "";
        if (this.props.design === undefined || this.props.item === undefined || this.props.item.value === undefined || this.props.item.unit === undefined) return out;
        else if (this.props.item.type === 64) {
            out = <Vent item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></Vent>;
        }
        else if (this.props.item.type === 80) {
            out = <Window item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></Window>;
        }
        else if (this.props.item.type === 81){
            out = <Shutter item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></Shutter>;
        }
        else if (this.props.item.type === 84){
            out = <Light item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></Light>;
        }
        else if (this.props.item.type === 85){
            out = <Heating item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></Heating>;
        }
        else if (this.props.item.type === 86){
            out = <Waterpump item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></Waterpump>;
        } 
        else if (this.props.item.type === 87){
            out = <ColorLight item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></ColorLight>;
        }
        else if (this.props.item.type === 88) {
            out = <DimLight item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></DimLight>;
        }
        else if (this.props.item.type === 89) {
            out = <ColorTempLight item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></ColorTempLight>;
        }
        else if (this.props.item.type === 203) {
            out = <Presence item={this.props.item} design={this.props.design} globalstate={this.props.globalstate} ></Presence>;
        }
        else {
            out = <Measurement item={this.props.item} design={this.props.design} landscape={this.props.landscape} globalstate={this.props.globalstate} ></Measurement>;
        }
        return out;
    }
}

export default State;