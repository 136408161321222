import React, { Component } from 'react';
import { Interactive } from 'react-interactive';
import Change from './Change';

class ValueControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        };
        this.handleValueChange = this.handleValueChange.bind(this);
    }
    render() {
        var output ="";
        if(this.props.globalstate !== undefined && this.props.desc !== undefined)
        {
            var colors = this.props.globalstate.colors.light;
            if(this.props.globalstate.dark) colors = this.props.globalstate.colors.dark;
            var fgcolor = colors.fgcolor;
            var midcolor = colors.midcolor;
            if(this.props.globalstate.selectedItem !== undefined && this.props.globalstate.selectedItem.id === this.props.id)
            {
                fgcolor = colors.redcolor;
                midcolor = colors.redcolor;
            }
            var hoverstyle = {fill: fgcolor, stroke: fgcolor, color: fgcolor, background: fgcolor+'33'}
            var buttonstyle = {fill: midcolor, stroke: midcolor, flex: 1, flexBasis: '120px', border: '1px', margin:'0px 2px', borderStyle: 'solid', height: '32px',color: midcolor, background: 'none',  zIndex:100, position:'relative' }
            var buttontextstyle = {position: 'relative', float: 'left', top: '50%', transform: 'translate(0%, -50%)', color: fgcolor }
            var iconstyle = {position:'absolute',top:'15%',height:'70%', width:'100%', fill: fgcolor, stroke: fgcolor}
            output = <div style={{ display: 'flex', width: '100%', flex: '0 0 45px', background: colors.bgcolor }}>
                        <div style={{flex: 1, margin: '1px', flexBasis: '150px'}}>
                            <div style={Object.assign({},buttontextstyle)} >{this.props.desc}</div>
                        </div>
                        <input style={{ flex: 1, margin: '1px', flexBasis: '30px' }} type="text" value={this.state.value} onChange={this.handleValueChange} />
                        <Interactive as="div" hover={hoverstyle} style={Object.assign({}, buttonstyle, { width: '3%' })} onClick={(e) => this.sendCommand(this.props.id, this.state.value, e)}>
                            <Change divstyle={iconstyle}/>
                        </Interactive>
                    </div>
        }
        return output;
    }
    handleValueChange(event) {
        this.setState({ value: event.target.value });
    }
    sendCommand(id, value, e)
    {
        fetch('https://' + window.location.hostname + '/HomeAPI/ChangeState', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location: this.props.location,
                state: id,
                value: value
            }),
            credentials: 'include'
        })
            .then(this.handleErrors)
            .catch(error => console.log(error));
    }
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
    }
}

export default ValueControl;